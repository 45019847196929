'use strict'

import angular from 'angular'

import '../api'

angular.module('app.directives.archive', ['api'])
  .directive('appDocumentArchive', function() {
    return {
      restrict: 'EA',
      template: require('../../tpl/directives/archive.html'),
      controller: ['$window', '$scope', 'api', function($window, $scope, api) {
        var sign = api.$collection('documents', { type: 'sign' })
        $scope.signAndDownload = function(model) {
          sign.get({ version: model.VersionId }, function(res) {
            $window.location = res.url
          })
        }
      }],
      scope: { collection: '=' }
    }
  })
