import _ from 'underscore'
import angular from 'angular'

import './storage'
import './api'

const API_BASE_URL = `${process.env.AFFIO_API_BASE_URL}/`

angular.module('authentication', ['angularLocalStorage', 'api'])
  .service('auth', ['$http', 'storage', 'api',
    function($http, storage, api) {

      // remove any localStorage session - deprecated storage
      storage.remove('session')

      // auth config
      var config = {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Data-Cascade': 'false',
          'X-Data-Expand': '1'
        }
      }

      /**
       *  Registers user over server-side layer
       *  @param {string} username Unique email
       *  @param {string} password
       *  @returns {promise} $http
       *  @public
       */
      function register(username, password) {
        return $http.post(getBaseURL() + 'user', {
          'username': username,
          'password': password,
          'grant_type': 'client_credentials'
        }, getConfig()).then(res => {

          // cache user in response

          var user = res.data.user || {}

          // set session in auth service layer
          setSession(res.data)

          // set user in browser storage
          setUser({
            id: user._id,
            username: user.username,
            identity: user.profile,
            estate: user.estate,
            executorship: user.executorship,
            guardianship: user.guardianship
          })

          return res
        })
      }

      // expose public method
      this.register = register

      /**
       *  Authenticates user over server-side layer
       *  @param {string} username
       *  @param {string} password
       *  @returns {promise} $http
       *  @public
       */
      function authenticate(username, password) {
        var input = {}, xsrf

        // parse input
        input = {
          'username': username,
          'password': password
        }

        // serialize input
        xsrf = _.serialize(_.extend(input, {
          'grant_type': 'client_credentials',
          'token_type': getTokenType()
        }))

        return $http.post(getBaseURL() + 'user/accessToken', xsrf, getConfig('form'))
          .then(res => {

            // cache user in response
            var user = res.data.user || {}

            // set session in auth service layer
            setSession(res.data)

            // set user in browser storage
            setUser({
              id: user._id,
              username: user.username,
              identity: user.profile,
              estate: user.estate,
              executorship: user.executorship,
              guardianship: user.guardianship
            })

            return res
          })
      }

      // expose public method
      this.authenticate = authenticate

      /**
       *  Authenticates user via Facebook provider
       *  @param {string} action
       *  @param {string} token
       *  @returns {promise} $http
       *  @public
       */
      function authenticateViaFacebook(action, token) {
        var input = {}, xsrf

        // parse input
        input = { 'token': token }

        // serialize input
        xsrf = _.serialize(_.extend(input, {
          'grant_type': 'client_credentials',
          'token_type': getTokenType()
        }))

        return $http.post(getBaseURL() + 'auth/facebook/token', xsrf, getConfig('form'))
          .then(res => {

            // cache user in response
            var user = res.data.user || {}

            // set session in auth service layer
            setSession(res.data)

            // set user in browser storage
            setUser({
              id: user._id,
              username: user.username,
              identity: user.profile,
              estate: user.estate,
              executorship: user.executorship,
              guardianship: user.guardianship
            })

            return res
          })
      }

      // expose public method
      this.authenticateViaFacebook = authenticateViaFacebook

      /**
       *  Authenticates user via Google provider
       *  @param {string} action
       *  @param {string} token
       *  @returns {promise} $http
       *  @public
       */
      function authenticateViaGoogle(action, token) {
        var input = {}, xsrf

        // parse input
        input = { 'token': token }

        // serialize input
        xsrf = _.serialize(_.extend(input, {
          'grant_type': 'client_credentials',
          'token_type': getTokenType()
        }))

        return $http.post(getBaseURL() + 'auth/google/token', xsrf, getConfig('form'))
          .then(res => {

            // cache user in response
            var user = res.data.user || {}

            // set session in auth service layer
            setSession(res.data)

            // set user in browser storage
            setUser({
              id: user._id,
              username: user.username,
              identity: user.profile,
              estate: user.estate,
              executorship: user.executorship,
              guardianship: user.guardianship
            })

            return res
          })
      }

      // expose public method
      this.authenticateViaGoogle = authenticateViaGoogle

      /**
       *  Retracts server-side authentication and logs user out
       *  @returns {promise} $http
       *  @public
       */
      function end() {
        return api.legacy_logout()
      }

      // expose public method
      this.end = end

      /**
       *  Handles password recovery over server layer
       *  @param {string} username Username to recover
       *  @returns {promise} $http
       *  @public
       */
      function recoverPassword(username) {
        return $http.post(getBaseURL() + 'user/forgotPassword', {
          username: username
        }, getConfig())
      }

      // expose public method
      this.recover = recoverPassword

      /**
       *  Reset user's password using token over server layer
       *  @param {string} token
       *  @param {string} password
       *  @returns {promise} $http
       *  @public
       */
      function resetPassword(token, password) {
        return $http.post(getBaseURL() + 'user/resetPassword', {
          'grant_type': 'client_credentials',
          token: token,
          password: password
        }, getConfig()).then(res => {

          // cache user in response
          var user = res.data.user || {}

          // set session in auth service layer
          setSession(res.data)

          // set user in browser storage
          setUser({
            id: user._id,
            username: user.username,
            identity: user.profile,
            estate: user.estate,
            executorship: user.executorship,
            guardianship: user.guardianship
          })

          return res
        })
      }

      // expose public method
      this.reset = resetPassword

      /**
       *  Manually change authenticated user's password over server layer
       *  @param {string} password
       *  @returns {promise} $http
       *  @public
       */
      function changePassword(password) {
        var endpoint = getBaseURL() + 'user/changePassword'
        return $http.post(endpoint, {
          'grant_type': 'client_credentials',
          password: password
        }, getConfig()).then(res => {

          // cache user in response
          var user = res.data.user || {}

          // set session in auth service layer
          setSession(res.data)

          // set user in browser storage
          setUser({
            id: user._id,
            username: user.username,
            identity: user.profile,
            estate: user.estate,
            executorship: user.executorship,
            guardianship: user.guardianship
          })

          return res
        })
      }

      // expose public method
      this.change = changePassword

      /**
       *  Base URL for requests
       *  @returns {string} Base URL
       *  @public
       */
      function getBaseURL() {
        return API_BASE_URL
      }
      this.getBaseURL = getBaseURL

      /**
       *  Default configuration for requests
       *  @param {number} [type] Configuration type
       *  @param {string} [method] Request method
       *  @param {string} [url] Request url
       *  @returns {object} Configuration
       *  @public
       */
      function getConfig(type, method, url) {
        var res = angular.copy(config)

        // return default config if no type provided
        if (_.isUndefined(type)) return res

        // support simultaneous config types
        if (!_.isArray(type)) type = [type]

        // validate parameters for signed config
        if (url) {
          console.warn('Deprecated! Legacy getConfig() call for ' + url)
          if (_.contains(type, 'signed') || !!method) {
            console.warn('Deprecated! getConfig() replaced by $httpProvider interceptor to sign requests, this use-case can be simplified.')
          }
        }

        // set resulting config based on
        // type of request
        _.each(type, instruction => {
          switch (instruction) {
            case 'cascade':
              res = _.extend(res, {
                headers: _.extend(res.headers, {
                  'X-Data-Cascade': 'true'
                })
              })
              break
            case 'deepextended':
              res = _.extend(res, {
                headers: _.extend(res.headers, {
                  'X-Data-Expand': '3'
                })
              })
              break
            case 'extended':
              res = _.extend(res, {
                headers: _.extend(res.headers, {
                  'X-Data-Expand': '2'
                })
              })
              break
            case 'flat':
              res = _.extend(res, {
                headers: _.extend(res.headers, {
                  'X-Data-Expand': undefined
                })
              })
              break
            case 'form':
              res = _.extend(res, {
                headers: _.extend(res.headers, {
                  'Content-Type': 'application/x-www-form-urlencoded'
                })
              })
              break
            default:
              res = res
              break
          }
        })

        return res
      }

      // expose public method
      this.getConfig = getConfig

      /**
       *  Gets user from localStorage
       *  @param {string} [id] Ensure user of id
       *  @returns {object} User object
       *  @public
       */
      function getUser(id) {
        var user = storage.get('user')

        // if id is defined, check local user matches
        if (!_.isUndefined(id) && id !== user.id) {
          clearUser()

          // set new user's id
          user = setUser({ id: id })
        }
        return user
      }

      // expose public method
      this.getUser = getUser

      /**
       *  Sets user in localStorage
       *  @param {object} user User object
       *  @returns {object} User object
       *  @public
       */
      function setUser(user) {
        if (!_.isObject(user)) {
          throw new Error('Error setting local user object, input is invalid.')
        }

        // if id is defined, check local user matches
        if (!_.isUndefined(user.id) && user.id !== getUserID()) {

          // mismatch, clear old user session
          clearUser()
        }

        // delimit user attributes for data privacy
        user = _.pick(user, ['id', 'username', 'identity', 'estate', 'executorship', 'guardianship', 'history', 'title', 'name', 'surname', 'gender'])

        return storage.set('user', _.extend((getUser() || {}), user))
      }

      // expose public method
      this.setUser = setUser

      /**
       *  Gets primary key id for user record
       *  @returns {string} Primary key id
       *  @public
       */
      function getUserID() {
        return (getUser() || {}).id
      }

      // expose public method
      this.getUserID = getUserID

      /**
       *  Gets reference id for user person record
       *  @returns {string} Reference id
       *  @public
       */
      function getUserIdentity() {
        return (getUser() || {}).identity
      }

      // expose public method
      this.getUserIdentity = getUserIdentity

      /**
       *  Gets username from localStorage
       *  @returns {string} Username
       *  @public
       */
      function getUsername() {
        return (getUser() || {}).username
      }

      // expose public method
      this.getUsername = getUsername

      /**
       *  Sets username in localStorage
       *  @param {string} username
       *  @returns {string} Username
       *  @public
       */
      function setUsername(username) {
        if (_.isEmpty(username) || !_.isString(username)) {
          throw new Error('Error setting local username, input is invalid.')
        }

        return setUser({ username: username }).username
      }

      // expose public method
      this.setUsername = setUsername

      /**
       *  Gets reference id for user estate record
       *  @returns {string} Reference id
       *  @public
       */
      this.getUserEstate = function() {
        return (getUser() || {}).estate
      }

      /**
       *  Gets reference id for user executorship record
       *  @returns {string} Reference id
       *  @public
       */
      this.getUserExecutorship = function() {
        return (getUser() || {}).executorship
      }

      /**
       *  Gets reference id for user guardianship record
       *  @returns {string} Reference id
       *  @public
       */
      this.getUserGuardianship = function() {
        return (getUser() || {}).guardianship
      }

      /**
       *  Clears stored user
       */
      function clearUser() {
        return storage.remove('user')
      }

      /**
       *  Caches session
       *  @param {object} session Session data
       *  @returns {object} session
       *  @private
       */
      function setSession(session) {

        // handle legacy_login in api service
        api.legacy_login(session)
      }

      /**
      *  Checks whether session is valid
      *  @returns {boolean} Validity
      *  @public
      */
      function sessionIsValid() {
        return api.session.isValid()
      }

      // expose public method
      this.sessionIsValid = sessionIsValid

      /**
       *  Token type for authentication
       *  @returns {string} Token type
       *  @private
       */
      function getTokenType() {
        return 'Bearer'
      }
    }
  ])
