import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import '../familytree/layout'
import '../factories/nodes'
import './link.table'

angular.module('app.relationships.services.node.list', ['authentication', 'app.account.service', 'app.relationships.services.link.table', 'app.relationships.factories.nodes'])
  .service('relationships.service.node.list', ['$http', 'auth', 'relationships.factory.node', 'relationships.service.link.table',
      function($http, auth, Node, links) {
        var collection = [], self

        /**
         *  Fetch nodes from server side
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function() {
          var endpoint = auth.getBaseURL() + 'person'

          // fetch nodes
          return $http.get(endpoint, auth.getConfig()).then(res => {

            // keep self updated
            self = auth.getUserIdentity()

            // set each person into emptied collection
            collection = _.map(res.data || [], function(person) {
              return new Node(person)
            })

            // fetch links
            return links.fetch(collection).then(() => this)
          })
        }

        /**
         *  Get node or all nodes from collection
         *  @param {string} [id] Node id to get specific node
         *  @returns {object} Node
         *  @public
         */
        function get(id) {
          if (!_.isUndefined(id)) {
            return _.findWhere(collection, { id: id })
          }
          return collection
        }
        this.get = get

        /**
         *  Get node referencing user
         *  @returns {object} Node
         *  @public
         */
        function getSelf() {

          // self id of user person model,
          // immutable node in collection
          if (self) return get(self)
        }
        this.getSelf = getSelf

        /**
         *  Look up attribute on specific node
         *  @param {string} id Node id
         *  @param {array} attrs Attribute(s) to look up
         *  @returns {object} Node
         *  @public
         */
        this.lookup = function(id, attrs) {
          return _.pick(this.get(id), attrs)
        }

        /** Mock node with id of 1 */
        this.mock = function(data) {
          var node = new Node(_.extend(data, { id: 1 }))
          collection.push(node)
          return node
        }

        /** Remove mock node */
        this.unmock = function() {
          collection = _.reject(collection, function(node) {
            return node.id === 1
          })
        }

        /**
         *  Add a node
         *  @param {object} data
         *  @returns {promise} $http
         *  @public
         */
        this.add = function(data) {
          var endpoint = auth.getBaseURL() + 'person'
          return $http.post(endpoint, data || {}, auth.getConfig())
                  .then(function(res) {
                    var node = new Node(res.data)
                    collection.unshift(node)
                    return get(node.id)
                  })
        }

        /**
         *  Remove a node
         *  @param {object} node Object reference to node
         *  @public
         */
        this.remove = function(node) {
          var endpoint = auth.getBaseURL() + 'person/' + node.id, defer

          // unreference node
          links.unreference(node)

          // delete person through service layer
          defer = $http.delete(endpoint, auth.getConfig())

          // register promises callback
          defer.then(() => {

            // remove node on successful deletion
            collection = _.without(collection, node)
          })

          // return promise
          return defer
        }
      }
  ])
