// global jQuery
import './jquery.shim'

// libraries
import angular from 'angular'
import app from './app'

// error tracking
import raven from 'raven-js'
import ravenAngular from 'raven-js/plugins/angular'

// 1.2.x compatibility, angular.resumeBootstrap()
// takes an optional array of modules that should be added to
// the original list of modules that the app was about to be
// bootstrapped with
window.name = 'NG_DEFER_BOOTSTRAP!'

// configure error tracking
raven
  .config('https://da598ef001f4415fb1539da0f1de5430@sentry.io/185291')
  .addPlugin(ravenAngular, angular)
  .install()

// ng-app appended to <html> element,
// resumes bootstrap once DOM is ready
angular
  .element(angular.element(document.getElementsByTagName('html')[0]))
  .ready(() => angular.resumeBootstrap([app.name]))
