import _ from 'underscore'
import angular from 'angular'

import '../../../../auth'
import '../../factories/party.acting'

angular.module('app.wishes.guardianships.factories.guardian', ['authentication', 'app.wishes.factories.party.acting'])
  .factory('wishes.guardianships.factories.guardian', ['$http', 'auth', 'wishes.factories.party.acting', function($http, auth, ActingParty) {
    return constructor

    /**
     *  Return guardian
     *  @param {object} obj Existing data
     *  @returns {object} guardian
     *  @constructor
     */
    function constructor(obj) {
      var id = this.id = obj._id

      // set endpoint
      this.endpoint = auth.getBaseURL() + 'guardian/' + id

      // extend ActingParty
      return _.extend(new ActingParty(obj), this)
    }
  }])
