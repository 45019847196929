import _ from 'underscore'
import moment from 'moment'
import angular from 'angular'

import 'angular-resource'
import 'angular-cookies'

import './bus'

const APP_BASE_URL = '/'
const API_BASE_URL = process.env.AFFIO_API_BASE_URL
const MESSAGE_TIMEOUT = 1000

const SESSION_REFRESH_THRESHOLD = .5
const SESSION_TERMINATE_THRESHOLD = .9

// certain requests to ignore in notifier
// TODO: this should be delegated to $state definitions and exist as a provider
const IGNORE_REQUESTS = [

  // authentication endpoints
  { path: /^\/user$/, 401: null },
  { path: /^\/user\/logout/, 401: null },
  { path: /^\/user\/accessToken/, 401: null },
  { path: /^\/user\/refreshToken/, 401: null },
  { path: /^\/user\/forgotPassword/, 401: null },
  { path: /^\/user\/resetPassword/, 401: null },
  { path: /^\/resetPassword/, 401: null },

  // payment provider endpoints
  { path: /^\/payment\/process/ },
  { path: /^\/payment\/validate/, 402: null },
  { path: /^\/payment\/verifyCoupon/, 404: null },

  // application state endpoints
  { path: /^\/state\/[\w\d]*/ },

  // third-party login endpoints
  { path: /^\/auth\/facebook\/token/, session: null },
  { path: /^\/auth\/google\/token/, session: null },

  // ignored calls to non-auth endpoints
  { path: /^\/cdn\/wordpress\/*/, session: null, 404: null }

]

const RESOURCE_MAPPINGS = {
  user: '/user/:id',
  profile: '/person/:id/:subres/:id2',
  asset: '/asset/:id/:subres/:id2',
  person: '/person/:id/:subres/:id2',
  documents: '/user/documents/:type'
}

class NotificationService {
  constructor($timeout, $window, $q, $sce, $injector, _session) {
    'ngInject'

    this.$timeout = $timeout
    this.$window = $window
    this.$q = $q
    this.$sce = $sce
    this.$injector = $injector
    this.session = _session

    this.currentTimeout = MESSAGE_TIMEOUT
    this.queue = []
    this.inProgress = 0
  }

  /**
   *  Adds regular message to queue
   *  @param {String} message
   *  @returns {function} Remove partial
   */
  add(message) {
    return this._add({
      body: message,
      sticky: true
    })
  }
  /**
   *  Adds error message to queue
   *  @param {String} message
   *  @returns {function} Remove partial
   */
  addError(message) {
    return this._add({
      body: message,
      sticky: true
    })
  }
  /**
   *  Adds network error message to queue
   *  @param {String} message
   *  @returns {function} Remove partial
   */
  addNetworkError(message) {
    return this._add({
      body: message,
      block: true,
      type: 'no_network',
      displayMs: null
    })
  }
  /**
   *  Adds saving message to queue
   *  @param {String} message
   *  @returns {function} Remove partial
   */
  addSaving(message) {
    return this._add({
      body: message,
      type: 'spinner',
      minDisplayMs: 500,
      displayMs: null
    })
  }
  /**
   *  Adds session error message to queue
   *  @param {String} message
   *  @returns {function} Remove partial
   */
  addSessionError(message) {
    return this._add({
      body: message,
      sticky: true,
      block: true
    })
  }
  /**
   *  Adds message to queue
   *  @param {object} message
   *  @description
   *    add()
   *    {
   *      body: "Description",
   *      sticky: bool (user needs to click to close)
   *      type: error type (for icon),
   *      displayMs: milliseconds to display for
   *    }
   *  @returns {function} Remove partial
   */
  _add(message) {
    if (!_.isString(message.body)) return
    message.body = this.$sce.trustAsHtml(message.body)
    message.destroy = _.bind(this.remove, this, message)
    this.queue.push(message)

    if (!message.sticky && !_.isNull(message.displayMs)) {

      // remove after x seconds
      this.$timeout(() => this.remove(message), message.displayMs || 2000)
    }

    if (message.minDisplayMs) {
      return (() => {
        let canTrigger = 0

        this.$timeout(() => {
          if (canTrigger++) this.remove(message)
        }, message.minDisplayMs)

        return () => {
          if (++canTrigger > 1) this.remove(message)
        }
      })()
    }

    return message
  }

  /**
   *  Removes message from queue
   *  @returns {boolean} Success
   */
  remove(message) {
    let index = _.indexOf(this.queue, message)

    if (index > -1) {
      this.queue.splice(index, 1)
      return true
    }

    return false
  }

  removeAll() {
    this.queue = []
  }

  handleProgress(req) {
    if ((req.method === 'POST' || req.method === 'PUT') && --this.inProgress === 0 && this.done) {
      this.done()
      this.done = undefined
    }
  }

  handlers() {
    return {

      // we can detect when a network request is in progress here
      request: req => {
        let deferred = this.$q.defer()

        if (req.method === 'POST' || req.method === 'PUT') {

          if (!_.some(IGNORE_REQUESTS, ignore => ignore.path.test(req.url.replace(API_BASE_URL, '')))) {
            this.done = this.done || this.addSaving('Saving...')
          }

          this.inProgress++
        }

        // add auth header if logged in to authenticated endpoints
        if (this.session.isValid() && !_.some(IGNORE_REQUESTS, ignore => ignore.path.test(req.url.replace(API_BASE_URL, '')) && _.isNull(ignore.session))) {
          this.session.getToken().then(function(header) {
            req.headers.Authorization = header
            deferred.resolve(req)
          }, deferred.reject)
        } else {
          deferred.resolve(req)
        }

        return deferred.promise
      },

      requestError: req => {
        this.handleProgress(req.config)
        return req
      },

      response: res => {
        this.currentTimeout = MESSAGE_TIMEOUT
        this.handleProgress(res.config)
        return res
      },

      responseError: res => {
        let deferred = this.$q.defer()
        this.handleProgress(res.config)

        if (_.some(IGNORE_REQUESTS, ignore => {
          return ignore.path.test(res.config.url.replace(API_BASE_URL, '')) &&
            _.isNull(ignore[res.status])
        })) {

          // this type of status is ignored for this request
          deferred.reject(res)
          return deferred.promise
        }

        switch (res.status) {
          case 500:
            this.addError('There was an error with your request ' + res.config.url)
            deferred.reject(res)
            break

          case 400:
          case 404:
          case 409:
          case 422:
            this.addError(res.data.error.message || "Couldn't process request")
            deferred.reject(res)
            break

          case 401:
            this.removeAll()
            this.addSessionError('Your session has expired, you will need to <button class="btn btn-link btn-link-anchor">sign in again</button>')
            this.session.destroy()
            deferred.reject(res)
            break

          case 0:

            // no network, retry until reconnect
            // TODO: use browser network API if available
            if (this.networkErrorMessage) { this.networkErrorMessage.destroy(); }
            this.networkErrorMessage = this.addNetworkError('No network connection, retrying in ' + Math.round(this.currentTimeout / 1000) + ' seconds')

            this.$timeout(() => {

              // rerun the request
              let $http = this.$injector.get('$http')
              $http(res.config).then(res => {
                if (this.networkErrorMessage) {
                  this.networkErrorMessage.destroy()
                }

                deferred.resolve(res
              )               })
            }, (() => {
              this.currentTimeout = ((this.currentTimeout * 2) <= (30 * 1000)) ? (this.currentTimeout * 2) : (30 * 1000)
              return this.currentTimeout
            })()
          )

            break

          default:
            deferred.reject(res)

        }

        return deferred.promise
      }
    }
  }
}

class SessionService {
  constructor($q, $injector, $cookieStore) {
    'ngInject'

    this.$q = $q
    this.$injector = $injector
    this.$cookieStore = $cookieStore

    this.access_token = $cookieStore.get('affio_access_token')
    this.refresh_token = $cookieStore.get('affio_refresh_token')
    this.expires_in = $cookieStore.get('affio_access_expires_in')
    this.expires = moment.utc($cookieStore.get('affio_access_expires') || 0)

    this.refreshing = false
  }

  isValid() {
    return sessionNotEmpty.call(this) && sessionNotExpired.call(this)

    /**
     *  Checks session is populated
     *  @returns {boolean} Populated?
     *  @private
     */
    function sessionNotEmpty() {
      let keys = ['access_token', 'refresh_token', 'expires']
      return _.every(keys, key => _.has(this, key))
    }

    /**
     *  Checks session is active
     *  @returns {boolean} Active?
     *  @private
     */
    function sessionNotExpired() {
      return moment.utc().isBefore(this.expires)
    }
  }

  /**
   *  Calculate time elapsed since session start
   *  @returns {moment.duration}
   */
  timeElapsed() {
    let commenced = moment.utc(this.expires).subtract(this.expires_in, 'seconds')
    return moment.duration(moment.utc().diff(commenced))
  }

  /**
   *  Determine whether session is in refresh threshold
   *  @returns {boolean}
   */
  shouldRefresh() {

    // fails, cannot refresh invalid session
    if (!this.isValid()) return false

    // fails, session is already processing refresh
    if (this.refreshing) return false

    let threshold = this.expires_in * SESSION_REFRESH_THRESHOLD
    return moment.duration(this.timeElapsed()).asSeconds() > threshold
  }

  /**
   *  Determine whether session is in termination threshold
   *  @returns {boolean}
   */
  shouldTerminate() {

    // always terminate invalid session
    if (!this.isValid()) return true

    let threshold = this.expires_in * SESSION_TERMINATE_THRESHOLD
    return moment.duration(this.timeElapsed()).asSeconds() > threshold
  }

  /**
   *  Refresh session token
   */
  refreshToken() {
    let deferred = this.$q.defer()

    if (this.isValid()) {
      this.refreshing = true

      // post refresh token request
      let $http = this.$injector.get('$http')
      $http.post(API_BASE_URL + '/user/refreshToken', {
        refresh_token: this.refresh_token,
        grant_type: 'client_credentials'
      }).then(res => {
        this.refreshing = false
        deferred.resolve(this.set(res.data))
      }, err => {
        this.refreshing = false
        deferred.reject(err)
      })
    } else {

      // reject invalid session
      deferred.reject()
    }

    return deferred.promise
  }

  /**
   *  Get formatted token for Authorization header
   *  @returns {promise} Authorization header
   */
  getToken() {
    let deferred = this.$q.defer()

    if (this.isValid()) {

      // resolve access token
      if (this.shouldRefresh()) {
        console.info('Silently refreshing session')

        // refresh if within respective threshold
        this.refreshToken().then(() => {
          deferred.resolve(formatToken(this.access_token))
        })
      } else {

        // outside threshold, resolve with cached token
        deferred.resolve(formatToken(this.access_token))
      }
    } else {

      // reject invalid session
      deferred.reject()
    }

    return deferred.promise

    /**
     *  Basic helper to format Bearer token
     *  @param {string} token
     *  @returns {string}
     */
    function formatToken(token) {
      return `Bearer ${token}`
    }
  }

  /**
   *  Set session data
   *  @param {object} session
   */
  set(session) {
    if (!_.isObject(session)) {

      // assert token is properly defined
      throw new Error('Tried to set ' + (!session ? 'empty' : 'invalid') + ' token!')
    }

    // cache session data locally
    this.access_token = session.access_token
    this.refresh_token = session.refresh_token

    // calculate timestamp for expiry
    this.expires_in = session.expires_in
    this.expires = moment.utc().add(--session.expires_in, 'seconds')

    // store session data in $cookieStore
    this.$cookieStore.put('affio_access_token', this.access_token, { path: APP_BASE_URL })
    this.$cookieStore.put('affio_refresh_token', this.refresh_token, { path: APP_BASE_URL })
    this.$cookieStore.put('affio_access_expires_in', this.expires_in, { path: APP_BASE_URL })
    this.$cookieStore.put('affio_access_expires', this.expires.format(), { path: APP_BASE_URL })
  }

  /**
   *  Implode session
   */
  destroy() {
    this.access_token = null
    this.refresh_token = null
    this.expires_in = 0
    this.expires = moment.utc(0)

    this.$cookieStore.remove('affio_access_token', { path: APP_BASE_URL })
    this.$cookieStore.remove('affio_refresh_token', { path: APP_BASE_URL })
    this.$cookieStore.remove('affio_access_expires_in', { path: APP_BASE_URL })
    this.$cookieStore.remove('affio_access_expires', { path: APP_BASE_URL })
  }
}

class APIService {
  constructor($http, $location, $resource, $interval, $timeout, $q, bus, _notifications, _session) {
    'ngInject'

    this.$http = $http
    this.$location = $location
    this.$resource = $resource
    this.$interval = $interval
    this.$timeout = $timeout
    this.$q = $q

    this.bus = bus
    this.notifications = _notifications
    this.session = _session

    this.sessionValidator = null
    this.mocked = {
      'is': true
    }

    // prepare existing session
    if (this.session.isValid()) this.prepSession()
  }

  get messages() {
    return this.notifications.queue
  }

  // TODO: temporary till auth is removed
  get legacy_login() {
    return this.prepSession
  }
  get legacy_logout() {
    return this.logout
  }

  $res(type, params, actions) {
    params = params || {}
    _.defaults(params, { id: '@_id' })
    return this.$resource(API_BASE_URL + RESOURCE_MAPPINGS[type], params, _.extend({
      getDeep: {
        method:'GET',
        headers: { 'X-Data-Expand': 1 }
      },
      update: {
        method: 'PUT',
        transformRequest: function(data) {

          // iterate all keys finding any related that might be new
          // not-yet-created relations.
          // remove this when the API handles deeply nested updates
          _.each(data, function(v, k) {

            // check if a key contains an object but no _id
            if (k.substr(0, 1) !== '$' && !this.mocked[k] && !_.isArray(v) && _.isObject(v)) {
              let subres = this.$res(type, { subres: k, id: data._id, id2: v._id })

              // update or create
              subres[v._id ? 'update' : 'save'](v)

              // TODO: we should we put this back on the representation once
              // saved
              delete data[k]
            }
          })
          return (angular.toJson(data))
        }
      }
    }, actions || {}))
  }

  get(uri, params) {
    console.warn('api.get() method deprecated!')
    this.$http.get(API_BASE_URL + uri, params)
  }

  post(uri, params) {
    console.warn('api.post() method deprecated!')
    this.$http.post(API_BASE_URL + uri, params)
  }

  // TODO: replace this with proper collection library,
  // and resolve related resource promises
  $collection(type, params) {
    return this.$resource(API_BASE_URL + RESOURCE_MAPPINGS[type], params, {
      fetchAll: {
        method:'GET',
        isArray: true,

        // hack to workaround empty resources
        transformResponse: data => {
          let res = angular.fromJson(data)
          if (res && _.isArray(res[params.type])) {
            return res[params.type]
          }

          return []
        }
      }
    })
  }

  /**
   *  Prepare user session in service
   *  @param {object} [data] Session
   *  @returns {object} Session
   */
  prepSession(data) {
    if (data) this.session.set(data)

    this.sessionValidator = this.sessionValidator || this.$interval(() => {

      // TODO: replace support for idle session notification
      // this.notifications.add({
      //   body: "Your session will be logged out soon because it's idle",
      //   displayMs: 3000
      // })

      if (this.session.shouldTerminate()) {
        console.info('Session detected in termination threshold')
        this.cancelSession()

        this.notifications.addSessionError('Your session went idle - you will <button class="btn btn-link btn-link-anchor">need to log-in again</button>')
      }

    }, (this.session.expires_in / 12) * 1000)

    return this.session
  }

  cancelSession() {
    if (!_.isNull(this.sessionValidator)) {

      // cancel interval validating session status
      this.$interval.cancel(this.sessionValidator)
      this.sessionValidator = null
    }

    // destroy current session
    this.session.destroy()

    // broadcast session termination
    this.bus.publish('action', 'session', 'terminated')
  }

  logout() {
    let deferred = this.$q.defer()

    this.$http.get(API_BASE_URL + '/user/logout')
      .then(() => deferred.resolve(this.cancelSession()), deferred.reject)

    return deferred.promise
  }

  login(username, password) {
    let req = {
      username: username,
      password: password,
      grant_type: 'client_credentials',
      token_type: 'Bearer'
    }

    return this.$http.post(API_BASE_URL + '/user/accessToken', req)
      .then(res => {

        // if we are successful set the token
        this.prepSession(res.data)

        // set our user resource
        return res.data.user
      })
  }
}

angular.module('api', ['ngResource', 'ngCookies', 'msgbus'])

  // default configuration for requests
  .config($httpProvider => {
    $httpProvider.defaults.headers.common = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Cascade': 'false',
      'X-Data-Expand': '1'
    }

    $httpProvider.interceptors.push('_interceptor')
  })

  // intercept all $http requests so we can handle raw errors too
  .factory('_interceptor', ['_notifications', _notifications => _notifications.handlers()])

  // handles messages sent to the console
  .service('_notifications', NotificationService)

  // authenticated session that syncs itself with $cookieStore
  .service('_session', SessionService)

  // exposed api service
  .service('api', APIService)
