import angular from 'angular'

import '../account/service'

angular.module('app.wishes', ['app.account.service'])
  .controller('wishes.controller', ['$scope', 'user', 'account.service', 'directives.menu.service', '$rootScope', function($scope, user, account, menu, $rootScope) {
    /**
     * Check if a section is complete by name
     * @param section String name of section
     **/
    $scope.isComplete = function(section) {
      return menu.isComplete($rootScope, section)
    }

    /**
     * Check if a section has been started by name
     * @param section String name of section
     **/
    $scope.isStarted = function(section) {
      return menu.isStarted($rootScope, section)
    }

    $scope.noUnder18 = function() {
      return account.setProgress({
        section: 'wishes.guardians'
      }, 'confirmed')
    }
  }])
