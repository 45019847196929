import _ from 'underscore'
import angular from 'angular'

import 'typeahead'
import '../../../auth'
import '../../../directives/drag'
import '../../../directives/carousel'
import '../../../directives/typeahead'
import '../../account/service'
import '../services/gifts'

angular
  .module('app.wishes.property', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.directives.typeahead',
    'app.account.service',
    'app.wishes.services.gifts',
  ])
  .controller('wishes.property.controller', [
    '$scope',
    '$http',
    '$state',
    '$stateParams',
    '$sce',
    'auth',
    'account.service',
    'wishes.services.gifts',
    function ($scope, $http, $state, $stateParams, $sce, auth, account, gifts) {
      let selected = $stateParams.id || 1

      // initiate info
      $scope.info = false

      import('../../../../tpl/wishes/property/info.html').then(
        (infoTemplate) => {
          $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
        },
      )

      $scope.goToAssetsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.assets.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.setInfo = (state) => {
        $scope.info = state
      }

      // track state for new tag
      if ($stateParams.id) {
        account
          .getProgress({
            section: 'wishes.property',
            tag: $stateParams.id,
          })
          .then(function (tracked) {
            if (!tracked) {
              return account.setProgress({
                section: 'wishes.property',
                tag: $stateParams.id,
              })
            }
          })
      }

      $scope.confirm = function ($event) {
        if ($event) $event.preventDefault()
        return account.setProgress(
          {
            section: 'wishes.property',
            tag: $scope.model.gift?.id,
          },
          'confirmed',
        )
      }

      $scope.model = {
        get gift() {
          return gifts.get(selected)
        },
        get gifts() {
          return gifts.getByAssetType('realEstate')
        },
      }

      // Population Methods

      $scope.existsBeneficiary = function (person) {
        if (!_.isUndefined($scope.model.gift._id)) {
          return $scope.model.gift.existsBeneficiary(person)
        }
      }
      $scope.existsAsset = function (asset) {
        var allGifts = $scope.model.gifts
        var given = []
        _.each(allGifts, function (gift) {
          given.push(gift.asset)
        })
        $scope.given = given
        return _.some(given, function (a) {
          return _.isEqual(asset._id, a._id)
        })
      }

      /** Add entity by identifier (asset or party) to gift */
      $scope.add = function ($event, identifier) {
        var promise

        // prevent default
        if ($event) $event.preventDefault()

        if (!$scope.loading) {
          $scope.loading = true

          // e.g. "asset:<id>"
          // this is done because drag+drop will not handle objects,
          // stringified object could be used alternatively
          identifier = identifier.split(':')

          // determine required method to add entity
          var collection =
            $scope[identifier[0] === 'asset' ? 'assets' : 'candidates']
          var method =
            'add' + (identifier[0] === 'asset' ? 'Asset' : 'Beneficiary')

          // re-assign to collection object from the incoming entity id
          var entity = _.findWhere(collection, { _id: identifier[1] })

          if (!_.has($scope.model.gift, '_id')) {
            // gift not yet persisted,
            // persist gift and continue
            promise = gifts.add({}).then(function (gift) {
              selected = gift.id
              return gift[method](entity)
            })
          } else {
            promise = $scope.model.gift[method](entity)
          }

          // update collection cache
          promise.then(
            identifier[0] === 'asset' ? updateAssets : updateCandidates,
          )

          // handle error case and cancel loading
          promise.then(() => ($scope.loading = false))
        }

        return promise
      }

      /** Remove beneficiary from gift */
      $scope.removeBeneficiary = function ($event, beneficiary) {
        $event.preventDefault()
        $scope.model.gift.removeBeneficiary(beneficiary).then(updateCandidates)
      }

      /** Remove asset from gift */
      $scope.removeAsset = function ($event, asset) {
        $event.preventDefault()
        $scope.model.gift.removeAsset(asset).then(function (res) {
          updateAssets()
          $scope.model.gift.asset = res
        })
      }

      // Gift CRUD Methods
      $scope.editGift = function (gift) {
        $scope.model.gift = gift
        $scope.$state = 'edit'
        $scope.$editState = 'selection'
        $scope.$action = 'edit'
        $scope.$valueEdit = false
      }

      $scope.deleteGift = function (gift) {
        if (_.has(gift, '_id')) {
          gifts.remove(gift).then(() => {
            if ($scope.model.gifts.length > 0) {
              $state.go('auth.wishesOptionalProperty')
            } else {
              $state.go('auth.wishesOptional')
            }
          })
        } else {
          if ($scope.model.gifts.length > 0) {
            $state.go('auth.wishesOptionalProperty')
          } else {
            $state.go('auth.wishesOptional')
          }
        }
      }

      $scope.cancel = function ($event) {
        if ($event) $event.preventDefault()
        return $scope.deleteGift($scope.model.gift)
      }

      /** Set default scenario for substitution */
      $scope.setDefault = function () {
        // remove any existing substitution reference
        if ($scope.model.gift.substitutions.length) {
          $scope.model.gift.removeSubstitution(
            _.first($scope.model.gift.substitutions),
          )
        }

        // drop extended reference
        $scope.model.gift.extended = false
        $scope.model.gift.save({ extended: false })
      }

      /** Set extended scenario for substitution */
      $scope.setExtended = function () {
        // remove any existing substitution reference
        if ($scope.model.gift.substitutions.length) {
          $scope.model.gift.removeSubstitution(
            _.first($scope.model.gift.substitutions),
          )
        }

        // set extended reference
        $scope.model.gift.extended = true
        $scope.model.gift.save({ extended: true })
      }

      // fetch gifts
      gifts.fetch()

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible beneficiary candidates */
      function updateCandidates() {
        var endpoint =
          auth.getBaseURL() +
          (!_.isUndefined($scope.model.gift) &&
          !_.isUndefined($scope.model.gift._id)
            ? 'candidates/property/' + $scope.model.gift._id
            : 'candidates')
        return $http
          .get(endpoint, auth.getConfig('signed', 'GET', endpoint))
          .then((res) => {
            $scope.candidates = res.data
            return res
          })
      }

      $scope.assets = []

      // fetch assets
      updateAssets()

      /** Fetch eligible assets */
      function updateAssets() {
        var endpoint = auth.getBaseURL() + 'candidates/realEstate'
        return $http
          .get(endpoint, auth.getConfig('signed', 'GET', endpoint))
          .then((res) => {
            $scope.assets = res.data
            return res
          })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }
        let giftModelId = $stateParams.id || $scope.model.gift?.id

        if (giftModelId) {
          currentState.params = { id: giftModelId }
        }

        return JSON.stringify(currentState)
      }
    },
  ])
