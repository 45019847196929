import _ from 'underscore'
import angular from 'angular'

import '../../../../auth'
import '../../substitution/factory'
import './guardian'

angular.module('app.wishes.guardianships.factories.guardianship', ['authentication', 'app.wishes.guardianships.factories.guardian', 'app.wishes.substitution.factory'])
  .factory('wishes.guardianships.factories.guardianship', ['$http', '$q', 'auth', 'wishes.guardianships.factories.guardian', 'wishes.substitution.factory', function($http, $q, auth, Guardian, Substitution) {

    return constructor

    /**
     *  Return guardianship
     *  @param {object} obj Existing data
     *  @returns {object} executor
     *  @constructor
     */
    function constructor(obj) {
      var id = this.id = obj._id

      // map guardians to factory instances
      obj.guardians = _.map(obj.guardians, function(guardian) {
        return new Guardian(guardian)
      })

      // map substitutions to factory instances
      obj.substitutions = _.map(obj.substitutions, function(substitution) {
        return new Substitution(substitution)
      })

      /**
       *  Check whether guardian is already represented in the guardianship
       *  @param {object} person Person object
       *  @returns {boolean} exists
       *  @public
       */
      this.existsGuardian = function(person) {
        return _.some(this.guardians, function(guardian) {
          return ((_.isObject(guardian.person)) ? guardian.person._id : guardian.person) === person._id
        })
      }

      this.addGuardian = function(person) {
        var endpoint = auth.getBaseURL() + 'guardianship/' + id + '/guardians'
        var deferred = $q.defer()

        if ((this.guardians || []).length === 2) {
          deferred.reject('Maximum number of 2 guardians exceeded.')
        } else {
          if (!this.existsGuardian(person)) {
            $http.post(endpoint, { person: person._id }, auth.getConfig(['signed', 'extended'], 'POST', endpoint))
              .then(res => {
                this.guardians.push(res.data)
                deferred.resolve(res)
              }, deferred.reject)
          } else {
            deferred.reject('Person is already added as a guardian.')
          }
        }
        return deferred.promise
      }

      this.removeGuardian = function(guardian) {
        var endpoint = auth.getBaseURL() + 'guardianship/' + id + '/guardians/' + guardian._id
        return $http.delete(endpoint, auth.getConfig(['signed'], 'DELETE', endpoint)).then(() => {
          this.guardians = _.without(this.guardians, guardian)
        })
      }

      /** Mock a substitution with id of 1 */
      this.mockSubstitution = function(data) {
        var substitution = new Substitution(_.extend(data, { id: 1 }))
        this.substitutions.push(substitution)
        return substitution
      }

      /** Remove mock substitution */
      this.unmockSubstitution = function() {
        this.substitutions = _.reject(this.substitutions, function(substitution) {
          return substitution.id === 1
        })
      }

      this.addSubstitution = function(data) {
        var endpoint = auth.getBaseURL() + 'guardianship/' + id + '/substitutions'
        return $http.post(endpoint, data, auth.getConfig(['signed', 'extended'], 'POST', endpoint))
                  .then(res => {
                    var substitution = new Substitution(res.data)
                    this.substitutions.push(substitution)
                    this.unmockSubstitution()
                    return substitution
                  })
      }

      this.removeSubstitution = function(substitution) {
        var endpoint = auth.getBaseURL() + 'guardianship/' + id + '/substitutions/' + substitution._id
        return $http.delete(endpoint, auth.getConfig('signed', 'DELETE', endpoint)).then(() => {
          this.substitutions = _.without(this.substitutions, substitution)
        })
      }

      /**
       *  Save attributes to guardianship object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        if (!_.isUndefined(id)) {
          var endpoint = auth.getBaseURL() + 'guardianship/' + id
          return $http.put(endpoint, attrs, auth.getConfig(['signed', 'extended'], 'PUT', endpoint))
        }
      }

      return _.extend(this, obj)
    }
  }])
