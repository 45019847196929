import angular from 'angular'

import '../../account/service'
import './asset.list'

angular.module('app.assets.services.prototypes', ['app.account.service', 'app.assets.services.asset.list'])
  .service('assets.service.prototypes', ['account.service', 'assets.service.asset.list', function(account, assets) {
    function realEstate() {
      return assets.add({ type: 'realEstate' })
    }
    this.realEstate = realEstate

    function personalProperty() {
      return assets.add({ type: 'personalProperty' })
    }
    this.personalProperty = personalProperty
  }])
