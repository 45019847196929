import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../../directives/drag'
import '../../../directives/carousel'
import '../../account/service'
import './service'

angular
  .module('app.wishes.estate', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.account.service',
    'app.wishes.estate.service',
  ])
  .controller('wishes.estate.controller', [
    '$scope',
    '$http',
    '$state',
    '$sce',
    'auth',
    'account.service',
    'wishes.estate.service',
    function ($scope, $http, $state, $sce, auth, account, estate) {
      $scope.editDistribution = false

      // initiate info
      $scope.info = false

      import('../../../../tpl/wishes/estate/info.html').then((infoTemplate) => {
        $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
      })

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.setInfo = function (state) {
        $scope.info = state
      }

      $scope.confirm = function ($event) {
        if ($event) $event.preventDefault()
        return account.setProgress(
          {
            section: 'wishes.estate',
          },
          'confirmed',
        )
      }

      $scope.model = {
        // abstract getter
        estate: estate.get,
      }

      // Population Methods

      /** Check if person exists as beneficiary */
      $scope.exists = function (person) {
        // abstract exists beneficiary check
        if (_.isUndefined($scope.model.estate())) return
        return $scope.model.estate().existsBeneficiary(person)
      }

      /** Add person as beneficiary */
      $scope.add = function ($event, person) {
        // prevent default
        $event.preventDefault()

        if (!$scope.loading) {
          $scope.loading = true

          // re-assign to a person object from the incoming person ID
          person = _.findWhere($scope.candidates, { _id: person })
          $scope.model
            .estate()
            .addBeneficiary(person)
            .then(updateCandidates)
            .then(
              function () {
                $scope.loading = false
              },
              function () {
                $scope.loading = false
              },
            )
        }
      }

      /** Remove beneficiary */
      $scope.remove = function ($event, beneficiary) {
        // prevent default
        $event.preventDefault()

        $scope.model
          .estate()
          .removeBeneficiary(beneficiary)
          .then(updateCandidates)
      }

      /** Set default scenario for substitution */
      $scope.setDefault = function () {
        // remove any existing substitution reference
        if ($scope.model.estate().substitutions.length) {
          $scope.model
            .estate()
            .removeSubstitution(_.first($scope.model.estate().substitutions))
        }

        // drop extended reference
        $scope.model.estate().extended = false
        $scope.model.estate().save({ extended: false })
      }

      /** Set extended scenario for substitution */
      $scope.setExtended = function () {
        // remove any existing substitution reference
        if ($scope.model.estate().substitutions.length) {
          $scope.model
            .estate()
            .removeSubstitution(_.first($scope.model.estate().substitutions))
        }

        // set extended reference
        $scope.model.estate().extended = true
        $scope.model.estate().save({ extended: true })
      }

      // fetch estate
      estate.fetch()

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible beneficiary candidates */
      function updateCandidates() {
        var endpoint = auth.getBaseURL() + 'candidates/estate'
        return $http
          .get(endpoint, auth.getConfig('signed', 'GET', endpoint))
          .then((res) => {
            $scope.candidates = res.data
            return res
          })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }
        return JSON.stringify(currentState)
      }
    },
  ])
