import _ from 'underscore'
import angular from 'angular'

import '../../../auth'

angular.module('app.wishes.factories.party', ['authentication'])
  .factory('wishes.factories.party', ['$http', 'auth', function($http, auth) {
    return constructor

    /**
     *  Return party
     *  @param {object} obj Existing data
     *  @returns {object} party
     *  @constructor
     */
    function constructor(obj) {

      // reference substitution,
      // resolves as substitution id if defined
      // or 'default' or 'extended' as appropriate
      this.substitutionRef = (_.isEmpty(obj.substitution)) ? ((obj.extended || obj.distributed) ? (obj.extended ? 'extended' : 'distributed') : 'default') : (obj.substitution || {})._id || obj.substitution

      /**
       *  Save attributes to inheriting party object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        return $http.put(this.endpoint, attrs, auth.getConfig('signed', 'PUT', this.endpoint))
      }

      return _.extend(obj, this)
    }
  }])
