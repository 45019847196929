import _ from 'underscore'
import angular from 'angular'

import '../bus'
import '../components/account/service'

angular.module('app.directives.menu', ['msgbus', 'app.account.service'])
  .constant('directives.menu.wishes_list', [
    {
      title: 'Guardians for my Children',
      link: '/wishes/guardians',
      section: 'wishes.guardians'
    },
    {
      title: 'Executors & Trustees',
      link: '/wishes/executors',
      section: 'wishes.executors'
    },
    {
      title: 'Gifts of Money',
      link: '/wishes/cash',
      section: 'wishes.cash'
    },
    {
      title: 'Gifts of Possessions',
      link: '/wishes/chattels',
      section: 'wishes.chattels'
    },
    {
      title: 'Gifts of Real Estate',
      link: '/wishes/property',
      section: 'wishes.property'
    },
    {
      title: 'The Rest of My Estate',
      link: '/wishes/estate',
      section: 'wishes.estate'
    },
    {
      title: 'My Funeral',
      link: '/wishes/funeral',
      section: 'wishes.funeral'
    },
    {
      title: 'Legal',
      link: '/wishes/legal',
      section: 'wishes.legal'
    }
  ])
  .constant('directives.menu.essential_wishes_list', [
    {
      title: 'Guardians for my Children',
      link: '/wishes/guardians',
      section: 'wishes.guardians'
    },
    {
      title: 'Executors & Trustees',
      link: '/wishes/executors',
      section: 'wishes.executors'
    },
    {
      title: 'The Rest of My Estate',
      link: '/wishes/estate',
      section: 'wishes.estate'
    },
    {
      title: 'Legal',
      link: '/wishes/legal',
      section: 'wishes.legal'
    }
  ])
  .constant('directives.menu.optional_wishes_list', [
    {
      title: 'Gifts of Money',
      link: '/wishes/cash',
      section: 'wishes.cash'
    },
    {
      title: 'Gifts of Possessions',
      link: '/wishes/chattels',
      section: 'wishes.chattels'
    },
    {
      title: 'Gifts of Real Estate',
      link: '/wishes/property',
      section: 'wishes.property'
    },
    {
      title: 'My Funeral',
      link: '/wishes/funeral',
      section: 'wishes.funeral'
    }
  ])
  .constant('directives.menu.will_list', [
    {
      title: 'Me & My Will',
      link: '/will/me-and-my-will',
      section: 'will.review.clause.intro'
    },
    {
      title: 'Executors & Trustees',
      link: '/will/executors',
      section: 'will.review.clause.7'
    },
    {
      title: 'Guardians for my Children',
      link: '/will/guardians',
      section: 'will.review.clause.8'
    },
    {
      title: 'My Funeral',
      link: '/will/funeral',
      section: 'will.review.clause.9'
    },
    {
      title: 'Gifts of Money',
      link: '/will/legacies',
      section: 'will.review.clause.10'
    },
    {
      title: 'Gifts of Possessions',
      link: '/will/chattels',
      section: 'will.review.clause.11'
    },
    {
      title: 'Gifts of Real Estate',
      link: '/will/property',
      section: 'will.review.clause.12'
    },
    {
      title: 'The Rest of My Estate',
      link: '/will/estate',
      section: 'will.review.clause.13'
    }
  ])
  .service('directives.menu.service', ['directives.menu.wishes_list', 'directives.menu.essential_wishes_list', 'directives.menu.optional_wishes_list', 'directives.menu.will_list', function(WISHES_LIST, ESSENTIAL_WISHES_LIST, OPTIONAL_WISHES_LIST, WILL_LIST) {

    /**
     *  Helper function to determine if section is started
     *  @param {object} scope $rootScope
     *  @param {string} section
     *  @returns {boolean}
     *  @public
     */
    function isStarted(scope, section) {
      var state = findState(scope, section)
      if (_.isNull(state)) return false
      return !_.every(_.has(state, 'progress') ? [state] : state, _.isEmpty)
    }
    this.isStarted = isStarted

    /**
     *  Helper function to determine if section is complete
     *  @param {object} scope $rootScope
     *  @param {string} section
     *  @returns {boolean}
     *  @public
     */
    function isComplete(scope, section) {
      var state = findState(scope, section)
      if (_.isNull(state)) return false
      return _.every(_.has(state, 'progress') ? [state] : state, function(obj) {
        return _.property('progress')(obj) === 'confirmed'
      })
    }
    this.isComplete = isComplete

    /**
     *  Helper function to determine if any wishes area is started
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.wishesStarted = function(scope) {
      return _.any(WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if entire wishes area is complete
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.wishesComplete = function(scope) {
      return _.every(WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if essential wishes are started
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.essentialWishesStarted = function(scope) {
      return _.some(ESSENTIAL_WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if essential wishes are complete
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.essentialWishesComplete = function(scope) {
      return _.every(ESSENTIAL_WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if optional wishes are started
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.optionalWishesStarted = function(scope) {
      return _.some(OPTIONAL_WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if optional wishes are complete
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.optionalWishesComplete = function(scope) {
      return _.every(OPTIONAL_WISHES_LIST, function(route) {
        return isComplete(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if any will review is started
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.willStarted = function(scope) {
      return _.any(WILL_LIST, function(route) {
        return isStarted(scope, route.section)
      })
    }

    /**
     *  Helper function to determine if entire will review is complete
     *  @param {object} scope $rootScope
     *  @returns {boolean}
     *  @public
     */
    this.willComplete = function(scope) {
      return _.every(WILL_LIST, function(route) {
        return isStarted(scope, route.section)
      })
    }

    /**
     *  Utility for finding state of defined section
     *  @param {object} scope $rootScope
     *  @param {string} section
     *  @returns {object}
     *  @private
     */
    function findState(scope, section) {
      if (!_.has(scope, 'state')) return null
      if (!_.has(scope.state, section)) return null
      return scope.state[section]
    }
  }])
  .controller('directives.menu.controller', ['$rootScope', '$scope', '$location', '$state', 'bus', 'account.service', 'directives.menu.service', 'user', function($rootScope, $scope, $location, $state, bus, account, menu, user) {

    /** Nav offset */
    $scope.offsetNav = function() {
      return !!$rootScope.offsetNav
    }

    /** Toggle offset nav */
    $scope.toggleOffsetNav = function() {
      $rootScope.offsetNav = !$rootScope.offsetNav
    }

    /** Nav expanded */
    $scope.expandedNav = function() {
      return !!$rootScope.expandedNav
    }

    /** Toggle expanded nav */
    $scope.toggleExpandedNav = function() {
      $rootScope.expandedNav = !$rootScope.expandedNav
    }

    /** View expanded */
    $scope.expandedView = function() {
      return !!$rootScope.expandedView
    }

    /** Toggle expanded view */
    $scope.toggleExpandedView = function() {
      $rootScope.expandedView = !$rootScope.expandedView
    }

    /**
     * This checks to see if the provided state is the same as
     * the current state/URL
     **/
    $scope.isSameState = function(state) {
      var stateArray = state.split('')
      var currentStateArray = $state.current.name.split('')

      // loops the the input state array's length because
      // it will always be shorter than the current state URL
      // this checks to make sure each character is the same
      // if not, it immediately returns false (saves time)
      for (var i = 0; i <= stateArray.length - 1; i++) {
        if (stateArray[i] !== currentStateArray[i]) {
          return false
        }
      }
      return true
    }

    /**
     *  Determines key for advice text that should be presented currently
     */
    $scope.activeAdviceKey = function() {

      // nice bit of logic :)
      if (menu.isComplete($rootScope, 'register')) {
        if (straightThroughToEssentialWishes($rootScope)) {
          if (menu.essentialWishesComplete($rootScope)) {
            if (menu.optionalWishesComplete($rootScope) || menu.willStarted($rootScope)) {
              if (menu.willComplete($rootScope)) {
                if (menu.isStarted($rootScope, 'will.payment')) {

                  // not the strongest payment detection,
                  // since this is connected unreliably to
                  // application state, not paid status directly

                  // complete state
                  return 'download'
                }

                // payment still due
                return 'payment'
              }

              // final progression
              return 'review'
            }

            // by tertiary default
            return 'optionals'
          }

          // alernative secondary default
          return 'essentials'
        }

        if (menu.isStarted($rootScope, 'relationships')) {
          return 'assets'
        }

        // by secondary default
        return 'relationships'
      }

      // by default
      return 'register'

      /** Short-hand for use-case where relationships / assets can be skipped */
      function straightThroughToEssentialWishes(scope) {

        // My Friends and Family is started AND My Assets is started
        // OR any one of the four essential wishes has been completed
        return (menu.isStarted(scope, 'relationships') && menu.isStarted(scope, 'assets')) ||
          menu.essentialWishesStarted(scope)
      }
    }

    bus.subscribe($scope, 'action', '$state', 'change:success', function() {

      // propagate progress
      updateProgress()
    })

    // shorthand helper functions for macro sections
    $scope.complete = {

      // wishes macro section
      wishes: _.partial(menu.wishesComplete, $rootScope),

      // essential wishes macro section
      essentialWishes: _.partial(menu.essentialWishesComplete, $rootScope),

      // optional wishes macro section
      optionalWishes: _.partial(menu.optionalWishesComplete, $rootScope),

      // will macro section
      will: _.partial(menu.willComplete, $rootScope)
    }

    bus.subscribe($scope, 'data', 'state', 'propagated', function($event, arr) {

      // delete reference to existing application state index
      if (!_.isUndefined($rootScope.state)) delete $rootScope.state

      // propagate application state index in root
      $rootScope.state = _.mapObject(_.groupBy(arr, 'section'), function(tracked) {
        return _.any(tracked, _.property('tag')) ? _.indexBy(tracked, 'tag') : tracked[0]
      })
    })

    // immediately propagate
    bus.publish('data', 'state', 'propagated', user.state)

    function updateProgress() {
      return account.getProgress().then(function(arr) {
        bus.publish('data', 'state', 'propagated', arr)
      })
    }

  }])
