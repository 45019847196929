import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import '../factories/asset'

angular.module('app.assets.services.asset.list', ['authentication', 'app.account.service', 'app.assets.factories.asset'])
  .service('assets.service.asset.list', ['$http', 'auth', 'assets.factories.asset', function($http, auth, Asset) {

    var collection = [], self, selected, active

    /**
     *  Fetch assets from server side
     *  @returns {promise} $http
     *  @public
     */
    this.fetch = function() {
      var endpoint = auth.getBaseURL() + 'asset'

      // fetch assets
      return $http.get(endpoint, auth.getConfig())
            .then(res => {

              // empty existing collection
              collection = []

              // set each asset into collection
              _.each(res.data || [], function(asset) {
                collection.push( new Asset(asset) )
              })

              return this
            })
    }

    /**
     *  Get asset or all assets from collection
     *  @param {string} [id] Asset id to get specific asset
     *  @returns {object} Asset
     *  @public
     */
    function get(id) {
      if (!_.isUndefined(id)) {
        return _.findWhere(collection, { id: id })
      }
      return collection
    }
    this.get = get

    /** Mock asset with id of 1 */
    this.mock = function(data) {
      var asset = new Asset(_.extend(data, { id: 1 }))
      collection.push(asset)
      return asset
    }

    /** Remove mock asset */
    this.unmock = function() {
      collection = _.reject(collection, function(asset) {
        return asset.id === 1
      })
    }

    /**
     *  Add an asset
     *  @param {object} data
     *  @returns {promise} $http
     *  @public
     */
    this.add = function(data) {
      var endpoint = auth.getBaseURL() + 'asset'
      return $http.post(endpoint, data || {}, auth.getConfig())
            .then(function(res) {
              var asset = new Asset(res.data)
              collection.unshift(asset)
              return get(asset.id)
            }, function(res) {
              console.dir(res)
            })
    }

    /**
     *  Remove an asset
     *  @param {object} asset Object reference to asset
     *  @public
     */
    this.remove = function(asset) {
      var endpoint = auth.getBaseURL() + 'asset/' + asset.id, defer

      // delete asset through service layer
      defer = $http.delete(endpoint, auth.getConfig())

      // register promises callback
      defer.then(() => {

        // remove asset on successful deletion
        collection = _.without(collection, asset)
      })

      return defer
    }
    /**
     *  Get asset referencing user
     *  @returns {object} asset
     *  @public
     */
    function getSelf() {

      // self id of user person model,
      // immutable asset in collection
      if (self) return get(self)
    }
    this.getSelf = getSelf
    /**
     *  Get currently selected asset from collection
     *  @see this.select
     *  @public
     */
    function getSelected() {
      if (selected) return get((selected || {}).id)
    }
    this.getSelected = getSelected

    /**
     *  Get currently active asset from collection
     *  @see this.focus
     *  @public
     */
    function getActive() {
      return get((active || {}).id) || getSelf()
    }
    this.getActive = getActive

    /**
     *  Set selected asset from collection
     *  @param {object} asset Object reference to asset
     *  @public
     */
    function select(asset) {

      // set asset as selected
      selected = asset

      // sort();
    }
    this.select = select

    /**
     *  Set active asset from collection
     *  @param {object} asset Object reference to asset
     *  @public
     */
    function focus(asset) {

      // set asset as active
      active = asset

      // sort();
    }
    this.focus = focus

  }])
