import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import './factories/guardianship'

angular.module('app.wishes.guardianships.service', ['authentication', 'app.account.service', 'app.wishes.guardianships.factories.guardianship'])
  .service('wishes.guardianships.service', ['$http', 'auth', 'wishes.guardianships.factories.guardianship',
      function($http, auth, Guardianship) {
        var id = auth.getUserGuardianship(), guardianship

        // verify guardianship is defined, throw if not
        if (_.isUndefined(id)) throw new Error('Guardianship is not defined, malformed user data')

        /**
         *  Fetch guardianship from server side
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function() {
          var endpoint = auth.getBaseURL() + 'guardianship/' + id
          return $http.get(endpoint, auth.getConfig(['signed', 'deepextended'], 'GET', endpoint)).then(function(res) {

            // cache guardianship
            guardianship = new Guardianship(res.data)

            // return response for chaining
            return res
          })
        }

        /**
         *  Get guardianship object
         *  @returns {object} guardianship
         *  @public
         */
        this.get = function() {
          return guardianship
        }
      }
  ])
