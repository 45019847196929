import angular from 'angular'

import '../auth'

angular.module('app.directives.freemium', [])
  .controller('directives.freemium.controller', ['$scope', '$http', 'bus', 'freemium', function($scope, $http, bus, freemium) {

    var willPrice = 0
    var willPremium = false

    $scope.getWillPrice = function() {
      return willPrice
    }

    $scope.isWillPremium = function() {
      return willPremium
    }

    function currentWillPrice() {
      freemium.getWillPrice().then((res) => {
        willPremium = res.data.premium
        if (willPremium) {
          willPrice = (res.data.sum / 100).toFixed(2)
        } else {
          willPrice = 0
          willPrice = willPrice.toFixed(2)
        }
      })

      return (`£${willPrice}`)
    }

    currentWillPrice()

    bus.subscribe($scope, 'action', '$state', 'change:success', function() {

      // update premium price
      currentWillPrice()
    })
  }
  ])
  .directive('appFreemium', () => {
    return {
      restrict: 'E',
      replace: true,
      controller: 'directives.freemium.controller',
      template: require('../../tpl/directives/freemium.html')
    }
  })
  .service('freemium', ['$http', 'auth', function($http, auth) {

    this.getWillPrice = function() {
      var endpoint = auth.getBaseURL() + 'premium'

      return $http.get(endpoint, auth.getConfig(['signed', 'flat'], 'GET', endpoint))
    }

  }])
