import _ from 'underscore'
import angular from 'angular'

import '../../../auth'

import '../../../directives/drag'
import '../../../directives/carousel'
import '../estate/service'
import '../executors/service'
import '../services/gifts'
import '../guardianships/service'

angular
  .module('app.wishes.substitution', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.wishes.estate.service',
    'app.wishes.executors.service',
    'app.wishes.services.gifts',
    'app.wishes.guardianships.service',
    'app.wishes.substitution.factory',
  ])
  .controller('wishes.substitution.controller', [
    '$scope',
    '$http',
    '$state',
    '$stateParams',
    '$location',
    'auth',
    'wishes.estate.service',
    'wishes.executors.service',
    'wishes.services.gifts',
    'wishes.guardianships.service',
    function (
      $scope,
      $http,
      $state,
      $stateParams,
      $location,
      auth,
      estate,
      executorship,
      gifts,
      guardianship
    ) {
      // wishes context settings
      var context = {
        estate: {
          ref: 'estate',
          title: 'My Estate',
          unit: 'beneficiaries',
          service: estate,
        },
        executors: {
          ref: 'executors',
          title: 'My Executors & Trustees',
          unit: 'executors',
          service: executorship,
        },
        guardians: {
          ref: 'guardians',
          title: 'Guardians for my Children',
          unit: 'guardians',
          service: guardianship,
        },
        chattels: {
          ref: 'chattels',
          title: 'Gift of Possessions',
          unit: 'beneficiaries',
          service: gifts,
        },
        property: {
          ref: 'property',
          title: 'Gift of Real Estate',
          unit: 'beneficiaries',
          service: gifts,
        },
        cash: {
          ref: 'cash',
          title: 'Gift of Money',
          unit: 'beneficiaries',
          service: gifts,
        },
      }

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      // cache wishes area in context
      $scope.context = context[$stateParams.area]
      if (_.isUndefined($scope.context)) {
        // break out of controller,
        // and route back to wishes
        $location.path('/wishes')

        // throw error if context not provided
        throw new Error('No context provided to substitution.')
      } else {
        $scope.context.id = $stateParams.ref
      }

      $scope.family = []
      $scope.model = {
        // abstract container get method
        container: function () {
          return $scope.context.service.get($stateParams.ref)
        },
        substitutions: function () {
          // return substitutions in container
          if (_.isUndefined($scope.model.container())) return []
          return $scope.model.container().substitutions
        },
        parties: function () {
          // return parties in container
          if (_.isUndefined($scope.model.container())) return []
          return $scope.model.container()[$scope.context.unit]
        },
      }

      // Substitution Container

      /**
       *  Substitution exists uniquely in container
       *  @return {boolean} singleton
       */
      $scope.isSingleton = function () {
        return $stateParams.area === 'estate' || _.has($stateParams, 'ref')
      }

      /**
       *  Select substitution for editing
       *  @param {object} substitution
       */
      $scope.select = function (substitution) {
        // reset selected substitution if null
        if (_.isNull(substitution)) return $scope.deselect()

        // set selected substitution
        $scope.selected = substitution.id
      }

      /** Deselect substitution */
      $scope.deselect = function () {
        $scope.model.container($stateParams.ref).unmockSubstitution()

        // reset selected substitution
        $scope.selected = null
      }

      /**
       *  Get selected or singleton substitution
       *  @return {object} substitution
       */
      $scope.getSelected = function () {
        var selected

        if ($scope.isSingleton()) {
          // singleton substitution model,
          // resolve first in container
          selected = _.first($scope.model.substitutions())
        } else {
          // normal substitution model,
          // resolve selected
          selected = _.findWhere($scope.model.substitutions(), {
            id: $scope.selected,
          })
        }

        return selected
      }

      /** Determines selected state */
      $scope.existsSelected = function () {
        return (
          $scope.isSingleton() ||
          (!_.isNull($scope.selected) && !_.isUndefined($scope.selected))
        )
      }

      // scope methods - manage substitutions

      /** Create mock substitution */
      $scope.createSubstitution = function () {
        // mock substitution in container, will remove itself once persisted
        $scope.select(
          $scope.model
            .container($stateParams.ref)
            .mockSubstitution({ substitutedType: $scope.context.ref })
        )
      }

      /** Create substitution reference on candidate */
      $scope.updateSubstitution = function (candidate, substitution) {
        // (could abstract in party factory)
        // remove any existing substitution reference
        delete candidate.substitution
        if (_.isUndefined(substitution)) {
          // update extended status
          candidate.extended = candidate.substitutionRef === 'extended'

          // update distributed status
          candidate.distributed = candidate.substitutionRef === 'distributed'

          // persist candidate substitution status
          candidate.save({
            substitution: null,
            extended: candidate.extended,
            distributed: candidate.distributed,
          })
        } else {
          // resolve extended reference
          candidate.extended = false

          // resolve distributed reference
          candidate.distributed = false

          // replace substitution reference in model
          candidate.substitution = substitution

          // save substitution reference
          candidate.save({
            substitution: substitution._id,
            extended: candidate.extended,
            distributed: candidate.distributed,
          })
        }
      }

      /** Remove substitution */
      $scope.removeSubstitution = function (substitution) {
        return $scope.model
          .container($stateParams.ref)
          .removeSubstitution(substitution)
      }

      // Substitution Model

      $scope.loading = false
      /** Add substitute to selected substitution */
      $scope.addSubstitute = function ($event, person) {
        var promise

        // prevent default
        $scope.loading = true
        $event.preventDefault()

        // re-assign to a person object from the incoming person ID
        person = _.findWhere($scope.candidates, { _id: person })

        // get selected substitution
        var substitution = $scope.getSelected()

        if (!_.has(substitution, '_id')) {
          // substitution not yet persisted,
          // persist substitution and continue
          promise = $http
            .post(
              auth.getBaseURL() + 'substitute',
              { person: person._id },
              auth.getConfig('flat')
            )
            .then(
              function (res) {
                return $scope.model
                  .container($stateParams.ref)
                  .addSubstitution({
                    substitutedType: $scope.context.ref,
                    substitutes: [res.data._id],
                  })
              },
              function (err) {
                return err
              }
            )
        } else {
          promise = substitution.addSubstitute(person)
        }

        // handle carousel update
        // and loading state
        promise.then(function () {
          return updateCandidates().then(function () {
            $scope.loading = false
          })
        })

        return promise
      }

      /** Remove substitute from selected substitution */
      $scope.removeSubstitute = function ($event, substitute) {
        var activeSubstitution = $scope.getSelected()

        // prevent default
        $event.preventDefault()

        // cannot delete all substitutes from a substitution in Platform
        if (activeSubstitution.substitutes.length <= 1) {
          // remove entire substitution in invalid case
          return $scope
            .removeSubstitution(activeSubstitution)
            .then(function () {
              $scope.createSubstitution()
              updateCandidates()
            })
        }

        return activeSubstitution
          .removeSubstitute(substitute)
          .then(updateCandidates)
      }

      // fetch service dependencies
      $scope.context.service.fetch().then(function () {
        if ($scope.isSingleton() && _.isEmpty($scope.getSelected())) {
          // mock substitution for empty singleton
          $scope.createSubstitution()
        }
      })

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible substitute candidates */
      function updateCandidates() {
        var endpoint =
          auth.getBaseURL() +
          'candidates/' +
          $scope.context.ref +
          '-substitution' +
          ($stateParams.ref ? '/' + $stateParams.ref : '')

        return $http.get(endpoint, auth.getConfig()).then((res) => {
          $scope.candidates = res.data
          return res
        })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }

        currentState.params = { area: $stateParams.area, ref: $stateParams.ref }

        return JSON.stringify(currentState)
      }
    },
  ])
