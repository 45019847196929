'use strict'

import _ from 'underscore'
import angular from 'angular'
import uploadcare from 'uploadcare'

import '../api'

import user from '../../img/furniture/user.png'
import realEstate from '../../img/furniture/real-estate.png'
import personalProperty from '../../img/furniture/personal-property.png'

angular.module('app.directives.image', [])

// Uploadcare widget directive
  .directive('appUploader', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        ngModel: '=',
        appOnChange: '&'
      },
      controller: ['$scope', '$window', '$element', '$log', function($scope, $window, $element, $log) {
        if (!uploadcare) return $log.error('Uploadcare script has not been loaded!.')

        // flag for initial value change
        let settingInitialValue = false
        $element.attr('type', 'hidden')

        // bind Uploadcare widget
        $scope.widget = (_.has(uploadcare, 'SingleWidget') ? uploadcare : $window.uploadcare).SingleWidget($element)

        // register onChange handler
        $scope.widget.onChange(file => {
          if (!settingInitialValue) {
            $scope.appOnChange({ file: file })
          }

          // remove any existing flag
          settingInitialValue = false
        })

        // watch for changes to model
        $scope.$watch('ngModel', val => {
          if (val) {

            // flag value change
            settingInitialValue = true

            // set initial value in widget
            return $scope.widget.value(val)
          }
        })
      }]
    }
  })

  .directive('appImageUpload', () => {
    return {
      restrict: 'E',
      controller: 'ImageController',
      template: require('../../tpl/directives/image.html'),
      scope: {
        placeholder: '=',
        resource: '='
      }
    }
  })

  .constant('IMAGE_SIZE', '240x240')

  .controller('ImageController', ['$scope', 'bus', 'api', 'IMAGE_SIZE', function($scope, bus, api, IMAGE_SIZE) {

    // Placeholder images
    $scope.furniture = {
      user, realEstate, personalProperty
    }

    /** Changed image handler */
    $scope.changed = file => {

      // no file is found,
      // check to see if the user has removed an image
      if (!file) {
        if ($scope.image) {

          // unset image thumbnail
          $scope.thumbnail = null

          if (_.has($scope.image, '_id')) {

            // remove stored resource
            $scope.resource.remove({ id2: $scope.image._id })
          }
        }

        return
      }

      // handle uploads
      file.promise().done(fileInfo => {

        // upload successfully completed and file is ready
        // set image url in thumbnail
        $scope.thumbnail = generateThumbnailUrl(fileInfo.cdnUrl)

        // save resource
        $scope.image = $scope.resource.save(fileInfo)
      }).fail(error => {

        // upload failed or something else went wrong
        // error:
        //   'baddata' — invalid argument passed to file constructor
        //   'size' — file size is too large
        //   'upload' — error during upload
        //   'user' — upload cancelled
        //   'info' — error when fetching file information
        //   'deleted' — file was deleted (possible in a group)

        if (error === 'upload' && console.error) {

          // handle generic upload error,
          // likely detected due to provider downtime or usage (bandwidth / storage) limits
          console.error('Image upload failed, may be subject to usage limits')
        }
      })
    }

    // handle specific resource
    $scope.$watch('resource', resource => {
      if (resource) {

        // fetch collection
        resource.query(images => {
          if (images.length) {

            // register first image,
            // in collection in scope
            $scope.image = images[0]

            // set image url in thumbnail
            $scope.thumbnail = generateThumbnailUrl($scope.image.cdnUrl)
          } else {

            // register new resource in scope
            $scope.image = new $scope.resource()
          }
        })
      }
    })

    /**
   *  Generate customised url for thumbnail image
   *  @param {string} cdnUrl Uploadcare fileInfo.cdnUrl
   *  @return {string} url
   */
    function generateThumbnailUrl(cdnUrl) {
      return cdnUrl + '-/scale_crop/' + IMAGE_SIZE + '/center/'
    }
  }])
