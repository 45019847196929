import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../factories/links'

angular.module('app.relationships.services.link.table', ['authentication', 'app.relationships.factories.links'])
  .service('relationships.service.link.table', ['$http', 'auth', 'relationships.factory.link', 'relationships.factory.link.toTestator',
      function($http, auth, Link, RelationshipToTestator) {
        var collection = []

        /**
         *  Fetch links from server side
         *  @param {array} [nodes]
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function(nodes) {
          var flat = !_.isUndefined(nodes)
          return $http.get(auth.getBaseURL() + 'relationship', auth.getConfig((flat) ? 'flat' : _.noop()))
            .then(res => {

              // empty existing collection
              collection = []

              // set each person into collection
              _.each(res.data || [], function(relationship) {
                if (flat) {
                  relationship.origin = _.findWhere(nodes, { id: relationship.origin })
                  relationship.target = _.findWhere(nodes, { id: relationship.target })
                }
                collection.push( new Link(relationship) )
              })

              return this
            })
        }

        /**
         *  Get node or all nodes from collection
         *  @param {string} [id] Node id to get related links
         *  @returns {object} Link
         *  @public
         */
        function get(id) {
          if (!_.isUndefined(id)) {
            return _.filter(collection, function(link) {
              return link.origin === id || link.target === id
            })
          }
          return collection
        }
        this.get = get

        /**
         *  Add a link
         *  @param {string} type
         *  @param {object} origin Node linked from
         *  @param {object} target Node linked to
         *  @returns {promise} $http
         *  @public
         */
        this.add = function(type, origin, target) {
          var endpoint = auth.getBaseURL() + 'relationship',
              link = new Link({ type: type || 'other', origin: origin, target: target }),
              data = _.pick(link, ['type', 'origin', 'target'])

          if (!exists(link)) {
            return $http.post(endpoint, data, auth.getConfig('flat'))
              .then(function(res) {

                // map to model, preserving original origin and target object references
                var model = new Link(_.extend(res.data, { origin: link.originModel, target: link.targetModel }))

                // add model to table
                collection.push(model)
                return model
              })
          }

          alert('Link already exists or creates a paradox.')
          return false

          /**
           *  Check if link exists entirely or partially in collection
           *  @param {object} obj Link object
           *  @private
           */
          function exists(obj) {

            // attributes to delimit by
            var attrs = ['type', 'origin', 'target'], inv

            // pick delimited attributes from object
            obj = _.pick(obj, attrs)

            // reference inverse object with delimited attributes
            inv = _.pick(_.extend(_.clone(obj), { origin: obj.target, target: obj.origin }), _.rest(attrs))

            return !!(_.find(collection, function(link) {
              return _.isEqual(_.pick(link, attrs), obj) || _.isEqual(_.pick(link, _.rest(attrs)), inv)
            }))
          }
        }

        /**
         *  Remove a link
         *  @param {object} link Object reference to link
         *  @public
         */
        this.remove = function(link) {
          var endpoint = auth.getBaseURL() + 'relationship/' + link._id, defer

          // delete link through service layer
          defer = $http.delete(endpoint, auth.getConfig())

          // register promises callback
          defer.then(() => {

            // remove link on successful deletion
            collection = _.without(collection, link)
          })

          return defer
        }

        /**
         *  Remove the reference to a node in links collection
         *  @param {object} node Object reference to node
         *  @public
         */
        this.unreference = function(node) {

          // remove filtered list of referenced links
          _.each(_.filter(collection, function(link) {
            return link.origin === node.id || link.target === node.id

            // pass link up for deletion
          }), _.bind(this.remove, this))
        }

        /**
         *  Retrieve relationship to testator definition
         *  @param {string} id Node id
         *  @param {array} [relationships]
         *  @returns {string} Relationships to testator
         */
        function relationshipToTestator(id, relationships) {
          relationships = get(auth.getUserIdentity())
          return (!_.isEqual(id, auth.getUserIdentity())) ? new RelationshipToTestator(id, relationships) : {}
        }
        this.relationshipToTestator = relationshipToTestator

        function isTestator(id) {
          return (_.isEqual(id, auth.getUserIdentity()))
        }
        this.isTestator = isTestator
      }
  ])
