import angular from 'angular'

angular.module('app.directives.carousel', []).directive('appCarousel', () => {
  function link(scope, element) {
    const track = element.find('.carousel-track')[0]

    scope.scrollLeft = () => {
      const top =
        track.offsetHeight > track.offsetWidth ? track.offsetHeight * -1 : 0
      const left = top ? 0 : track.offsetWidth * -1

      track.scrollBy({ top, left, behavior: 'smooth' })
    }

    scope.scrollRight = () => {
      const top =
        track.offsetHeight > track.offsetWidth ? track.offsetHeight : 0
      const left = top ? 0 : track.offsetWidth

      track.scrollBy({ top, left, behavior: 'smooth' })
    }
  }

  return {
    link,
    scope: {},
    restrict: 'E',
    transclude: true,
    template: require('../../tpl/directives/carousel.html'),
  }
})
