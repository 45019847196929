import _ from 'underscore'
import angular from 'angular'

import 'typeahead'
import '../bus'
import '../iso'
import './typeahead'
import '../components/relationships/services/address.list'

angular.module('app.directives.address', ['msgbus', 'iso', 'app.directives.typeahead', 'app.relationships.services.address.list'])
  .controller('directives.address.controller', ['$scope', '$http', 'bus', 'iso', 'relationships.service.address.list', function($scope, $http, bus, iso, addresses) {
    var key = 'PH29-ZC49-TH31-RE55', engine

    // build typeahead engines
    engine = {

      // postcode remote engine
      postcode: new window.Bloodhound({
        datumTokenizer: window.Bloodhound.tokenizers.whitespace,
        queryTokenizer: window.Bloodhound.tokenizers.whitespace,
        limit: 128,
        remote: {
          url: '/PostcodeAnywhere/Interactive/FindByPostcode/v1.00/json3.ws?Key=' + key + '&Postcode=%QUERY',
          filter: function(data) {
            return _.has(_.first(data.Items, 'Error')) ? [] : data.Items
          }
        }
      }),

      // country local engine
      country: new window.Bloodhound({
        datumTokenizer: function(d) { return window.Bloodhound.tokenizers.whitespace(d.expanded_name || d.name) },
        queryTokenizer: window.Bloodhound.tokenizers.whitespace,
        limit: 128,
        local: iso.getISOCountries()
      })
    }
    engine.country.initialize()
    engine.postcode.initialize()

    // define datasets
    $scope.dataset = {
      postcode: {
        displayKey: function(obj) {
          if (!_.has(obj, 'StreetAddress') && !_.has(obj, 'Place')) {
            return 'No address found'
          }

          return obj.StreetAddress + ', ' + obj.Place
        },
        source: engine.postcode.ttAdapter()
      },
      country: {
        displayKey: function(obj) {
          return obj.expanded_name || obj.name
        },
        source: engine.country.ttAdapter()
      }
    }

    // define initial state
    if ((!_.isEmpty($scope.model) || $scope.showDisplay || $scope.delegated) && !$scope.showEdit) {
      $scope.state = 'address'
    } else if ($scope.useExisting) {
      $scope.state = 'select'
    } else {
      $scope.state = 'lookup'
    }

    // determine whether address model exists
    $scope.exists = !_.isEmpty($scope.model)

    /** State change helper */
    $scope.toggle = function($event, state) {

      // prevent default
      if ($event) $event.preventDefault()
      $scope.state = state
    }

    // abstract iso service name lookup
    $scope.getNameByCountryCode = iso.getNameByCountryCode

    /** Trigger cancel state */
    $scope.cancel = function($event) {

      // prevent default
      if ($event) $event.preventDefault()

      // empty query
      $scope.query = ''

      // progress correct state
      if ($scope.useExisting || $scope.state === 'address' || $scope.delegated || $scope.showEdit) {

        // empty existing values in model
        _.each(_.keys($scope.model), function(prop) {
          delete $scope.model[prop]
        })
        $scope.state = $scope.useExisting ? 'select' : 'lookup'
      } else {
        $scope.state = $scope.exists ? 'address' : 'lookup'
      }

      // abstract cancel method, where provided
      if (_.isFunction($scope.reset)) $scope.reset()
    }

    /** Trigger edit state */
    $scope.edit = function($event) {

      // prevent default
      if ($event) $event.preventDefault()
      $scope.state = $scope.useExisting ? 'select' : (_.isEmpty($scope.model) ? 'lookup' : 'edit')
    }

    /** Save address to parent container */
    $scope.save = function($event) {

      // prevent default
      if ($event) $event.preventDefault()

      // progress state
      $scope.state = 'address'

      // progress persistence
      $scope.exists = true

      // abstract submit method, where provided
      if (_.isFunction($scope.submit)) $scope.submit()
    }

    // set up data structures
    if (!$scope.useExisting) {

      // bind to postcode lookup query
      $scope.$watch('query', function(result) {
        if (_.has(result, 'Id')) {
          $http.get('/PostcodeAnywhere/Interactive/RetrieveById/v1.30/json3.ws?Key=' + key + '&Id=' + result.Id).then(function(res) {

            // parse result from response
            var input = _.first((res.data || {}).Items)

            // write model with result
            $scope.model = _.extend($scope.model || {}, {
              line1: input.Line1,
              line2: input.Line2,
              city: input.PostTown,
              region: input.County,
              postcode: input.Postcode,
              country: iso.getCountryCodeByName(input.CountryName)
            })

            // any previous model overwritten
            $scope.exists = false

            // progress edit state
            $scope.state = 'edit'

            // empty query
            $scope.query = ''
          })
        }
      })

      // bind to country lookup query
      $scope.$watch('country', function(result) {
        if (_.has(result, 'value')) {

          // set value as country
          $scope.model.country = result.value

          // empty query
          $scope.country = ''
        }
      })
    } else {
      $scope.addresses = []

      // fetch existing addresses
      addresses.fetch().then(function() {

        // reference addresses in scope
        $scope.addresses = addresses.get()

        // delete and re-assign the address based on the input id
        if (_.has($scope.model, '_id')) {
          var id = $scope.model._id
          delete $scope.model
          $scope.model = _.findWhere($scope.addresses, { _id: id })
        }
      })

      // bind to address id changes
      $scope.$watch('model._id', function(id) {

        // delete and re-assign the address based on the input id
        if (!_.isEmpty($scope.addresses)) {
          delete $scope.model
          $scope.model = _.findWhere($scope.addresses, { _id: id })
        }
      })
    }

    // subscribe as delegate
    if ($scope.delegated) {

      // subscribe edit state
      bus.subscribe($scope, 'action', 'delegates', 'edit', function() {
        $scope.edit()
      })

      // subscribe view state
      bus.subscribe($scope, 'action', 'delegates', 'save', function() {
        $scope.save()
      })
    }

  }])
  .directive('appAddressFind', function() {
    return {
      restrict: 'E',
      controller: 'directives.address.controller',
      scope: {
        model: '=ngModel',
        submit: '&?ngSubmit',
        reset: '&?ngReset',
        useExisting: '=?appUseExisting',
        showEdit: '=?appShowEdit',
        showDisplay: '=?appShowDisplay',
        delegated: '=?appDelegate'
      },
      template: require('../../tpl/directives/address.html')
    }
  })
