import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../services/beneficiaries'
import '../substitution/factory'
import './beneficiary'

angular
  .module('app.wishes.factories.gift', [
    'authentication',
    'app.wishes.services.beneficiaries',
    'app.wishes.factories.beneficiary',
    'app.wishes.substitution.factory',
  ])
  .factory('wishes.factories.gift', [
    '$http',
    '$q',
    'auth',
    'wishes.factories.beneficiary',
    'wishes.substitution.factory',
    function ($http, $q, auth, Beneficiary, Substitution) {
      return constructor

      /**
       *  Return gift
       *  @param {object} obj Existing data
       *  @returns {object} Gift
       *  @constructor
       */
      function constructor(obj) {
        // unique id
        var id = (this.id = obj._id || 1)

        // map beneficiaries to factory instances
        obj.beneficiaries = _.map(obj.beneficiaries, function (beneficiary) {
          return new Beneficiary(beneficiary)
        })

        // map substitutions to factory instances
        obj.substitutions = _.map(obj.substitutions, function (substitution) {
          return new Substitution(substitution)
        })

        /**
         *  Check whether any beneficiary is represented in the gift
         *  @returns {boolean} exists
         *  @public
         */
        this.hasBeneficiary = function () {
          return _.has(this, 'beneficiaries') && this.beneficiaries.length
        }

        /**
         *  Check whether any asset is represented in the gift
         *  @returns {boolean} exists
         *  @public
         */
        this.hasAsset = function () {
          return _.has(this, 'asset') && !_.isEmpty(this.asset)
        }

        /**
         *  Check whether beneficiary is already represented in the gift
         *  @param {object} person Person object
         *  @returns {boolean} exists
         *  @public
         */
        this.existsBeneficiary = function (person) {
          return _.some(this.beneficiaries, function (beneficiary) {
            return (
              (_.isObject(beneficiary.person)
                ? beneficiary.person._id
                : beneficiary.person) === person._id
            )
          })
        }

        /**
         *  Check whether asset is already represented in the gift
         *  @param {object} asset
         *  @returns {boolean} exists
         *  @public
         */
        this.existsAsset = function (asset) {
          return _.isEqual(this.asset?._id, asset._id)
        }
        this.addBeneficiary = function (person) {
          var endpoint = auth.getBaseURL() + 'gift/' + id + '/beneficiaries'
          var deferred = $q.defer()

          if (
            ((this.asset || {}).type === 'cash' ||
              (this.asset || {}).type === 'personalProperty') &&
            (this.beneficiaries || []).length
          ) {
            deferred.reject('Only one beneficiary is allowed.')
          } else {
            if (!this.existsBeneficiary(person)) {
              $http
                .post(
                  endpoint,
                  { person: person._id },
                  auth.getConfig('extended'),
                )
                .then((res) => {
                  this.beneficiaries.push(res.data)
                  deferred.resolve(res)
                }, deferred.reject)
            } else {
              deferred.reject('Person is already added as a beneficiary.')
            }
          }

          return deferred.promise
        }
        this.addAsset = function (asset) {
          let endpoint = auth.getBaseURL() + 'gift/' + id
          const deferred = $q.defer()

          if (!this.existsAsset(asset) && _.has(asset, '_id')) {
            $http
              .put(endpoint, { asset: asset._id }, auth.getConfig('extended'))
              .then((res) => {
                this.asset = res.data?.asset
                deferred.resolve(this.asset)
              }, deferred.reject)
          } else {
            endpoint += '/asset'
            $http
              .post(endpoint, asset, auth.getConfig('extended'))
              .then((res) => {
                this.asset = res.data
                deferred.resolve(this.asset)
              }, deferred.reject)
          }

          return deferred.promise
        }
        this.removeBeneficiary = function (beneficiary) {
          var endpoint =
            auth.getBaseURL() +
            'gift/' +
            id +
            '/beneficiaries/' +
            beneficiary._id
          return $http.delete(endpoint, auth.getConfig()).then(() => {
            this.beneficiaries = _.without(this.beneficiaries, beneficiary)
          })
        }

        this.removeAsset = function () {
          var endpoint = auth.getBaseURL() + 'gift/' + id
          return $http
            .put(endpoint, { asset: null }, auth.getConfig())
            .then(() => {
              this.asset = undefined
            })
        }

        /** Mock a substitution with id of 1 */
        this.mockSubstitution = function (data) {
          var substitution = new Substitution(_.extend(data, { id: 1 }))
          this.substitutions.push(substitution)
          return substitution
        }

        /** Remove mock substitution */
        this.unmockSubstitution = function () {
          this.substitutions = _.reject(
            this.substitutions,
            function (substitution) {
              return substitution.id === 1
            },
          )
        }

        this.addSubstitution = function (data) {
          var endpoint = auth.getBaseURL() + 'gift/' + id + '/substitutions'
          var deferred = $q.defer()

          $http
            .post(endpoint, data, auth.getConfig('deepextended'))
            .then((res) => {
              var substitution = new Substitution(res.data)
              this.substitutions.push(substitution)
              this.unmockSubstitution()
              deferred.resolve(substitution)
            }, deferred.reject)

          return deferred.promise
        }

        this.removeSubstitution = function (substitution) {
          if (substitution.mocked) return this.unmockSubstitution()
          var endpoint =
            auth.getBaseURL() +
            'gift/' +
            id +
            '/substitutions/' +
            substitution._id
          return $http.delete(endpoint, auth.getConfig()).then(() => {
            this.substitutions = _.without(this.substitutions, substitution)
          })
        }

        /**
         *  Save attributes to gift object
         *  @param {object} attrs Attributes object
         *  @returns {promise} $http
         */
        this.save = function (attrs) {
          if (!_.isUndefined(id)) {
            var endpoint = auth.getBaseURL() + 'gift/' + id
            return $http
              .put(endpoint, attrs, auth.getConfig())
              .then(function (res) {
                return res.data
              })
          }
        }

        this.saveAsset = function (data) {
          if (!_.isUndefined(data._id)) {
            var endpoint = auth.getBaseURL() + 'asset/' + data._id
            return $http
              .put(endpoint, data, auth.getConfig())
              .then(function (res) {
                return res.data
              })
          }
        }

        return _.extend(this, obj)
      }
    },
  ])
