import _ from 'underscore'
import angular from 'angular'

import 'typeahead'
import '../../../auth'
import '../../../directives/drag'
import '../../../directives/carousel'
import '../../../directives/typeahead'
import '../../account/service'
import '../services/gifts'

angular
  .module('app.wishes.cash', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.directives.typeahead',
    'app.account.service',
    'app.wishes.services.gifts',
  ])
  .controller('wishes.cash.controller', [
    '$scope',
    '$http',
    '$state',
    '$stateParams',
    '$sce',
    'auth',
    'account.service',
    'wishes.services.gifts',
    function ($scope, $http, $state, $stateParams, $sce, auth, account, gifts) {
      let selected = $stateParams.id || 1

      // initiate tutorial
      $scope.info = false

      import('../../../../tpl/wishes/cash/info.html').then((infoTemplate) => {
        $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
      })

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.setInfo = function (state) {
        $scope.info = state
      }

      // track state for new tag
      if ($stateParams.id) {
        account
          .getProgress({
            section: 'wishes.cash',
            tag: $stateParams.id,
          })
          .then(function (tracked) {
            if (!tracked) {
              return account.setProgress({
                section: 'wishes.cash',
                tag: $stateParams.id,
              })
            }
          })
      }

      $scope.confirm = function ($event) {
        if ($event) $event.preventDefault()
        return account.setProgress(
          {
            section: 'wishes.cash',
            tag: $scope.model.gift?.id,
          },
          'confirmed',
        )
      }

      $scope.model = {
        get gift() {
          return gifts.get(selected)
        },
        get gifts() {
          return gifts.getByAssetType('cash')
        },
      }

      // Legacy Amount Methods

      /** Add cash legacy to gift */
      $scope.saveAmount = async ($event, value) => {
        if ($event) $event.preventDefault()

        if (!$scope.model.gift?._id && !$scope.model.gift?.asset?._id) {
          // gift not yet persisted,
          // persist gift and continue
          const gift = await gifts.add({})
          selected = gift.id

          return gift.addAsset({
            type: 'cash',
            value: value,
          })
        }

        if ($scope.model.gift?._id && !$scope.model.gift?.asset?._id) {
          // asset not yet persisted,
          // persist asset with value
          return $scope.model.gift.addAsset({
            type: 'cash',
            value: value,
          })
        }

        // update existing asset
        const res = await $http.put(
          `${auth.getBaseURL()}asset/${$scope.model.gift.asset._id}`,
          { value: value },
          auth.getConfig('flat'),
        )

        // update value locally
        $scope.model.gift.asset.value = res.data.value
      }

      // Population Methods

      $scope.exists = function (person) {
        if (!_.isUndefined($scope.model.gift._id)) {
          return $scope.model.gift.existsBeneficiary(person)
        }
      }

      /** Add beneficiary to gift */
      $scope.add = function ($event, person) {
        // prevent default
        $event.preventDefault()
        if (!$scope.loading) {
          $scope.loading = true

          // re-assign to a person object from the incoming person ID
          person = _.findWhere($scope.candidates, { _id: person })
          if (!_.has($scope.model.gift, '_id')) {
            // gift not yet persisted,
            // persist gift and continue
            return gifts
              .add({})
              .then(function (gift) {
                selected = gift.id
                return gift.addBeneficiary(person)
              })
              .then(updateCandidates)
              .then(() => ($scope.loading = false))
          }

          return $scope.model.gift
            .addBeneficiary(person)
            .then(updateCandidates)
            .then(() => ($scope.loading = false))
        }
      }

      $scope.remove = function ($event, beneficiary) {
        $event.preventDefault()
        $scope.model.gift.removeBeneficiary(beneficiary).then(updateCandidates)
      }

      // Gift CRUD Methods

      $scope.deleteGift = function (gift) {
        if (_.has(gift, '_id')) {
          gifts.remove(gift).then(function () {
            if ($scope.model.gifts.length) {
              $state.go('auth.wishesOptionalCash')
            } else {
              $state.go('auth.wishesOptional')
            }
          })
        } else {
          if ($scope.model.gifts.length) {
            $state.go('auth.wishesOptionalCash')
          } else {
            $state.go('auth.wishesOptional')
          }
        }
      }

      $scope.cancel = function ($event) {
        if ($event) $event.preventDefault()
        return $scope.deleteGift($scope.model.gift)
      }

      // TODO: the background fetch causes a timing bug, hard to reproduce outside automated browser tests, could instead introduce a loading state and await this fetch
      // fetch gifts
      gifts.fetch()

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible beneficiary candidates */
      function updateCandidates() {
        const endpoint = auth.getBaseURL() + 'candidates/cash'
        return $http.get(endpoint, auth.getConfig()).then((res) => {
          $scope.candidates = res.data
          return res
        })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }
        let giftModelId = $stateParams.id || $scope.model.gift?.id

        if (giftModelId) {
          currentState.params = { id: giftModelId }
        }

        return JSON.stringify(currentState)
      }
    },
  ])
