import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import '../factories/beneficiary'

angular
  .module('app.wishes.services.beneficiaries', [
    'authentication',
    'app.account.service',
    'app.wishes.factories.beneficiary',
  ])
  .service('wishes.services.beneficiaries', [
    '$http',
    'auth',
    'wishes.factories.beneficiary',
    function ($http, auth, Beneficiary) {
      var collection = []

      /**
       *  Fetch beneficiaries from server side
       *  @returns {promise} $http
       *  @public
       */
      this.fetch = function () {
        var endpoint = auth.getBaseURL() + 'beneficiary'
        return $http
          .get(endpoint, auth.getConfig('signed', 'GET', endpoint))
          .then((res) => {
            // map results into memory
            collection = _.map(
              res.data || [],
              (beneficiary) => new Beneficiary(beneficiary),
            )

            // return response for chaining
            return res
          })
      }

      /**
       *  Get item or all items from collection
       *  @param {string} [id] Beneficiary id to retrieve
       *  @returns {object|array} Beneficiary or Beneficiaries
       *  @public
       */
      this.get = function (id) {
        return !_.isUndefined(id)
          ? _.findWhere(collection, { _id: id })
          : collection
      }

      /**
       *  Get substitutions related to beneficiaries in collection
       *  @returns {array} Substitutions
       *  @public
       */

      // this.getSubstitutions = function() {
      //     return substitutions.get();
      // };

      /**
       *  Check whether beneficiary is already represented in collection
       *  @param {object} person Person object
       *  @returns {boolean} exists
       *  @public
       */
      this.exists = function (person) {
        return _.find(collection, function (obj) {
          return (obj.person || {})._id === person._id
        })
      }

      /**
       *  Add a beneficiary
       *  @param {object} person Person object
       *  @returns {promise} $http
       *  @public
       */
      this.add = function (person) {
        var endpoint = auth.getBaseURL() + 'beneficiary'
        return $http
          .post(
            endpoint,
            { person: person._id },
            auth.getConfig('signed', 'POST', endpoint),
          )
          .then((res) => {
            var ref = res.data
            return $http
              .get(
                auth.getBaseURL() + 'beneficiary',
                auth.getConfig(
                  'signed',
                  'GET',
                  auth.getBaseURL() + 'beneficiary',
                ),
              )
              .then((res) => {
                var beneficiary = new Beneficiary(
                  _.findWhere(res.data, { _id: ref._id }),
                )
                collection.push(beneficiary)
                return this.get(beneficiary.id)
              })
          })
      }

      /**
       *  Find beneficiaries with an appointed substitution
       *  @param {object} substitution Object reference to substitution
       *  @returns {array} Array of referenced beneficiaries
       */
      this.referenced = function (substitution) {
        return _.filter(this.get(), function (beneficiary) {
          return (beneficiary.substitution || {})._id === substitution._id
        })
      }

      /**
       *  Remove substitution from one or multiple beneficiaries
       *  @param {object} substitution Object reference to substitution
       *  @param {object} [beneficiary] Object reference to beneficiary
       */
      this.unreference = function (substitution, beneficiary) {
        var references = this.get()
        if (!_.isUndefined(beneficiary)) references = [beneficiary]

        // remove substitution reference from applicable beneficiaries
        _.each(references, function (beneficiary) {
          if ((beneficiary.substitution || {})._id === substitution._id) {
            beneficiary.substitution = undefined
            beneficiary.save({ substitution: null })
          }
        })
      }

      /**
       *  Remove a beneficiary
       *  @param {object} beneficiary Object reference to beneficiary
       *  @public
       */
      this.remove = function (beneficiary) {
        var endpoint = auth.getBaseURL() + 'beneficiary/' + beneficiary._id
        if (beneficiary.substitution)
          this.unreference(beneficiary.substitution, beneficiary)
        return $http
          .delete(endpoint, auth.getConfig('signed', 'DELETE', endpoint))
          .then(() => (collection = _.without(collection, beneficiary)))
      }
    },
  ])
