import _ from 'underscore'
import angular from 'angular'

import 'angular-sanitize'

import '../../auth'
import '../account/service'
import '../payment/service'

const API_BASE_URL = process.env.AFFIO_API_BASE_URL

angular.module('app.will', ['ngSanitize', 'authentication', 'app.account.service', 'app.payment.service'])
  .controller('will.controller', ['$scope', '$http', '$stateParams', 'auth', 'account.service', 'payment.service', 'user', function($scope, $http, $stateParams, auth, account, payments, user) {
    account.setProgress('will.review')

    // expose state parameters to scope
    $scope.params = $stateParams

    // construct model
    $scope.model = {

      // clauses to render in review
      clauses: ['intro', '7', '8', '13', '10', '11', '12', '9'],
      clauseNames: ['me-and-my-will', 'executors', 'guardians', 'estate', 'legacies', 'chattels', 'property', 'funeral'],
      clauseTitles: ['Me & My Will', 'Executors & Trustees', 'Guardians', 'Estate', 'Cash Gifts', 'Personal Possessions', 'Real Estate', 'Funeral Wishes'],

      // clause model data
      clause: {}
    }

    /**
     *  Get position of clause reference in clauses array
     *  @param {array} array Array to check
     *  @param {number} num Clause reference
     *  @returns {number} index of clause reference in clauses array
     */
    $scope.getIndexOf = function(array, num) {
      return _.indexOf(array, num)
    }

    /**
     *  Set active clause number
     *  @param {number} num Clause number
     */
    $scope.setActive = function(num) {

      // render active clause
      step(num).then(function() {
        $scope.active = num
      })
    }

    /**
     *  Toggle approved state on specified clause
     *  @param {number} num Clause number
     */
    $scope.toggleApproved = function(num) {
      let clause = $scope.model.clause[num]

      if (_.isUndefined(clause)) {
        throw new Error('Clause undefined, cannot approve clause reference ' + num + '.')
      }

      // toggle approved state in state tracking
      account.setProgress('will.review.clause.' + num).then(
        () => clause.approved = true,
        () => console.error('Unable to approve clause')
      )
    }

    /**
     *  Check if clause is approved
     *  @param {number} num Clause number
     *  @returns {boolean} Approved state
     */
    $scope.isApproved = function(num) {
      var clause = $scope.model.clause[num]
      return !_.isUndefined(clause) && clause.approved
    }

    /**
     *  Check if all clauses are approved
     *  @returns {boolean} Sum approved state
     */
    $scope.isApprovedAll = function() {
      return _.every($scope.model.clause, function(clause) {
        return clause.approved
      })
    };

    // request approval progress
    (function(progress) {

      // read clause from route
      var clause = $stateParams.clause

      // loop through clauses to register approval states
      _.each($scope.model.clauses, num => {

        // set approval in clause model reference
        $scope.model.clause[num] = {
          approved: !_.isEmpty(_.findWhere(progress, { section: 'will.review.clause.' + num }))
        }
      })

      if (!_.isUndefined(clause)) {
        if (_.contains($scope.model.clauseNames, clause)) {
          $scope.state = 'review'
          $scope.setActive( $scope.model.clauses[_.indexOf($scope.model.clauseNames, clause)] )
        } else {
          $scope.active = true
          $scope.state = 'invalid'
        }
      }
    })(user.state)

    /**
     * Moves to previous clause
     * @param {number} num Clause Number
     * @returns {string} either url of previous clause or empty string
     **/
    $scope.prevClause = function(num) {

      // set to the index of the 'previous' clause
      let index = $scope.getIndexOf($scope.model.clauses, num) - 1
      let clauseName = ''
      if (index >= 0) {

        // for disabling "previous" button
        $scope.startOfClauses = false
        clauseName = $scope.model.clauseNames[index]
        return clauseName
      }

      $scope.startOfClauses = true
      return clauseName
    }

    /**
     * Moves to next clause
     * @param {number} num Clause Number
     * @returns {string} either url of next clause or empty string
     **/
    $scope.nextClause = function(num) {

      // set to the index of the 'next' clause
      let index = $scope.getIndexOf($scope.model.clauses, num) + 1
      let clauseName = ''
      if (index < $scope.model.clauseNames.length) {

        // for disabling "next" button
        $scope.endOfClauses = false
        clauseName = $scope.model.clauseNames[index]
        return clauseName
      }

      $scope.endOfClauses = true
      return clauseName
    }

    // request payment status
    payments.getStatus().then(function(paid) {
      $scope.paid = paid
    })

    /**
     *  Move between clauses
     *  @param {number} num Clause number
     *  @returns {promise} $http
     */
    function step(num) {
      return $http.get(`${API_BASE_URL}/will/preview/all/${num}/0/`, auth.getConfig()).then(res => {

        // set data in model
        $scope.model.clause[num] = _.extend($scope.model.clause[num], {
          summary: clean(res.data.summary),
          advice: clean(res.data.advice),
          validation: (res.data.validation) ? res.data.validation : false
        })
      }, res => console.dir(res))

      /** Clears html in response from artefacts */
      function clean(html) {
        return (html || '').replace('\n', '')
      }
    }
  }])
