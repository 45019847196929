import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../../iso'
import '../services/link.table'

angular.module('app.relationships.factories.nodes', ['authentication', 'iso', 'app.relationships.services.link.table'])
  .value('factory.node.createWizardSteps', ['details', 'relationships', 'address'])
  .factory('relationships.factory.node', ['$q', '$http', 'auth', 'iso', 'relationships.service.link.table', 'factory.node.createWizardSteps', function($q, $http, auth, iso, links, createWizardSteps) {
    return constructor

    /**
     *  Return node by type
     *  @param {object} obj Node data
     *  @returns {object} Node
     *  @constructor
     */
    function constructor(obj) {

      // unique id
      var id = this.id = obj._id || 1

      // identify immutable where not described
      this.immutable = obj.immutable || id === auth.getUserIdentity()

      /** Join to link table */
      this.links = function() {
        return links.get(id)
      }

      /** Mocked Case */
      this.mocked = !_.has(obj, '_id')

      /** Complete Case */
      this.isComplete = function() {
        return completed(this)
      }

      // determine if person details are complete
      this.complete = completed(obj)

      /**
       *  Check complete case
       *  @param {object} obj Person object
       *  @return {boolean} Completed
       */
      function completed(obj) {
        return _.every(['_id', 'name', 'surname', 'gender'], _.partial(_.has, obj))
      }

      // Name
      this.name = obj.name || ''
      this.surname = obj.surname || ''

      /** Cosmetic full name */
      this.fullname = function() {
        var name = (this.name) ? this.name + ' ' : ''
        name = (this.surname) ? name + this.surname : ''
        return name
      }

      // capture static reference
      this.fullnameStatic = this.fullname()

      // Photo

      // image container
      this.images = obj.images || []

      /**
       *  Determine if photo exists
       *  @returns {boolean}
       */
      this.hasPhoto = function() {
        return !_.isEmpty(this.images)
      }

      // Address

      // address container
      this.address = obj.address || {}

      /**
       *  Determine if address exists
       *  @returns {boolean}
       */
      this.hasAddress = function() {
        return !_.isEmpty(this.address)
      }

      /**
       *  Determine if address country is supported
       *  @returns {boolean}
       */
      this.hasSupportedCountry = function() {
        if (this.hasAddress() && _.has(this.address, 'country')) {
          return iso.isSupportedCountry(this.address.country)
        }

        return false
      }

      /**
       *  Save address container to node object
       *  @param {object} address Address object
       *  @returns {promise} $http
       */
      this.saveAddress = function(address) {
        if (!_.isEmpty(address)) {
          var endpoint = auth.getBaseURL() + 'person/' + id + '/address'
          if (_.has(address, '_id')) {
            return this.save({ address: address._id }).then(function() {
              return $http.put(endpoint, _.pick(address, ['line1', 'line2', 'city', 'region', 'postcode', 'country']), auth.getConfig('flat'))
            })
          }

          return $http.post(endpoint, address, auth.getConfig('flat'))
        }
      }

      /**
       *  Clear address reference in node object
       *  @returns {promise} $http
       */
      this.clearAddress = function() {
        return this.save({ address: null })
      }

      // Occupation

      /**
       *  Determine if occupation exists
       *  @returns {boolean}
       */
      this.hasOccupation = function() {
        return !_.isEmpty(this.occupation)
      }

      // Age

      // age container
      this.birth = obj.birth || {}

      /**
       *  Verify age container is populated
       *  @returns {boolean}
       */
      this.hasAge = function() {
        return !_.isEmpty(this.birth)
      }

      /**
       *  Verify valid date of birth exists
       *  @returns {boolean}
       */
      this.hasDateOfBirth = function() {
        var model = this.birth
        return this.hasAge() && _.every(['day', 'month', 'year'], function(prop) {
          return _.has(model, prop) && !_.isNaN(parseInt(model[prop]))
        })
      }

      /**
       *  Retrieve current years of age
       *  @returns {number}
       */
      this.years = function() {
        var today, dob, diffYears, diffMonths, diffDate
        if (this.hasDateOfBirth()) {

          // has specific date of birth
          today = new Date()

          // date of birth, month is zero-indexed
          dob = new Date(this.birth.year, (this.birth.month - 1), this.birth.day, 0, 0, 0, 0)
          diffYears = today.getFullYear() - dob.getFullYear()
          diffMonths = today.getMonth() - dob.getMonth()
          diffDate = today.getDate() - dob.getDate()

          if ((diffMonths < 0) || (diffMonths === 0 && diffDate < 0)) {
            diffYears--
          }

          return diffYears
        }
      }

      /**
       *  Retrieve current age
       *  @returns {string}
       */
      this.age = function() {

        // determine specific or relative date of birth
        return this.hasDateOfBirth() ? this.years() + ' years' : (this.isMinor() ? 'Under 18 years' : 'Over 18 years')
      }

      /**
       *  Determine if minor
       *  @returns {boolean}
       */
      this.isMinor = function() {
        return this.hasDateOfBirth() ? this.years() < 18 : this.birth.minor
      }

      /**
       *  Determine if deceased
       *  @returns {boolean}
       */
      this.isDeceased = function() {
        return this.birth.deceased
      }

      /**
       *  Save age container to node object
       *  @param {object} birth Age object
       *  @returns {promise} $http
       */
      this.saveAge = function(birth) {
        var endpoint = auth.getBaseURL() + 'person/' + id + '/birth'
        if (_.has(birth, '_id')) {
          return this.save({ birth: birth._id }).then(function() {
            return $http.put(endpoint, _.pick(birth, ['day', 'month', 'year', 'minor', 'deceased']), auth.getConfig('flat'))
          })
        }

        return $http.post(endpoint, birth, auth.getConfig('flat'))
      }

      // Relationships

      /**
       *  Determine if relative
       *  @returns {boolean}
       */
      this.isRelated = function() {
        return !_.isEmpty(this.links())
      }

      /**
       *  Linked parent nodes
       *  @returns {array} Parents
       */
      this.parents = function() {
        return _.where(this.links(), { type: 'child', target: id }) || []
      }

      /**
       *  Checks if parent exists as link
       *  @param {string} gender Limit by gender
       *  @returns {boolean}
       */
      this.hasParent = function(gender) {

        // attributes to be matched
        var match = {
          type: 'child',
          target: id
        }

        // populate gender in match if defined
        if (!_.isUndefined(gender)) match.originModel.gender = gender

        return !!(_.findWhere(this.parents(), match))
      }

      /**
       *  Checks if two parents exist as links
       *  @returns {boolean}
       */
      this.hasParents = function() {

        // can only have two parents
        return (_.where(this.parents(), { type:'child', target:id }) || []).length >= 2
      }

      /**
       *  Retrieve one or both parents
       *  @returns {array} Parent(s)
       */
      this.getParents = function() {
        var parents = (_.where(this.links(), { type:'child', target:id }) || [])
        return _.pluck(parents, 'originModel')
      }

      /**
       *  Checks if female parent exists as link
       *  @returns {boolean}
       */
      this.hasMother = function() {
        return this.hasParent('female')
      }

      /**
       *  Checks if male parent exists as link
       *  @returns {boolean}
       */
      this.hasFather = function() {
        return this.hasParent('male')
      }

      /**
       *  Linked child nodes
       *  @returns {array} Children
       */
      this.children = function() {
        return _.where(this.links(), { type: 'child', origin: id }) || []
      }

      /**
       *  Checks if at least one child exists
       *  @returns {boolean}
       */
      this.hasChildren = function() {
        return !_.isEmpty(this.children())
      }

      /**
       *  Retrieve one or many children
       *  @returns {array} Children
       */
      this.getChildren = function() {
        var children = (_.where(this.children(), { type:'child', origin:id }) || [])
        return _.pluck(children, 'targetModel')
      }

      /**
       *  Check if partner exists as link
       *  @returns {boolean}
       */
      this.hasPartner = function() {

        // can only have one partner
        var partnership = _.findWhere(this.links(), { type:'partner' })
        return !!partnership
      }

      /**
       *  Retrieve partner
       *  @returns {object} Partner
       */
      this.getPartner = function() {
        var partner = _.findWhere(this.links(), { type:'partner' })
        if (!_.isEmpty(partner)) return (id === partner.originModel.id) ? partner.targetModel : partner.originModel
      }

      /**
       * Gets naming convention array
       * @returns {array}
       */
      this.getPartnershipOptions = function() {
        var partnershipOptions = [
            { 'name':'fiance', 'value':'engaged_married' },
            { 'name':'civil partner', 'value':'civil partner' },
            { 'name':'future civil partner', 'value':'engaged_cp' },
            { 'name':'partner', 'value':'partner' },
            { 'name':_.isUndefined(this.gender) ? 'spouse' : (this.gender === 'male' ? 'husband' : 'wife'), 'value':'married' }
        ]

        return partnershipOptions
      }
      this.partnershipOptions = this.getPartnershipOptions()

      /**
       * Gets readable partnership type
       * @returns {string}
       */
      this.getPartnershipType = function() {
        var partner = _.findWhere(this.links(), { type:'partner' }) || {}
        if (!_.isEmpty(partner)) {
          var option = _.findWhere(this.getPartner().getPartnershipOptions(), { value: partner.status }) || 'Partner'
          return option.name
        }
      }

      /**
       * Gets readable partnership value
       * @returns {string}
       */
      this.getPartnershipValue = function() {
        var partner = _.findWhere(this.links(), { type:'partner' }) || {}
        if (!_.isEmpty(partner)) {
          var option = _.findWhere(this.getPartner().getPartnershipOptions(), { value: partner.status }) || 'Partner'
          return option.value
        }
      }

      /**
       *  Update partner relationship with type
       *  @param {string} status Partnership type
       */
      this.setPartnershipType = function(status) {
        var partner = _.findWhere(this.links(), { type:'partner' }) || {}
        if (!_.isEmpty(partner)) {
          partner.status = status
          return partner.save({ status: status })
        }
      }

      /**
       *  Type of relationship to testator
       *  @returns {string} Type
       */
      this.type = function() {
        var type = links.relationshipToTestator(id).type

        if (this.gender) {
          switch (type) {
            case 'parent':
              type = (this.gender === 'male') ? 'father' : 'mother'
              break
            case 'partner':
              type = (this.gender === 'male') ? 'husband' : 'wife'
              break
            case 'child':
              type = (this.gender === 'male') ? 'son' : 'daughter'
              break
          }
        }

        return type
      }

      /**
       *  Set link to other node
       *  @param {object} target Target node
       *  @param {string} type Type of link
       *  @public
       */
      this.link = function(target, type) {
        var defer = $q.defer()

        // avoid setting duplicates
        if (!_.isEqual(this, target)) {
          if (!(type === 'parent' && this.hasParents()) && !(type === 'partner' && this.hasPartner())) {

            // given it does not break relationship rules,
            // add link
            return links.add(type || 'other', this, target)
          }

          alert('Link added creates invalid relationship.')
        } else {
          alert('Cannot link to self.')
        }

        defer.resolve(undefined)
        return defer.promise
      }

      /**
       *  Remove link to other node
       *  @param {object} node Node ref to remove
       *  @public
       */
      this.unlink = function(node) {
        if (_.isEmpty(node) || _.isUndefined(node)) {

          // capture mandatory parameter, node
          throw new Error('No node provided to unlink.')
        }

        // find link reference to relationship
        var link = _.find(this.links(), function(link) {
          return link.origin === node.id || link.target === node.id
        }) || {}

        return _.isEmpty(link) ? alert('No link exists.') : links.remove(link)
      }

      /**
       *  Resolve create wizard steps
       *  @returns {array} steps
       */
      this.steps = function() {

        // exclude relationships step for unrelated node
        return this.isRelated() ? createWizardSteps : _.without(createWizardSteps, 'relationships')
      }

      /**
       *  Save initial details to node object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.create = function(attrs) {

        // subjective mandatory details

        if (!_.isEmpty(attrs.partnerType)) {

          // determine partnership type
          this.setPartnershipType(attrs.partnerType)
        }

        if (!_.isEmpty(attrs.birth)) {

          // determine if minor
          this.saveAge(attrs.birth)
        }

        if (!_.isEmpty(attrs.address)) {

          // persist address
          this.saveAddress(attrs.address)
        }

        // save initial data
        return this.save(_.pick(attrs, ['name', 'surname', 'gender']))
                  .then(() => {

                    // update complete state once persisted
                    this.complete = completed(this)
                  })
      }

      /**
       *  Save attributes to node object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        var endpoint = auth.getBaseURL() + 'person/' + id
        return $http.put(endpoint, attrs, auth.getConfig())
      }

      return _.extend(obj, this)

    }
  }])
