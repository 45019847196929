import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../factories/addresses'

angular.module('app.relationships.services.address.list', ['authentication', 'app.relationships.factories.addresses'])
  .service('relationships.service.address.list', ['$http', 'auth', 'relationships.factory.address',
      function($http, auth, Address) {

        var collection = []

        /**
         *  Fetch address from server side
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function() {
          var endpoint = auth.getBaseURL() + 'address'

          // fetch address
          return $http.get(endpoint, auth.getConfig(['signed', 'flat'], 'GET', endpoint)).then(function(res) {

            // set each address into emptied collection
            collection = _.map(res.data || [], function(address) {
              return new Address(address)
            })
            return res
          })
        }

        /**
         *  Get address or all addresses from collection
         *  @param {string} [id] Address id to get specific address
         *  @returns {object} Address
         *  @public
         */
        function get(id) {
          if (!_.isUndefined(id)) {
            return _.findWhere(collection, { id: id })
          }
          return collection
        }
        this.get = get

        /**
         *  Add an address
         *  @param {object} data
         *  @returns {promise} $http
         *  @public
         */
        this.add = function(data) {
          var endpoint = auth.getBaseURL() + 'address'
          return $http.post(endpoint, data || {}, auth.getConfig('signed', 'POST', endpoint))
                  .then(function(res) {
                    var address = new Address(res.data)
                    collection.unshift(address)
                    return get(address.id)
                  }, function(res) {
                    console.dir(res)
                  })
        }

        /**
         *  Remove an address
         *  @param {object} address Object reference to address
         *  @public
         */
        this.remove = function(address) {
          var endpoint = auth.getBaseURL() + 'address/' + address._id
          collection = _.without(collection, address)
          $http.delete(endpoint, auth.getConfig('signed', 'DELETE', endpoint))
        }


      }

  ])
