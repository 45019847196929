import _ from 'underscore'
import angular from 'angular'

import '../bus'

angular.module('app.directives.dates', ['msgbus'])
  .directive('ngMax', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, elem, attr, ctrl) {
        attr.$observe('ngMax', function(value) {
          if (!ctrl.$pristine) ctrl.$setViewValue(ctrl.$viewValue)
          if (value !== 'null') attr.$set('max', value)
        })

        ctrl.$parsers.push(maxValidator)
        ctrl.$formatters.push(maxValidator)

        function maxValidator(value) {
          var max = scope.$eval(attr.ngMax) || Infinity
          if (!_.isEmpty(value) && value > max) {
            ctrl.$setValidity('ngMax', false)
            return undefined
          }

          ctrl.$setValidity('ngMax', true)
          return value
        }
      }
    }
  })
  .controller('directives.dates.controller', ['$scope', 'bus', function($scope, bus) {

    // optionally defined model
    $scope.model = $scope.model || {}

    /** Utility function for current year */
    $scope.currentYear = function() {
      return parseInt(new Date().getFullYear())
    }

    // /** Utility function for current month */
    $scope.currentMonth = function() {

      // use one-indexed month, not zero-indexed
      return parseInt(new Date().getMonth() + 1)
    }

    // /** Utility function for current day */
    $scope.currentDay = function() {
      return parseInt(new Date().getDate())
    }

    /** Utility function for days in month */
    $scope.daysInMonth = function(month, year) {

      // calculate days difference between current month and next month, also works with leap years
      return ((Date.UTC(parseInt(year), parseInt(month), 1) - Date.UTC(parseInt(year), parseInt(month) - 1, 1)) / (1000 * 60 * 60 * 24)) || 31
    }

    /** Parse specific date */
    $scope.parsed = function() {

      // parse date with populated dates, returning timestamp
      return new Date($scope.model.year + 0, $scope.model.month - 1, $scope.model.day + 0).getTime()
    }

    // define initial state
    if ((!_.isEmpty($scope.model) || $scope.showDisplay) && !$scope.showEdit) {
      $scope.state = validDateOfBirth($scope.model) || $scope.immutable ? 'date' : 'minor'
    } else {
      $scope.state = $scope.immutable ? 'edit' : 'minor'
    }

    /** State change helper */
    $scope.toggle = function(state) {
      $scope.state = state
    }

    /** Save address to parent container */
    $scope.save = function($event) {

      // prevent default
      if ($event) $event.preventDefault()

      // abstract submit method, where provided
      if (_.isFunction($scope.submit)) $scope.submit()
    }

    $scope.saveDate = function($event) {

      // progress state
      $scope.state = 'date'

      // abstract save method
      return $scope.save($event)
    }

    // subscribe as delegate
    if ($scope.delegated) {

      // subscribe edit state
      bus.subscribe($scope, 'action', 'delegates', 'edit', function() {
        $scope.state = (validDateOfBirth($scope.model) || $scope.immutable) ? 'edit' : 'minor'
      })

      // subscribe view state
      bus.subscribe($scope, 'action', 'delegates', 'save', function() {
        $scope.saveDate()
      })
    }

    /** Validate Date of Birth completeness */
    function validDateOfBirth(model) {
      return _.every(['day', 'month', 'year'], function(prop) {
        return _.has(model, prop) && !_.isNaN(parseInt(model[prop]))
      })
    }
  }])
  .directive('appDateOfBirth', () => {
    return {
      restrict: 'E',
      controller: 'directives.dates.controller',
      template: require('../../tpl/directives/dateofbirth.html'),
      scope: {
        model: '=ngModel',
        immutable: '=appImmutable',
        name: '@appPersonName',
        submit: '&?ngSubmit',
        showEdit: '=?appShowEdit',
        showDisplay: '=?appShowDisplay',
        delegated: '=?appDelegate'
      }
    }
  })
