// libraries
import _ from 'underscore'
import angular from 'angular'

// ng extensions
import 'angular-ui-router'
import 'angular-animate'
import 'angular-resource'
import 'angular-touch'

// core modules
import './api'
import './auth'
import './storage'

// all components
import './components/hub/controller'
import './components/account/controllers'
import './components/assets/controllers'
import './components/relationships/controllers'
import './components/payment/controller'
import './components/documents/controller'
import './components/will/controller'
import './components/wishes/controller'
import './components/wishes/cash/controller'
import './components/wishes/chattels/controller'
import './components/wishes/estate/controller'
import './components/wishes/executors/controller'
import './components/wishes/funeral/controller'
import './components/wishes/guardianships/controller'
import './components/wishes/property/controller'
import './components/wishes/substitution/controller'
import './components/wishes/legal/controller'

// core directives
import 'directives/menu'
import 'directives/dates'
import 'directives/address'
import 'directives/image'
import 'directives/collapse'
import 'directives/notifications'
import 'directives/freemium'

// core services
import './components/account/service'
import './components/assets/services/asset.list'
import './components/relationships/factories/nodes'
import './components/relationships/services/node.list'
import './components/relationships/services/address.list'
import './components/payment/service'

const API_BASE_URL = process.env.AFFIO_API_BASE_URL

// register utils
_.mixin({

  // Serialize key-value pairs of an object into urlencoded format
  serialize: function(obj) {
    var pairs = _.pairs(obj)
    return _.reduce(pairs, function(memo, pair) {
      var key = _.first(pair), value = _.last(pair)
      value = _.isFunction(value) ? value() : value
      return memo + '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value)
    }, '').replace('&', '').replace(/%20/g, '+')
  }
})

// instantiate app module
var app = angular.module('app', [
  'ngAnimate',
  'ngResource',
  'ngTouch',
  'ui.router',
  'ui.bootstrap.collapse',
  'authentication',
  'angularLocalStorage',
  'app.directives.menu',
  'app.directives.dates',
  'app.directives.address',
  'app.directives.image',
  'app.directives.notifications',
  'app.directives.freemium',
  'app.account',
  'app.account.service',
  'app.payment.service',
  'app.assets',
  'app.assets.services.asset.list',
  'app.hub',
  'api',
  'app.relationships',
  'app.relationships.factories.nodes',
  'app.relationships.services.node.list',
  'app.relationships.services.address.list',
  'app.payment',
  'app.documents',
  'app.will',
  'app.wishes',
  'app.wishes.cash',
  'app.wishes.chattels',
  'app.wishes.estate',
  'app.wishes.executors',
  'app.wishes.funeral',
  'app.wishes.guardianships',
  'app.wishes.property',
  'app.wishes.substitution',
  'app.wishes.legal'
])

// App config
app.config(function($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider, $locationProvider, $animateProvider) {

  // selective angular animation
  $animateProvider.classNameFilter(/ng-animated/)

  // push-state browsing
  $locationProvider.html5Mode(true)

  // handle non-existent routes
  $urlRouterProvider.otherwise('/')

  // routes set to case-insensitive
  $urlMatcherFactoryProvider.caseInsensitive(true)

  // strict mode disabled on routing,
  // supports trailing slashes on urls
  $urlMatcherFactoryProvider.strictMode(false)

  /**
   *  Routing API
   *  .when({string} State, {
   *      url: {string}
   *      templateProvider: {promise}
   *      controller: {string} // optional
   *      offsetNav: {boolean} offsetNav // optional
   *      expandedNav: {boolean} expandedNav // optional
   *      expandedView: {boolean} expandedView // optional
   *  })
   */
  $stateProvider

    // TODO: all states should be delegated to respective modules

    // unauthenticated routes
    .state('login', {
      url: '/login?error&redirect&fbAuth&googleAuth',
      templateProvider: () => import('../tpl/login.html'),
      controller: 'app.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('logout', {
      url: '/logout',
      templateProvider: () => import('../tpl/login.html'),
      controller: 'account.session.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('register', {
      url: '/register?error&fbAuth&googleAuth',
      templateProvider: () => import('../tpl/register.html'),
      controller: 'app.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('recoverToken', {
      url: '/recover/:token',
      templateProvider: () => import('../tpl/recovery.html'),
      controller: 'app.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('recover', {
      url: '/recover',
      templateProvider: () => import('../tpl/recovery.html'),
      controller: 'app.controller',
      expandedView: true,
      noLayoutScript: true,
    })

    // authenticated routes,
    // prefixed using 'auth.',
    // resolves with user resource
    .state('auth', {
      abstract: true,
      resolve: {
        // core user object
        user: function ($resource) {
          return $resource(API_BASE_URL + '/user', {}).get().$promise
        },

        // related user profile
        profile: [
          '$resource',
          'user',
          'relationships.factory.node',
          ($resource, user, Node) =>
            $resource(API_BASE_URL + '/person/' + user.profile, {}).get(
              (res) => new Node(res),
            ).$promise,
        ],
      },
      views: {
        '@': {
          templateProvider: () => import('../tpl/index.html'),
        },
        'menu@auth': {
          templateProvider: () => import('../tpl/menu.html'),
          controller: 'directives.menu.controller',
        },
        'summary@auth': {
          templateProvider: () => import('../tpl/account/summary.html'),
          controller: function ($scope, user, profile) {
            $scope.user = user
            $scope.profile = profile
          },
        },
        'advice@auth': {
          templateProvider: () => import('../tpl/directives/advice.html'),
          controller: 'directives.menu.controller',
        },
      },
    })
    .state('auth.intro', {
      url: '/',
      templateProvider: () => import('../tpl/hub/lifelocker.html'),
      controller: 'hub.controller',
      adviceMessaging: true,
    })

    // primary menu
    .state('auth.me', {
      url: '/me',
      templateProvider: () => import('../tpl/relationships/editor.html'),
      controller: 'relationships.editor.controller',
      params: { type: 'user', delegated: true },
      resolve: {
        resource: [
          'relationships.service.node.list',
          function (nodes) {
            return nodes.fetch()
          },
        ],
      },
      adviceMessaging: true,
    })

    // assets routes,
    // prefixed using 'auth.assets.',
    // resolves with assets from service
    .state('auth.assets', {
      abstract: true,
      template: '<div ui-view></div>',
    })
    .state('auth.assets.editor', {
      url: '/assets/:id?redirect',
      templateProvider: () => import('../tpl/assets/editor.html'),
      params: { type: 'asset' },
      resolve: {
        resource: [
          'assets.service.asset.list',
          function (assets) {
            return assets.fetch()
          },
        ],
        addressResource: [
          'relationships.service.address.list',
          function (addresses) {
            return addresses.fetch()
          },
        ],
      },
      controller: 'assets.editor.controller',
    })
    .state('auth.assets.list', {
      url: '/assets',
      templateProvider: () => import('../tpl/assets/list.html'),
      controller: 'assets.controller',
      resolve: {
        resource: [
          'assets.service.asset.list',
          function (assets) {
            return assets.fetch()
          },
        ],
        addressResource: [
          'relationships.service.address.list',
          function (addresses) {
            return addresses.fetch()
          },
        ],
      },
      adviceMessaging: true,
    })

    // relationships routes,
    // prefixed using 'auth.relationships.',
    // resolves with nodes from service
    .state('auth.relationships', {
      abstract: true,
      template: '<div ui-view></div>',
    })
    .state('auth.relationships.tree', {
      url: '/relationships/tree',
      templateProvider: () => import('../tpl/relationships/tree.html'),
      controller: 'relationships.controller',
      resolve: {
        resource: [
          'relationships.service.node.list',
          function (nodes) {
            return nodes.fetch()
          },
        ],
      },
      expandedView: true,
    })
    .state('auth.relationships.editor', {
      url: '/relationships/:id?redirect',
      templateProvider: () => import('../tpl/relationships/editor.html'),
      params: { type: 'relationship' },
      resolve: {
        resource: [
          'relationships.service.node.list',
          function (nodes) {
            return nodes.fetch()
          },
        ],
      },
      controller: 'relationships.editor.controller',
    })
    .state('auth.relationships.list', {
      url: '/relationships',
      templateProvider: () => import('../tpl/relationships/list.html'),
      controller: 'relationships.controller',
      resolve: {
        resource: [
          'relationships.service.node.list',
          function (nodes) {
            return nodes.fetch()
          },
        ],
      },
      adviceMessaging: true,
    })
    .state('auth.payment', {
      url: '/checkout',
      templateProvider: () => import('../tpl/checkout.html'),
      controller: 'payment.controller',
      resolve: {
        // payment status
        paid: [
          'payment.service',
          function (payments) {
            // request payment status
            return payments.getStatus()
          },
        ],
      },
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.documents', {
      url: '/documents',
      templateProvider: () => import('../tpl/documents/download.html'),
      controller: 'documents.controller',
      resolve: {
        // payment status
        paid: [
          'payment.service',
          function (payments) {
            // request payment status
            return payments.getStatus()
          },
        ],
      },
      adviceMessaging: true,
    })
    .state('auth.settingsSection', {
      url: '/account/:section',
      templateProvider: () => import('../tpl/account/settings.html'),
      controller: 'account.settings.controller',
    })
    .state('auth.settings', {
      url: '/account',
      templateProvider: () => import('../tpl/account/settings.html'),
      controller: 'account.settings.controller',
    })

    // wishes areas
    .state('auth.wishes', {
      url: '/wishes',
      templateProvider: () => import('../tpl/wishes/hub.html'),
      controller: 'wishes.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssential', {
      url: '/wishes/essential',
      templateProvider: () => import('../tpl/wishes/essential.html'),
      controller: 'wishes.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesOptional', {
      url: '/wishes/optional',
      templateProvider: () => import('../tpl/wishes/optional.html'),
      controller: 'wishes.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesEssentialLegalScope', {
      url: '/wishes/legal/scope',
      templateProvider: () => import('../tpl/wishes/legal/scope.html'),
      controller: 'wishes.legal.controller',
      resolve: {
        // user will scope
        willScope: [
          '$http',
          'auth',
          function ($http, auth) {
            return $http
              .get(API_BASE_URL + '/willScope', auth.getConfig('flat'))
              .then(function (res) {
                return _.first(res.data)
              })
          },
        ],
      },
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialLegalRevocation', {
      url: '/wishes/legal/revocation',
      templateProvider: () => import('../tpl/wishes/legal/revocation.html'),
      controller: 'wishes.legal.controller',
      resolve: {
        // user will scope
        willScope: [
          '$http',
          'auth',
          function ($http, auth) {
            return $http
              .get(API_BASE_URL + '/willScope', auth.getConfig('flat'))
              .then(function (res) {
                return _.first(res.data)
              })
          },
        ],
      },
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialLegalSummary', {
      url: '/wishes/legal/summary',
      templateProvider: () => import('../tpl/wishes/legal/summary.html'),
      controller: 'wishes.legal.controller',
      resolve: {
        // user will scope
        willScope: [
          '$http',
          'auth',
          function ($http, auth) {
            return $http
              .get(API_BASE_URL + '/willScope', auth.getConfig('flat'))
              .then(function (res) {
                return _.first(res.data)
              })
          },
        ],
      },
      adviceMessaging: true,
    })

    .state('auth.wishesOptionalFuneralInform', {
      url: '/wishes/funeral/inform',
      templateProvider: () => import('../tpl/wishes/funeral/inform.html'),
      controller: 'wishes.funeral.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesOptionalFuneralDetails', {
      url: '/wishes/funeral/details',
      templateProvider: () => import('../tpl/wishes/funeral/details.html'),
      controller: 'wishes.funeral.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesSubstitutionAppointment', {
      url: '/wishes/:area/plan/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/substitution.html'),
      controller: 'wishes.substitution.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesSubstitution', {
      url: '/wishes/:area/:ref/plan',
      templateProvider: () => import('../tpl/wishes/substitution.html'),
      controller: 'wishes.substitution.controller',
      expandedView: true,
      noLayoutScript: true,
    })

    .state('auth.wishesEssentialExecutorsAppointment', {
      url: '/wishes/executors/appoint',
      templateProvider: () => import('../tpl/wishes/executors/creator.html'),
      controller: 'wishes.executors.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesEssentialExecutors', {
      url: '/wishes/executors',
      templateProvider: () => import('../tpl/wishes/executors/summary.html'),
      controller: 'wishes.executors.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialExecutorsInform', {
      url: '/wishes/executors/inform',
      templateProvider: () => import('../tpl/wishes/executors/inform.html'),
      controller: 'wishes.executors.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialExecutorsConfirm', {
      url: '/wishes/executors/confirm',
      templateProvider: () => import('../tpl/wishes/executors/summary.html'),
      controller: 'wishes.executors.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesEssentialGuardiansAppointment', {
      url: '/wishes/guardians/appoint',
      templateProvider: () => import('../tpl/wishes/guardians/creator.html'),
      controller: 'wishes.guardianships.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesEssentialGuardians', {
      url: '/wishes/guardians',
      templateProvider: () => import('../tpl/wishes/guardians/summary.html'),
      controller: 'wishes.guardianships.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialGuardiansInform', {
      url: '/wishes/guardians/inform',
      templateProvider: () => import('../tpl/wishes/guardians/inform.html'),
      controller: 'wishes.guardianships.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialGuardiansConfirm', {
      url: '/wishes/guardians/confirm',
      templateProvider: () => import('../tpl/wishes/guardians/summary.html'),
      controller: 'wishes.guardianships.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesOptionalCashInform', {
      url: '/wishes/cash/inform/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/cash/inform.html'),
      controller: 'wishes.cash.controller',
    })
    .state('auth.wishesOptionalCashGift', {
      url: '/wishes/cash/gift/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/cash/creator.html'),
      controller: 'wishes.cash.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesOptionalCashConfirm', {
      url: '/wishes/cash/confirm',
      templateProvider: () => import('../tpl/wishes/cash/confirm.html'),
      controller: 'wishes.cash.controller',
    })
    .state('auth.wishesOptionalCashConfirmation', {
      url: '/wishes/cash/confirm/:id',
      templateProvider: () => import('../tpl/wishes/cash/confirm.html'),
      controller: 'wishes.cash.controller',
    })
    .state('auth.wishesOptionalCash', {
      url: '/wishes/cash',
      templateProvider: () => import('../tpl/wishes/cash/summary.html'),
      controller: 'wishes.cash.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesOptionalChattelsInform', {
      url: '/wishes/chattels/inform/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/chattels/inform.html'),
      controller: 'wishes.chattels.controller',
    })
    .state('auth.wishesOptionalChattelsGift', {
      url: '/wishes/chattels/gift/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/chattels/creator.html'),
      controller: 'wishes.chattels.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesOptionalChattelsConfirm', {
      url: '/wishes/chattels/confirm',
      templateProvider: () => import('../tpl/wishes/chattels/confirm.html'),
      controller: 'wishes.chattels.controller',
    })
    .state('auth.wishesOptionalChattelsConfirmation', {
      url: '/wishes/chattels/confirm/:id',
      templateProvider: () => import('../tpl/wishes/chattels/confirm.html'),
      controller: 'wishes.chattels.controller',
    })
    .state('auth.wishesOptionalChattels', {
      url: '/wishes/chattels',
      templateProvider: () => import('../tpl/wishes/chattels/summary.html'),
      controller: 'wishes.chattels.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesOptionalPropertyInform', {
      url: '/wishes/property/inform/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/property/inform.html'),
      controller: 'wishes.property.controller',
    })
    .state('auth.wishesOptionalPropertyGift', {
      url: '/wishes/property/gift/:id',
      params: { id: { value: null, squash: true } },
      templateProvider: () => import('../tpl/wishes/property/creator.html'),
      controller: 'wishes.property.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesOptionalPropertyConfirm', {
      url: '/wishes/property/confirm',
      templateProvider: () => import('../tpl/wishes/property/confirm.html'),
      controller: 'wishes.property.controller',
    })
    .state('auth.wishesOptionalPropertyConfirmation', {
      url: '/wishes/property/confirm/:id',
      templateProvider: () => import('../tpl/wishes/property/confirm.html'),
      controller: 'wishes.property.controller',
    })
    .state('auth.wishesOptionalProperty', {
      url: '/wishes/property',
      templateProvider: () => import('../tpl/wishes/property/summary.html'),
      controller: 'wishes.property.controller',
      adviceMessaging: true,
    })

    .state('auth.wishesEssentialEstateAppointment', {
      url: '/wishes/estate/appoint',
      templateProvider: () => import('../tpl/wishes/estate/creator.html'),
      controller: 'wishes.estate.controller',
      expandedView: true,
      noLayoutScript: true,
    })
    .state('auth.wishesEssentialEstate', {
      url: '/wishes/estate',
      templateProvider: () => import('../tpl/wishes/estate/summary.html'),
      controller: 'wishes.estate.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialEstateInform', {
      url: '/wishes/estate/inform',
      templateProvider: () => import('../tpl/wishes/estate/inform.html'),
      controller: 'wishes.estate.controller',
      adviceMessaging: true,
    })
    .state('auth.wishesEssentialEstateConfirm', {
      url: '/wishes/estate/confirm',
      templateProvider: () => import('../tpl/wishes/estate/summary.html'),
      controller: 'wishes.estate.controller',
      adviceMessaging: true,
    })

    // will clauses
    .state('auth.will', {
      url: '/will',
      templateProvider: () => import('../tpl/hub/lifelocker.html'),
      controller: 'will.controller',
    })
    .state('auth.willClauses', {
      url: '/will/:clause',
      templateProvider: () => import('../tpl/will/review.html'),
      controller: 'will.controller',
    })
})

app.run(['$rootScope', '$state', 'bus', function($rootScope, $state, bus) {

  // view is rendered
  $rootScope.$on('$viewContentLoaded', () => {
    $rootScope.ready = true
  })

  // apply listener to route updates
  bus.subscribe($rootScope, 'action', '$state', 'change:success', ($event, target) => {
    _.each([
      'offsetNav',
      'expandedNav',
      'expandedView',
      'noLayoutScript',
      'adviceMessaging'
    ], prop => $rootScope[prop] = !!target[prop])
  })

  // apply listener to route errors
  bus.subscribe($rootScope, 'action', '$state', 'change:error', handleSessionTermination)

  // apply listener to session termination
  bus.subscribe($rootScope, 'action', 'session', 'terminated', handleSessionTermination)

  /**
   *  Handler for session termination
   *  @param {angular.$event} $event Angular Event
   *  @param {object} [toState]
   *  @param {object} [toParams]
   *  @private
   */
  function handleSessionTermination($event, toState, toParams) {
    var options

    // handle optional parameters
    toState = toState || $state.current
    toParams = toParams || $state.params

    // ignore non-authenticated routes
    if (!_.has(toState, 'name') || toState.name.split('.')[0] !== 'auth') {
      return
    }

    try {

      // set serialized form of state,
      // redirect parameter will return
      // terminated session to previous state
      // upon successful authentication
      options = {
        redirect: JSON.stringify({
          to: toState.name,
          params: toParams
        })
      }
    } catch (e) { console.error(e) }

    // redirect to login with options
    $state.go('login', options)
  }
}])

// Capitalisation helper in templates
// e.g. {{pseudo | title}}
app.filter('title', () => {
  return function(input) {
    if (_.isString(input)) {
      return input.substring(0, 1).toUpperCase() + input.substring(1)
    }
  }
})

// Pagination helper in templates
// e.g. {{array | startFrom:pageNumber*pageSize | limitTo:pageSize}}
app.filter('startFrom', function() {
  return function(input, start) {
    return input.slice(parseInt(start))
  }
})

// Input type helper attribute directive
app.directive('ngType', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attr) {
      attr.$observe('ngType', value => {
        if (value !== 'null') attr.$set('type', value)
      })
    }
  }
})

// App Controller
app.controller('app.controller', ['$scope', '$http', '$stateParams', '$state', '$location', 'auth', 'account.service', 'relationships.factory.node', 'api',
  function($scope, $http, $stateParams, $state, $location, auth, account, Node, api) {
    $scope.ready = false

    if ($stateParams.fbAuth || $stateParams.googleAuth) {
      var promise

      // proceed to authenticate
      $scope.loading = true

      if ($stateParams.fbAuth) {
        promise = auth.authenticateViaFacebook($state.current.name, $stateParams.fbAuth)
      } else if ($stateParams.googleAuth) {
        promise = auth.authenticateViaGoogle($state.current.name, $stateParams.googleAuth)
      }

      // process via appropriate provider
      promise.then(function() {
        if ($state.current.name === 'login') {
          try {

            // attempt to restore to redirect path
            let redirect = JSON.parse($stateParams.redirect)
            $state.go(redirect.to, redirect.params)
          } catch (e) { $state.go('auth.intro') }
        } else {

          // handle new user with legacy format
          return legacyAuthenticatedUserHandler()
            .then(function() {
              $scope.loading = false
            })
        }
      }, function() {

        // error occurred
        $scope.loading = false
      })
    }

    // route parameters in scope
    $scope.params = angular.copy($stateParams)

    // preserve input
    $scope.input = {
      username: auth.getUsername(),
      password: '',
      pattern: /^(?=.*\d+)(?=.*[a-zA-Z])[0-9a-zA-Z!#$%&*@^\ "'()+,\-.\/:;<=>?[\\\]_`{|}~]{6,128}$/
    }
    Object.defineProperty($scope, 'logged', {
      get: function() { return account.isLoggedIn()}
    })

    $scope.providerLoginURL = function(provider) {
      return API_BASE_URL + '/auth/' + provider + '?action=login' +
        ($stateParams.redirect ? '&redirect=' + encodeURIComponent($stateParams.redirect) : '')
    }

    $scope.complete = function($event) {
      if ($event) $event.preventDefault()
      $scope.loading = true
      return account.setProgress('register', 'confirmed').then(
        () => $state.go('auth.intro'),
        () => $scope.loading = false
      )
    }

    if (account.isLoggedIn()) {

      // handle authenticated user
      legacyAuthenticatedUserHandler()
    } else {
      $scope.ready = true
      $scope.step = 0
    }

    /**
     *  Authenticates user
     *  @param {angular.$event} $event Angular Event
     *  @param form Form wrapper
     *  @param {string} username User input - username
     *  @param {string} password User input - password
     *  @public
     */
    $scope.login = function($event, form, username, password) {

      // prevent fallback form submission
      $event.preventDefault()

      $scope.loading = true

      // checking for missing credentials
      if ((username === '') || (username === undefined)) {
        form.$error.noname = true
      }else {
        form.$error.noname = false
      }
      if ((password === '') || (password === undefined)) {
        form.$error.nopass = true
      }else {
        form.$error.nopass = false
      }

      if (form.$valid) {

        // authenticate over service layer
        auth.authenticate(username, password).then(() => {
          try {

            // attempt to restore to redirect path
            var redirect = JSON.parse($stateParams.redirect)
            $state.go(redirect.to, redirect.toParams)
          } catch (e) { $state.go('auth.intro') }
        }, res => {
          $scope.loading = false
          switch (res.status) {
            case 401:
              form.$error.unmatched = true
              break
            default:
              form.$error.server = true
          }
        })
      } else {
        $scope.loading = false
      }
    }

    /**
     *  Registers user
     */
    $scope.register = function($event, form, username, password) {

      // prevent fallback form submission
      $event.preventDefault()

      $scope.loading = true

      if (form.$valid) {

        // register user over service layer
        auth.register(username, password)
          .then(() => {

            // handle new user with legacy format
            return legacyAuthenticatedUserHandler()
              .then(function() {
                $scope.loading = false
              })
          }, res => {
            $scope.loading = false
            switch (res.status) {
              case 404:
                form.$error.missing = true
                break
              case 409:
                form.$error.exists = true
                break
              case 422:
                form.$error.unprocessable = true
                break
              default:
                form.$error.server = true
            }
          })
      } else {
        $scope.loading = false
      }
    }

    /**
     *  Logs out user
     *  @param {angular.$event} $event Angular Event
     *  @public
     */
    $scope.logout = function() {
      account.logout().then(() => $location.path('/login'))
    }

    /**
     *  Password recovery
     *  @param {angular.$event} $event Angular Event
     *  @param form Form wrapper
     *  @param {string} username User input - username
     *  @public
     */
    $scope.recoverPassword = function($event, form, username) {

      // prevent fallback form submission
      $event.preventDefault()

      $scope.loading = true

      if (form.$valid) {
        account.recover(username).then(() => {
          $scope.loading = false
          form.submitted = true

          // set username in shared scope
          auth.setUsername(username)
        }, res => {
          $scope.loading = false

          // flag error
          switch (res.status) {
            case 401:
              form.$error.unmatched = true
              break
            default:
              form.$error.server = true
          }
        })
      }
    }

    /**
     *  Password reset
     *  @param {angular.$event} $event Angular Event
     *  @param form Form wrapper
     *  @param {string} password User input - password
     *  @public
     */
    $scope.resetPassword = function($event, form, password) {

      // prevent fallback form submission
      $event.preventDefault()

      $scope.loading = true

      if (form.$valid) {
        account.reset($stateParams.token, password).then(() => {

          // route to hub page
          $state.go('auth.intro')
        }, res => {
          $scope.loading = false

          // flag error
          switch (res.status) {
            case 401:
              form.$error.invalid = true
              break
            default:
              form.$error.server = true
          }
        })
      }
    }

    /**
     *  Handles authenticated user in root app controller
     *  @returns {Promise} account.getUserDetails
     *  @private
     */
    function legacyAuthenticatedUserHandler() {
      account.setProgress('register')
      return account.getUserDetails().then(function(res) {
        var user = res.data
        $scope.model = new Node(user)
        $scope.input = _.extend($scope.model, $scope.input)
        $scope.resource = api.$res('person', { id: $scope.model.id, subres: 'images' }, {
          save: {
            method: 'POST',
            headers: { 'X-Data-Expand': undefined }
          }
        })

        $scope.stepProgress = function() {
          return $scope.step <= 6 ? $scope.step - 1 : 1
        }

        $scope.nextStep = function($event, form, obj, step) {

          // prevent fallback form submission
          $event.preventDefault()

          if (form.$valid && !_.isEmpty(obj)) {
            account.setUserDetails(obj).then(function() {

              // progress step
              $scope.step = step + 1
            })
          } else {

            // progress step
            $scope.step = step + 1
          }
        }

        $scope.prevStep = function($event, step) {

          // prevent fallback form submission
          $event.preventDefault()

          // reverse step
          $scope.step = step - 1
        }

        /** Save Date of Birth */
        $scope.saveAge = function($event, birth, step) {

          // prevent fallback form submission
          if ($event) $event.preventDefault()
          $scope.model.saveAge(birth).then(function() {

            // progress step
            $scope.step = step + 1
          })
        }

        /** Save Address */
        $scope.saveAddress = function($event, address, step) {

          // prevent fallback form submission
          if ($event) $event.preventDefault()
          $scope.model.saveAddress(address).then(function() {

            // progress step
            $scope.step = step + 1
          })
        }

        /** Clear address */
        $scope.clearAddress = function() {
          return account.setUserDetails({ address: null })
        }

        $scope.step = resolveStep()
        $scope.ready = true
        return res

        /** Loop through values to determine progress in profile */
        function resolveStep() {
          var req = [

            // check name, surname twice to
            // skip introduction
            // ['name', 'surname'],
            ['name', 'surname'],

            // gender
            ['gender'],

            // date of birth
            ['birth.day', 'birth.month', 'birth.year'],

            // address
            ['address.country'],

            // image
            ['image']
          ]

          var prereq = true

          // loop through requirements to resolve step
          return _.reduce(req, function(step, arr) {

            // prerequirement resolves,
            // resolve this requirement
            if (prereq && _.every(arr, function(value) {
              var ref = user
              value = value.split('.')
              if (value.length === 2) {
                ref = user[value[0]]
                value = value[1]
              } else { value = value[0] }

              // reference and contained values resolve
              return !_.isEmpty(ref) && _.has(ref, value)
            })) {

              // requirement resolves
              return step += 1
            }

            // requirement missing,
            // break future loops
            prereq = false
            return step
          }, 1)
        }
      })
    }
  }
])

export default app
