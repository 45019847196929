import angular from 'angular'
import './transition'

// forked from angular-ui-bootstrap 0.12.0
angular.module('ui.bootstrap.collapse', ['ui.bootstrap.transition'])

  // .directive('collapse', ['$transition', function ($transition) {
  .directive('ngCollapse', ['$transition', function($transition) {

    return {
      link: function(scope, element, attrs) {

        var initialAnimSkip = true
        var currentTransition

        function doTransition(change) {
          var newTransition = $transition(element, change)
          if (currentTransition) {
            currentTransition.cancel()
          }
          currentTransition = newTransition
          newTransition.then(newTransitionDone, newTransitionDone)
          return newTransition

          function newTransitionDone() {

            // Make sure it's this transition, otherwise, leave it alone.
            if (currentTransition === newTransition) {
              currentTransition = undefined
            }
          }
        }

        function expand() {
          if (initialAnimSkip) {
            initialAnimSkip = false
            expandDone()
          } else {
            element.removeClass('collapse').addClass('collapsing')
            doTransition({ height: element[0].scrollHeight + 'px' }).then(expandDone)
          }
        }

        function expandDone() {
          element.removeClass('collapsing')
          element.addClass('collapse in')
          element.css({ height: 'auto' })
        }

        function collapse() {
          if (initialAnimSkip) {
            initialAnimSkip = false
            collapseDone()
            element.css({ height: 0 })
          } else {

            // CSS transitions don't work with height: auto, so we have to manually change the height to a specific value
            element.css({ height: element[0].scrollHeight + 'px' })
            element.removeClass('collapse in').addClass('collapsing')
            doTransition({ height: 0 }).then(collapseDone)
          }
        }

        function collapseDone() {
          element.removeClass('collapsing')
          element.addClass('collapse')
        }

        // scope.$watch(attrs.collapse, function (shouldCollapse) {
        scope.$watch(attrs.ngCollapse, function(shouldCollapse) {
          if (shouldCollapse) {
            collapse()
          } else {
            expand()
          }
        })
      }
    }
  }])
