import _ from 'underscore'
import angular from 'angular'

import '../../auth'
import '../../bus'
import '../../storage'

angular.module('app.account.service', ['authentication', 'msgbus', 'angularLocalStorage']).service('account.service', ['$http', '$q', 'auth', 'bus', function($http, $q, auth, bus) {
  /**
   *  Checks if user is recognized from previous login
   *  @returns {boolean} User recognized?
   */
  function isKnown() {
    return !_.isEmpty(auth.getUser()) && !_.isEmpty(auth.getUserIdentity())
  }
  this.isKnown = isKnown

  /**
   *  Checks if user is logged in
   *  @returns {boolean} User active?
   */
  function isLoggedIn() {
    return isKnown() && auth.sessionIsValid()
  }
  this.isLoggedIn = isLoggedIn

  /**
   *  Retracts server-side authentication and logs user out
   *  @returns {promise} $http
   */
  this.logout = function() {
    return auth.end()
  }

  /**
   *  Handles password recovery over server layer
   *  @returns {promise} $http
   */
  this.recover = function() {
    return auth.recover.apply(null, arguments)
  }

  /**
   *  Password reset
   *  @returns {promise} $http
   */
  this.reset = function() {
    return auth.reset.apply(null, arguments)
  }

  /**
   *  Manual password changes
   *  @returns {promise} $http
   */
  this.change = function() {
    return auth.change.apply(null, arguments)
  }

  /**
   *  Get tracked progress state
   *  @param {string} tracked State of interest
   *  @param {string} [progress] Progress indicator
   *  @returns {promise}
   */
  function getProgress(tracked, progress) {

    // create promise
    var deferred = $q.defer()

    // retrieve state from server layer
    getUserRecord().then(function(res) {

      // resolve with retrieved state
      deferred.resolve(resolved(res.data.state))
    }, deferred.reject)

    // return promise
    return deferred.promise

    /**
     *  Resolve progress by tracked interest or all
     *  @param {array} arr Progress array
     *  @returns {boolean|array} Tracked interest progress or all progress
     */
    function resolved(arr) {
      var state = _.isObject(tracked) ? tracked : { section: tracked }
      if (!_.isUndefined(progress)) state.progress = progress
      return (!_.isEmpty(tracked)) ? !_.isEmpty(_.findWhere(arr, state)) : arr
    }
  }
  this.getProgress = getProgress

  /**
   *  Extends tracked progress state
   *  @param {string} tracked State of interest
   *  @param {string} [progress] Progress indicator
   *  @returns {promise} $http
   */
  function setProgress(tracked, progress) {

    // create promise
    var deferred = $q.defer()
    var endpoint = auth.getBaseURL() + 'user/' + auth.getUserID() + '/state'

    // read existing progress to extend
    getProgress().then(function(arr) {
      // handle missing array
      arr = arr || []

      // extract specific state
      var state = _.findWhere(arr, _.isObject(tracked) ? tracked : { section: tracked })

      // extend progress, avoid duplicates
      if (_.isEmpty(state)) {

        // set state
        state = _.isObject(tracked) ? tracked : { section: tracked }
        if (!_.isUndefined(progress)) state.progress = progress

        // push to server layer
        $http.post(endpoint, state, auth.getConfig('flat'))
          .then(function(res) {
            // broadcast locally
            arr.push(res.data)
            bus.publish('data', 'state', 'propagated', arr)
            deferred.resolve(res)
          }, deferred.reject)
      } else {

        // update existing state object
        endpoint = auth.getBaseURL() + 'state/' + state._id
        if (!_.isUndefined(progress)) state.progress = progress
        $http.put(endpoint, state, auth.getConfig('flat'))
          .then(function(res) {
            // broadcast locally
            _.extend(_.findWhere(arr, { _id: res.data._id }), res.data)
            bus.publish('data', 'state', 'propagated', arr)
            deferred.resolve(res)
          }, deferred.reject)
      }
    }, deferred.reject)

    return deferred.promise
  }
  this.setProgress = setProgress

  /**
   *  Removes tracked progress state or resets all states
   *  @param {string|array} [tracked] State of interest
   *  @returns {promise} $http
   */
  this.resetProgress = function(tracked) {

    // create promise
    var deferred = $q.defer()
    var endpoint = auth.getBaseURL() + 'user/' + auth.getUserID() + '/state'

    // read existing progress to extend
    getProgress().then(function(arr) {
      if (_.isUndefined(tracked)) {

        // reset all progress
        // broadcast locally
        bus.publish('data', 'state', 'propagated', [])

        // push to server layer
        return $q.all(_.map(arr, function(state) {
          if (_.has(state, '_id')) return $http.delete(endpoint + '/' + state._id, auth.getConfig('flat'))
        })).then(deferred.resolve, deferred.reject)
      }

      // extract specific state
      let state = _.findWhere(arr || [], { section: tracked })

      // extend progress, avoid duplicates
      if (!_.isEmpty(state) && _.has(state, '_id')) {

        // broadcast locally
        bus.publish('data', 'state', 'propagated', _.reject(arr, obj => obj._id === state._id))

        // push to server layer
        return $http.delete(endpoint + '/' + state._id, auth.getConfig('flat'))
          .then(deferred.resolve, deferred.reject)
      }
    }, deferred.reject)

    return deferred.promise
  }

  /**
   *  Sets user's personal details
   *  @param {object} params
   *  @returns {promise} $http
   */
  function setUserDetails(params) {
    var endpoint = auth.getBaseURL() + 'person/' + auth.getUserIdentity(), data

    // map user data for request
    data = _.pick(params, [
      'title', 'name', 'surname', 'gender',
      'emails', 'phones', 'occupation',
      'birth', 'address'
    ])

    return $http.put(endpoint, data)
  }
  this.setUserDetails = setUserDetails

  /**
   *  Gets user's personal details
   *  @returns {promise} $http
   */
  function getUserDetails() {
    var deferred = $q.defer(), endpoint
    if (!isLoggedIn()) {

      // break promise if,
      // user is not yet logged in
      deferred.reject({})
    } else {

      // if user is logged in, abstract request against user profile
      endpoint = auth.getBaseURL() + 'person/' + auth.getUserIdentity()
      $http.get(endpoint).then(deferred.resolve, deferred.reject)
    }
    return deferred.promise
  }
  this.getUserDetails = getUserDetails

  /**
   *  Gets user's record from service layer
   *  @returns {promise} $http
   */
  function getUserRecord() {
    var deferred = $q.defer(), endpoint
    if (!isLoggedIn()) {

      // break promise if,
      // user is not yet logged in
      deferred.reject({})
    } else {

      // if user is logged in, abstract request against user record
      endpoint = auth.getBaseURL() + 'user/' + auth.getUserID()
      $http.get(endpoint).then(deferred.resolve, deferred.reject)
    }
    return deferred.promise
  }  
  this.getUserRecord = getUserRecord

}])
