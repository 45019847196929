import _ from 'underscore'
import angular from 'angular'

import '../../../../auth'
import '../../substitution/factory'
import './executor'

angular.module('app.wishes.executors.factories.executorship', ['authentication', 'app.wishes.executors.factories.executor', 'app.wishes.substitution.factory'])
  .factory('wishes.executors.factories.executorship', ['$http', '$q', 'auth', 'wishes.executors.factories.executor', 'wishes.substitution.factory', function($http, $q, auth, Executor, Substitution) {
    return constructor

    /**
     *  Return executorship
     *  @param {object} obj Existing data
     *  @returns {object} Executorship
     *  @constructor
     */
    function constructor(obj) {
      var id = this.id = obj._id

      // map executors to factory instances
      obj.executors = _.map(obj.executors, function(executor) {
        return new Executor(executor)
      })

      // map substitutions to factory instances
      obj.substitutions = _.map(obj.substitutions, function(substitution) {
        return new Substitution(substitution)
      })

      /**
       *  Check whether executor is already represented in the executorship
       *  @param {object} person Person object
       *  @returns {boolean} exists
       *  @public
       */
      this.existsExecutor = function(person) {
        return _.some(this.executors, function(executor) {
          return ((_.isObject(executor.person)) ? executor.person._id : executor.person) === person._id
        })
      }
      this.addExecutor = function(person) {
        var endpoint = auth.getBaseURL() + 'executorship/' + id + '/executors'
        var deferred = $q.defer()

        if ((this.executors || []).length === 4) {
          deferred.reject('Maximum number of 4 executors exceeded.')
        } else {
          if (!this.existsExecutor(person)) {
            $http.post(endpoint, { person: person._id }, auth.getConfig(['signed', 'extended'], 'POST', endpoint))
                          .then(res => {
                            this.executors.push(res.data)
                            deferred.resolve(res)
                          }, deferred.reject)
          } else {
            deferred.reject('Person is already added as an executor.')
          }
        }

        return deferred.promise
      }
      this.removeExecutor = function(executor) {
        var endpoint = auth.getBaseURL() + 'executorship/' + id + '/executors/' + executor._id
        return $http.delete(endpoint, auth.getConfig(['signed'], 'DELETE', endpoint))
                .then(() => {
                  this.executors = _.without(this.executors, executor)
                })
      }

      /** Mock a substitution with id of 1 */
      this.mockSubstitution = function(data) {
        var substitution = new Substitution(_.extend(data, { id: 1 }))
        this.substitutions.push(substitution)
        return substitution
      }

      /** Remove mock substitution */
      this.unmockSubstitution = function() {
        this.substitutions = _.reject(this.substitutions, function(substitution) {
          return substitution.id === 1
        })
      }

      this.addSubstitution = function(data) {
        var endpoint = auth.getBaseURL() + 'executorship/' + id + '/substitutions'
        return $http.post(endpoint, data, auth.getConfig(['signed', 'extended'], 'POST', endpoint))
                  .then(res => {
                    var substitution = new Substitution(res.data)
                    this.substitutions.push(substitution)
                    this.unmockSubstitution()
                    return substitution
                  })
      }

      this.removeSubstitution = function(substitution) {
        var endpoint = auth.getBaseURL() + 'executorship/' + id + '/substitutions/' + substitution._id
        return $http.delete(endpoint, auth.getConfig('signed', 'DELETE', endpoint))
                .then(() => {
                  this.substitutions = _.without(this.substitutions, substitution)
                })
      }

      /**
       *  Save attributes to executorship object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        if (!_.isUndefined(id)) {
          var endpoint = auth.getBaseURL() + 'executorship/' + id
          return $http.put(endpoint, attrs, auth.getConfig('signed', 'PUT', endpoint)).then(function(res) {
            return res.data
          })
        }
      }

      return _.extend(this, obj)
    }
  }])
