import _ from 'underscore'
import angular from 'angular'
import 'typeahead'

angular.module('app.directives.typeahead', [])
  .directive('appTypeahead', ['$timeout', function($timeout) {
    return {
      restrict: 'A',
      scope: {
        value: '=ngModel',
        options: '&?ngOptions',
        datasets: '=ngDatasets'
      },
      link: function(scope, element) {

        // extend options with defaults
        scope.options = _.extend({
          highlight: true
        }, scope.options && scope.options())

        $timeout(function() {

          // create the typeahead on the element
          element.typeahead(scope.options, scope.datasets)
        })

        function updateScope(object, suggestion) {

          // for some reason $apply will place [Object] into element, this hacks around it
          var preserveVal = element.val()
          scope.$apply(function() {
            scope.value = suggestion
          })
          element.val(preserveVal)
        }

        // Update the value binding when a value is manually selected from the dropdown.
        element.bind('typeahead:selected', function(object, suggestion, dataset) {
          updateScope(object, suggestion, dataset)
          return scope.$emit('typeahead:selected')
        })

        // Update the value binding when a query is autocompleted.
        element.bind('typeahead:autocompleted', function(object, suggestion, dataset) {
          updateScope(object, suggestion, dataset)
          return scope.$emit('typeahead:autocompleted')
        })

        // Update the value binding when the user manually enters some text
        element.bind('input', function() {
          scope.$apply(function() {
            var value = element.val()
            scope.value = value
          })
        })
      }
    }
  }])
