import _ from 'underscore'
import angular from 'angular'

import '../../api'

angular.module('app.payment', ['api'])
  .controller('payment.controller', ['$scope', '$state', 'account.service', 'payment.service', 'paid', function ($scope, $state, account, payments, paid) {
    let cardElement

    // refer paid user directly to documents
    if (paid) return $state.go('auth.documents')

    $scope.model = {
      price: 5000,
      total: 5000,

      // voucher data
      voucherCode: '',
      voucherApplied: null,
      voucherPercentage: false,
      voucherDiscount: 0
    }

    $scope.input = {}

    /** Apply voucher from isolated form */
    $scope.applyVoucher = function ($event, voucher) {
      if ($event) $event.preventDefault()

      $scope.applyingVoucher = true

      // retrieve details and apply to checkout
      payments.retrieveVoucherDetails(voucher.$modelValue)
        .then(res => {
          var data = res.data

          // apply discount in checkout
          $scope.model.voucherCode = data.id
          $scope.model.voucherApplied = !!data.valid
          $scope.model.voucherPercentage = _.isNumber(data.percent_off)
          $scope.model.voucherDiscount = (
            $scope.model.voucherPercentage ?
              data.percent_off :
              data.amount_off
          )

          // successfully retrieved
          $scope.voucherExpiredError = false
          $scope.voucherUnknownError = false
          $scope.applyingVoucher = false
        }, err => {

          // error in retrieval
          $scope.voucherExpiredError = err === 'expired'
          $scope.voucherUnknownError = err === 'unknown'
          $scope.applyingVoucher = false
        })
    }

    /** Apply voucher to mirror form submit when 'enter' key is pressed */
    $scope.applyVoucherOnEnter = function ($event, voucher) {
      if ($event.which === 13) {

        // 'enter' key is pressed
        $event.preventDefault()

        // apply voucher directly through method in scope
        $scope.applyVoucher($event, voucher)
      }
    }

    /** Handle purchase */
    $scope.purchase = function ($event) {
      if ($event) $event.preventDefault()

      $scope.processing = true

      return payments.send(cardElement, $scope.model.voucherCode).then(() => {
        $scope.paid = true
        account.setProgress('will.payment').then(() => $state.go('auth.documents'))
      }, error => {
        $scope.serviceError = error
        $scope.processing = false
      })
    }

    payments.createCardElement().then(card => {
      cardElement = card
      card.mount('#card-element')
    })

    /** Calculate total price */
    $scope.$watch('model.voucherApplied', function (value) {
      var price = $scope.model.price, discount, percentage

      if (!value) {

        // voucher not applied,
        // revert to original price
        $scope.model.total = price
        return
      }

      // read voucher discount from model
      discount = $scope.model.voucherDiscount
      percentage = $scope.model.voucherPercentage

      // integer value of price after discounts have been applied
      $scope.model.total = percentage ? price * ((100 - discount) / 100) : price - discount
    })

  }])
