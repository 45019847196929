import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import './party.inheriting'

angular.module('app.wishes.factories.beneficiary', ['authentication', 'app.wishes.factories.party.inheriting'])
  .factory('wishes.factories.beneficiary', ['$http', 'auth', 'wishes.factories.party.inheriting', function($http, auth, InheritingParty) {
    return constructor

    /**
     *  Return beneficiary
     *  @param {object} obj Existing data
     *  @returns {object} Beneficiary
     *  @constructor
     */
    function constructor(obj) {

      // unique id
      var id = this.id = obj._id || 1

      // set endpoint
      this.endpoint = auth.getBaseURL() + 'beneficiary/' + id

      // extend InheritingParty
      return _.extend(new InheritingParty(obj), this)
    }
  }])
