import _ from 'underscore'
import angular from 'angular'

import '../../../auth'

angular.module('app.assets.factories.asset', ['authentication'])
  .value('factory.asset.createWizardSteps', ['details', 'ownership', 'address'])
  .factory('assets.factories.asset', ['$http', 'auth', 'factory.asset.createWizardSteps', function($http, auth, createWizardSteps) {
    return constructor

    /**
     *  Return asset by type
     *  @param {object} obj Asset data
     *  @returns {object} Asset
     *  @constructor
     */
    function constructor(obj) {

      // unique id
      var id = this.id = obj._id || 1

      // default ownership to owned
      this.ownership = (obj.ownership) ? obj.ownership : 'owned'

      // default shared with to empty object
      this.sharedWith = !_.isEmpty(obj.sharedWith) ? obj.sharedWith : {}

      /** Shared with someone */
      this.isSharedWith = function() {
        return !_.isEmpty(this.sharedWith)
      }

      // share types
      this.shareTypes = [
              {
                'id': '1',
                'name': 'Joint Tenants',
                'value': 'jointOwnership'
              },
              {
                'id': '2',
                'name': 'Tenants In Common',
                'value': 'inCommon'
              },
              {
                'id': '3',
                'name': "I don't know",
                'value': 'notKnown'
              }
          ]

      /** Mocked Case */
      this.mocked = !_.has(obj, '_id')

      /** Complete Case */
      this.isComplete = function() {
        return completed(this)
      }

      // determine if person details are complete
      this.complete = completed(obj)

      /**
       *  Check complete case
       *  @param {object} obj Person object
       *  @return {boolean} Completed
       */
      function completed(obj) {
        return _.every(['_id', 'type', 'name'], _.partial(_.has, obj))
      }

      // Photo

      // image container
      this.images = obj.images || []

      /**
       *  Determine if photo exists
       *  @returns {boolean}
       */
      this.hasPhoto = function() {
        return !_.isEmpty(this.images)
      }

      // Address

      // address container
      this.address = obj.address || {}

      /**
       *  Determine if address exists
       *  @returns {boolean}
       */
      this.hasAddress = function() {
        return !_.isEmpty(this.address)
      }

      /**
       *  Save address container to asset object
       *  @param {object} address Address object
       *  @returns {promise} $http
       */
      this.saveAddress = function(address) {
        if (!_.isEmpty(address)) {
          var endpoint = auth.getBaseURL() + 'asset/' + id + '/address'
          if (_.has(address, '_id')) {
            return this.save({ address: address._id }).then(function() {
              return $http.put(endpoint, _.pick(address, ['line1', 'line2', 'city', 'region', 'postcode', 'country']), auth.getConfig('flat'))
            })
          }

          return $http.post(endpoint, address, auth.getConfig('flat'))
        }
      }

      /**
       *  Resolve create wizard steps
       *  @returns {array} steps
       */
      this.steps = function() {
        return createWizardSteps
      }

      /**
       *  Clear address reference in asset object
       *  @returns {promise} $http
       */
      this.clearAddress = function() {
        return this.save({ address: null })
      }

      /**
       *  Save initial details to asset object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.create = function(attrs) {

        // subjective mandatory details

        if (!_.isEmpty(attrs.address)) {

          // persist address
          this.saveAddress(attrs.address)
        }

        if (_.isObject(attrs.sharedWith)) {

          // use id from populated sharedWith object
          attrs.sharedWith = attrs.sharedWith._id
        }

        if (_.isEmpty(attrs.sharedWith)) {

          // empty bad sharedWith reference
          delete attrs.sharedWith
        }

        // save initial data
        return this.save(_.pick(attrs, ['name', 'ownership', 'sharedWith']))
                  .then(() => {

                    // update complete state once persisted
                    this.complete = completed(this)
                  })
      }

      /**
       *  Save attributes to asset object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        var endpoint = auth.getBaseURL() + 'asset/' + id
        return $http.put(endpoint, attrs, auth.getConfig())
      }

      return _.extend(obj, this)
    }
  }])
