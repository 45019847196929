import angular from 'angular'

import '../../api'
import '../../directives/menu'
import '../../directives/archive'
import '../account/service'

angular.module('app.documents', ['api', 'app.directives.menu', 'app.directives.archive', 'app.account.service'])
  .controller('documents.controller', ['$rootScope', '$scope', 'api', 'directives.menu.service', 'paid', function($rootScope, $scope, api, menu, paid) {
    var generator = api.$res('documents', { type: 'generate' })
    var list = api.$collection('documents', { type: 'list' })

    /** Check if the entire Will review section is complete */
    $scope.willComplete = function() {
      return menu.willComplete($rootScope)
    }

    // only expose certain methods where
    // application state is sufficiently progressed
    if ($scope.willComplete()) {

      /** Generate new Will */
      $scope.generate = function() {
        $scope.state = 'generating'
        generator.get(function(res) {
          $scope.state = 'ready'
          $scope.downloadURL = res.url
          $scope.history = list.fetchAll()
        })
      }

      // reflect paid status
      // from resolve in scope
      $scope.paid = paid

    }

    // fetch list on initiation
    $scope.history = list.fetchAll()
  }])
