import _ from 'underscore'
import angular from 'angular'

import '../../../../auth'
import '../../factories/party.acting'

angular.module('app.wishes.executors.factories.executor', ['authentication', 'app.wishes.factories.party.acting'])
  .factory('wishes.executors.factories.executor', ['$http', 'auth', 'wishes.factories.party.acting', function($http, auth, ActingParty) {
    return constructor

    /**
     *  Return executor
     *  @param {object} obj Existing data
     *  @returns {object} executor
     *  @constructor
     */
    function constructor(obj) {
      var id = this.id = obj._id

      // set endpoint
      this.endpoint = auth.getBaseURL() + 'executor/' + id

      // extend ActingParty
      return _.extend(new ActingParty(obj), this)
    }
  }])
