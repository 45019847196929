import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'

angular.module('app.wishes.legal', ['authentication', 'app.account'])
  .controller('wishes.legal.controller', ['$scope', '$http', 'auth', 'account.service', 'willScope', function($scope, $http, auth, account, willScope) {

    // initiate application state for legal wishes
    _.each(['scope', 'revocation'], function(micro) {
      return account.setProgress({
        section: 'wishes.legal',
        tag: micro
      })
    })

    // expose willScope in model
    $scope.model = _.defaults(willScope || {}, {

      // default to UK
      willScope: 'UK'
    })

    // selectively show Will revocation details
    $scope.showRevoke = requiresAdvancedWillRevocation($scope.model)

    $scope.confirm = function($event, micro) {
      if ($event) $event.preventDefault()
      return account.setProgress({
        section: 'wishes.legal',
        tag: micro
      }, 'confirmed')
    }

    // detect changes in model and persist to server
    $scope.$watch('model', function(newModel, oldModel) {
      var endpoint = auth.getBaseURL() + 'willScope'

      // determine changes in model, restrict saves to these keys
      var diffKeys = _.reject(_.keys(newModel), function(key) {
        return newModel[key] === oldModel[key]
      })

      if (diffKeys.length) {

        // selectively show Will revocation details
        $scope.showRevoke = requiresAdvancedWillRevocation(newModel)

        if (_.has(newModel, '_id')) {

          // save against existing willScope
          endpoint += '/' + newModel._id
          $http.put(endpoint, _.pick(newModel, diffKeys), auth.getConfig('flat'))
        } else {

          // or create anew
          $http.post(endpoint, _.pick(newModel, diffKeys), auth.getConfig('flat'))
                      .then(function(res) {
                        newModel._id = res.data._id
                      })
        }
      }
    }, /* objectEquality: */ true)

    /**
     *  Utility function to determine if advanced revocation details should be captured
     *  @param {object} model Scope model
     *  @return {boolean}
     *  @private
     */
    function requiresAdvancedWillRevocation(model) {
      return !!model.hasPriorWill && model.priorWillScope !== 'UK'
    }

  }])
