import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../factories/party'

angular.module('app.wishes.substitution.factory', ['authentication', 'app.wishes.factories.party'])
  .factory('wishes.substitution.factory', ['$http', 'auth', 'wishes.factories.party', function($http, auth, Party) {
    return constructor

    /**
     *  Return substitution
     *  @param {object} obj Existing data
     *  @returns {object} Substitution
     *  @constructor
     */
    function constructor(obj) {
      var id = this.id = obj._id

      obj.substitutes = _.map(obj.substitutes, function(substitute) {

        // TODO: References in substitution.substitutes.{person,charity,formation} are always resolved as an array by the server even though these are single references - we correct the problem here but this will break if the underlying server-side issue is fixed, likely to do with deep populated models
        return new Party(_.extend(substitute, {
          person: _.isArray(substitute.person) ? substitute.person[0] : substitute.person,
          charity: _.isArray(substitute.charity) ? substitute.charity[0] : substitute.charity,
          formation: _.isArray(substitute.formation) ? substitute.formation[0] : substitute.formation,
        }))
      })

      /** Mocked Case */
      this.mocked = !_.has(obj, '_id')

      /**
       *  Check whether substitute is already represented in the substitution
       *  @param {object} person Person object
       *  @returns {boolean} exists
       *  @public
       */
      this.existsSubstitute = function(person) {
        return isSubstitute.call(this, person) || isSubstituted.call(this, person)

        /** Check if person already exists as substitute */
        function isSubstitute(person) {
          return _.some(this.substitutes, function(substitute) {
            return ((_.isObject(substitute.person)) ? substitute.person._id : substitute.person) === person._id
          })
        }

        /** Check if person is substituted by this substitution */
        function isSubstituted(person) {
          return _.some(this.substituted, function(substituted) {
            return ((_.isObject(substituted)) ? substituted._id : substituted) === person._id
          })
        }
      }

      /**
       *  Add substitute to substitution
       *  @param {object} person
       *  @returns {promise} $http
       */
      this.addSubstitute = function(person) {
        var endpoint = auth.getBaseURL() + 'substitution/' + id + '/substitutes'
        if (!this.existsSubstitute(person)) {
          return $http.post(endpoint, { person: person._id }, auth.getConfig('extended')).then(res => {
            this.substitutes.push(res.data)
          })
        }
      }

      /**
       *  Remove substitute from substitution
       *  @param {object} substitute Reference object
       *  @returns {promise} $http
       */
      this.removeSubstitute = function(substitute) {
        var endpoint = auth.getBaseURL() + 'substitution/' + id + '/substitutes/' + substitute._id
        return $http.delete(endpoint, auth.getConfig()).then(() => {
          this.substitutes = _.without(this.substitutes, substitute)
        })
      }

      /**
       *  Save attributes to substitution object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        if (!_.isUndefined(id)) {
          var endpoint = auth.getBaseURL() + 'substitution/' + id
          return $http.put(endpoint, attrs, auth.getConfig())
        }
      }

      return _.extend(this, obj)
    }
  }])
