import _ from 'underscore'
import angular from 'angular'

import '../../../auth'

angular.module('app.relationships.factories.links', ['authentication'])
  .factory('relationships.factory.link.toTestator', function() {
    var exports = {}
    return function(id, relationships) {
      if (_.isUndefined(id)) throw new Error('Person not identified to relate to testator.')

      // find any relationships defined to testator
      relationships = _.filter(relationships, function(link) {
        return link.origin === id || link.target === id
      })

      if (_.isEmpty(relationships)) {
        exports.type = 'family'
      } else {
        var partner = _.findWhere(relationships, { type: 'partner' }),
            nuclear = _.where(relationships, { type: 'child' })

        if (!_.isEmpty(partner)) exports.type = 'partner'
        if (!_.isEmpty(nuclear)) {
          if (_.findWhere(nuclear, { origin: id })) exports.type = 'parent'
          if (_.findWhere(nuclear, { target: id })) exports.type = 'child'
        }
      }

      return exports
    }
  })
  .factory('relationships.factory.link.partner', function() {
    var exports = {}

    // exports.type = 'partner';

    return function(obj) {
      return _.extend(obj, exports)
    }
  })
  .factory('relationships.factory.link.child', function() {
    var exports = {}

    // exports.type = 'child';

    return function(obj) {
      return _.extend(obj, exports)
    }
  })
  .factory('relationships.factory.link.parent', function() {
    var exports = {}

    // invert relationship
    exports.type = 'child'

    return function(obj) {

      // relationships are downward,
      // so adding a parent link inverts as a
      // child link from the parent's perspective
      exports.origin = obj.target
      exports.target = obj.origin

      // remove object references
      delete obj.origin
      delete obj.target

      return _.extend(obj, exports)
    }
  })
  .factory('relationships.factory.link.other', function() {
    var exports = {}

    // exports.type = 'other';

    return function(obj) {
      return _.extend(obj, exports)
    }
  })
  .factory('relationships.factory.link', ['$http', 'auth', 'relationships.factory.link.partner', 'relationships.factory.link.child', 'relationships.factory.link.parent', 'relationships.factory.link.other',
      function($http, auth, Partner, Child, Parent, Other) {
        return constructor

        /**
         *  Return link by type
         *  @param {object} object Link object
         *  @returns {object} Link
         *  @constructor
         */
        function constructor(obj) {
          var Model, origin

          // unique id
          var id = this.id = (obj || {})._id

          // break out of constructor if data is corrupt
          if (!(obj.origin && obj.target)) return false

          switch (obj.type) {
            case 'partner':
              Model = Partner
              break
            case 'child':
              Model = Child
              break
            case 'parent':
              Model = Parent
              break
            default:
              Model = Other
          }

          // extend subclass model
          obj = _.extend(obj, new Model(obj))

          // reference origin and target node objects
          this.originModel = obj.origin
          this.targetModel = obj.target

          // flatten origin and target to id strings
          this.origin = origin = obj.origin.id
          this.target = obj.target.id

          /**
           *  Returns the descriptive type of the relationship.
           *  @param {string} id
           *  @returns {string} Descriptive type
           */
          this.description = function(id) {
            var desc

            switch (obj.type) {
              case 'child':
                desc = (origin === id) ? 'child' : 'parent'
                break
              default:
                desc = obj.type
            }

            return desc
          }

          /**
           *  Save attributes to relationship object
           *  @param {object} attrs Attributes object
           *  @returns {promise} $http
           */
          this.save = function(attrs) {
            if (id) {
              var endpoint = auth.getBaseURL() + 'relationship/' + id
              return $http.put(endpoint, attrs, auth.getConfig('signed', 'PUT', endpoint))
            }
          }

          return _.extend(obj, this)
        }
      }
  ])
