import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../../directives/drag'
import '../../../directives/carousel'
import '../../account/service'
import './service'

angular
  .module('app.wishes.executors', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.account.service',
    'app.wishes.executors.service',
  ])
  .controller('wishes.executors.controller', [
    '$scope',
    '$http',
    '$state',
    '$sce',
    'auth',
    'account.service',
    'wishes.executors.service',
    function ($scope, $http, $state, $sce, auth, account, executorship) {
      // initiate info
      $scope.info = false

      import('../../../../tpl/wishes/executors/info.html').then(
        (infoTemplate) => {
          $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
        },
      )

      $scope.setInfo = function (state) {
        $scope.info = state
      }

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.confirm = function ($event) {
        if ($event) $event.preventDefault()
        return account.setProgress(
          {
            section: 'wishes.executors',
          },
          'confirmed',
        )
      }

      $scope.plurals = {}
      $scope.plurals.order = {
        0: '{}',
        1: '{}st',
        2: '{}nd',
        3: '{}rd',
        other: '{}th',
      }

      // collection
      $scope.model = {
        // abstracted executors in model
        executorship: executorship.get,
      }

      /** Check if person exists as executor */
      $scope.exists = function (person) {
        // abstract exists beneficiary check
        if (_.isUndefined($scope.model.executorship())) return
        return $scope.model.executorship().existsExecutor(person)
      }
      $scope.add = function ($event, person) {
        $event.preventDefault()

        if (!$scope.loading) {
          $scope.loading = true

          // re-assign to a person object from the incoming person ID
          person = _.findWhere($scope.candidates, { _id: person })
          if (!_.isEmpty(person))
            return $scope.model
              .executorship()
              .addExecutor(person)
              .then(updateCandidates)
              .then(() => ($scope.loading = false))
        }
      }
      $scope.remove = function ($event, executor) {
        $event.preventDefault()

        // remove executor from executorship
        $scope.model
          .executorship()
          .removeExecutor(executor)
          .then(updateCandidates)
      }

      // fetch executorship
      executorship.fetch()

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible executor candidates */
      function updateCandidates() {
        var endpoint = auth.getBaseURL() + 'candidates/executors'
        return $http
          .get(endpoint, auth.getConfig('signed', 'GET', endpoint))
          .then((res) => {
            $scope.candidates = res.data
            return res
          })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }

        return JSON.stringify(currentState)
      }
    },
  ])
