import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import '../factories/gift'

angular
  .module('app.wishes.services.gifts', [
    'authentication',
    'app.account.service',
    'app.wishes.factories.gift',
  ])
  .service('wishes.services.gifts', [
    '$http',
    'auth',
    'wishes.factories.gift',
    function ($http, auth, Gift) {
      var collection = []

      /**
       *  Fetch gifts from server side
       *  @returns {promise} $http
       *  @public
       */
      this.fetch = function () {
        var endpoint = auth.getBaseURL() + 'gift'
        return $http
          .get(endpoint, auth.getConfig('deepextended'))
          .then(function (res) {
            // map results into memory
            collection = _.map(res.data || [], function (gift) {
              return new Gift(gift)
            })

            // return response for chaining
            return res
          })
      }

      /**
       *  Get item or all items from collection
       *  @param {string} [id] Gift id to retrieve
       *  @returns {object|array} Gift or Gifts
       *  @public
       */
      this.get = function (id) {
        return !_.isUndefined(id)
          ? _.findWhere(collection, { id: id })
          : collection
      }

      /**
       *  Return gifts containing assets of type
       *  @param {string} [type]
       *  @returns {array} Collection
       *  @public
       */
      this.getByAssetType = function (type) {
        if (_.isUndefined(type)) return collection

        // returned filtered collection
        return _.filter(collection, function (gift) {
          // ensure asset exists and match asset type
          return !_.isEmpty(gift.asset) && gift.asset.type === type
        })
      }

      /**
       *  Check whether gift is already represented in collection
       *  @param {object} asset Asset object
       *  @returns {boolean} Exists
       *  @public
       */
      this.exists = function (asset) {
        return _.find(collection, function (obj) {
          return (obj.asset || {})._id === asset._id
        })
      }

      /**
       *  Add a gift
       *  @param {object}
       *  @returns {promise} $http
       *  @public
       */
      this.add = function (data) {
        var endpoint = auth.getBaseURL() + 'gift'
        if (_.isEmpty(data)) {
          return $http.post(endpoint, {}, auth.getConfig()).then((res) => {
            var gift = new Gift(res.data)
            collection.push(gift)
            return gift
          })
        }

        return $http
          .post(auth.getBaseURL() + 'asset', data.asset, auth.getConfig())
          .then((res) => {
            return $http
              .post(endpoint, { asset: res.data._id }, auth.getConfig())
              .then((res) => {
                var gift = new Gift(res.data)
                collection.push(gift)
                return gift
              })
          })
      }

      /**
       *  Remove a gift
       *  @param {object} executor Object reference to executor
       *  @public
       */
      this.remove = function (gift) {
        var endpoint = auth.getBaseURL() + 'gift/' + gift._id
        return $http.delete(endpoint, auth.getConfig()).then(() => {
          collection = _.without(collection, gift)
          return collection
        })
      }
    },
  ])
