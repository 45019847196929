import _ from 'underscore'
import angular from 'angular'

import '../../auth'
import '../../directives/address'
import '../../directives/image'
import './service'

angular.module('app.account', ['app.directives.address', 'app.directives.image', 'app.account.service'])
  .controller('account.settings.controller', ['$scope', '$stateParams', '$location', 'account.service', 'user', function($scope, $stateParams, $location, account, user) {

    // route section
    $scope.section = $stateParams.section || ''

    // create scope for input
    $scope.input = {
      pattern: /^(?=.*\d+)(?=.*[a-zA-Z])[0-9a-zA-Z!#$%&*@^\ "'()+,\-.\/:;<=>?[\\\]_`{|}~]{6,128}$/
    }

    // populate details from service layer
    account.getUserDetails().then(function(res) {
      $scope.input = _.extend(res.data, $scope.input)
    })

    /**
     *  Update data on server
     *  @param {angular.$event} $event Angular Event
     *  @param {object} [params] Parameters to update
     *  @public
     */
    $scope.update = function($event, params) {

      // prevent fallback form submission
      $event.preventDefault()

      // push to service layer
      account.setUserDetails(params || $scope.input)
    }

    /**
     *  Change user's password
     *  @param {angular.$event} $event Angular Event
     *  @param form Form wrapper
     *  @param {string} password New password to change to
     *  @public
     */
    $scope.changePassword = function($event, form, password) {

      // prevent fallback form submission
      $event.preventDefault()
      $scope.loading = true

      // push to service layer
      if (form.$valid) {
        account.change(password).then(function() {
          $scope.loading = false
          form.submitted = true

          // remove password in scope
          $scope.input.password = ''
        }, function() {
          $scope.loading = false

          // flag server error
          form.$error.server = true
        })
      }
    }
    $scope.user = user
  }])
  .controller('account.session.controller', ['$scope', '$location', 'account.service', function($scope, $location, account) {

    // log out of session and redirect to login screen
    account.logout().then(() => $location.path('/login'))
  }])
