import _ from 'underscore'
import angular from 'angular'

angular.module('app.relationships.directives', [])
  .directive('ngOrient', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {

        // alias orient attribute
        element.attr('orient', attrs.ngOrient)
      }
    }
  })
  .directive('appFillViewport', ['$window', function($window) {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope, element) {
        var viewport = angular.element($window)

        function setSizing() {
          element.css({
            'position': 'relative',
            'width': '100%',
            'height': viewport.height() * 0.80 + 'px'
          })
          element.attr('height', viewport.height() * 0.80)
        }

        setSizing()

        // scale on window resize, limited refresh rate
        viewport.bind('resize', _.throttle(setSizing, 100))
      }
    }
  }])
