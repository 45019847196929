import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import './party'

angular.module('app.wishes.factories.party.inheriting', ['authentication', 'app.wishes.factories.party'])
  .factory('wishes.factories.party.inheriting', ['$http', 'auth', 'wishes.factories.party', function($http, auth, Party) {
    return constructor

    /**
     *  Return inheriting party
     *  @param {object} obj Existing data
     *  @returns {object} Inheriting party
     *  @constructor
     */
    function constructor(obj) {

      // obj.extended represents a shorthand
      // extension of the inheriting party
      // to his or her own inheritors,
      // candidate for removal once classes are supported in substitutions
      // this.extended = obj.extended

      // obj.distributed represents a shorthand
      // extension of the inheriting party
      // to remaining named inheritors,
      // candidate for removal once classes are supported in substitutions
      // this.distributed = obj.distributed

      // Distribution

      // ensure share is numerical
      this.share = parseFloat(obj.share) || 0

      /**
       *  Parse share as human readable value
       *  @returns {string} HTML-encoded string
       */
      this.shareValue = function() {

        // read share value as float
        var share = parseFloat(this.share)

        if (share) {

          // convert share to html encoded fraction,
          // or return as percentage
          switch (Math.round((share / 100) * 1000) / 1000) {
            case 1 / 2:
              share = '&frac12;'
              break
            case Math.round((1 / 3) * 1000) / 1000:
              share = '&#8531;'
              break
            case Math.round((2 / 3) * 1000) / 1000:
              share = '&#8532;'
              break
            case 1 / 4:
              share = '&frac14;'
              break
            case 3 / 4:
              share = '&frac34;'
              break
            case 1 / 5:
              share = '&#8533;'
              break
            case 2 / 5:
              share = '&#8534;'
              break
            case 3 / 5:
              share = '&#8535;'
              break
            case 4 / 5:
              share = '&#8536;'
              break
            case Math.round((1 / 6) * 1000) / 1000:
              share = '&#8537;'
              break
            case Math.round((5 / 6) * 1000) / 1000:
              share = '&#8538;'
              break
            case 1 / 8:
              share = '&#8539;'
              break
            case 3 / 8:
              share = '&#8540;'
              break
            case 5 / 8:
              share = '&#8541;'
              break
            case 7 / 8:
              share = '&#8542;'
              break
            default:
              share = '='
              break
          }
        }

        // a share value of 0
        // is used for equal distribution
        return share || '='
      }

      return _.extend(new Party(obj), this)
    }
  }])
