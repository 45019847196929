import angular from 'angular'

angular.module('app.directives.card', [])
  .controller('directives.card.controller', ['$scope', '$location', function($scope, $location) {

    // define card icon
    $scope.icon = ($scope.type === 'asset') ? ($scope.model.type === 'realEstate' ? 'fa-home fa-4x' : 'fa-suitcase fa-3x') : 'fa-user fa-4x'

    /** Select card for editing */
    $scope.select = $scope.edit = function() {
      $location.path(($scope.type === 'asset' ? '/assets/' : '/relationships/') + $scope.model.id)
    }
  }])
  .directive('appCard', () => {
    return {
      restrict: 'E',
      replace: true,
      controller: 'directives.card.controller',
      scope: {
        'type': '@ngType',
        'model': '=ngModel'
      },
      template: require('../../tpl/directives/card.svg')
    }
  })
