import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../../directives/drag'
import '../../../directives/carousel'
import '../../account/service'
import './service'

angular
  .module('app.wishes.guardianships', [
    'authentication',
    'app.directives.drag',
    'app.directives.carousel',
    'app.account.service',
    'app.wishes.guardianships.service',
  ])
  .controller('wishes.guardianships.controller', [
    '$scope',
    '$http',
    '$state',
    '$sce',
    'auth',
    'account.service',
    'wishes.guardianships.service',
    function ($scope, $http, $state, $sce, auth, account, guardianship) {
      // initiate tutorial
      $scope.info = false

      import('../../../../tpl/wishes/guardians/info.html').then(
        (infoTemplate) => {
          $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
        },
      )

      $scope.setInfo = function (state) {
        $scope.info = state
      }

      $scope.goToRelationshipsEditor = ($event) => {
        if ($event) $event.preventDefault()
        $state.go('auth.relationships.editor', {
          id: 'new',
          redirect: stringifyCurrentState(),
        })
      }

      $scope.confirm = function ($event) {
        if ($event) $event.preventDefault()
        return account.setProgress(
          {
            section: 'wishes.guardians',
          },
          'confirmed',
        )
      }

      // collection
      $scope.model = {
        // guardians in model,
        // abstraction of guardianship.get
        guardianship: guardianship.get,
      }

      /**
       *  Check if person exists as guardian
       *  @param {object} person
       */
      $scope.exists = function (person) {
        // abstract exists guardian check
        if (_.isUndefined($scope.model.guardianship())) return
        return $scope.model.guardianship().existsGuardian(person)
      }

      /**
       *  Add guardian to guardianship
       *  @param {angular.$event} $event Angular Event
       *  @param {string} id
       *  @return {promise}
       */
      $scope.add = function ($event, id) {
        $event.preventDefault()

        if (!$scope.loading) {
          $scope.loading = true

          // re-assign to a person object from the incoming id
          let person = _.findWhere($scope.candidates, { _id: id })
          if (!_.isEmpty(person)) {
            return $scope.model
              .guardianship()
              .addGuardian(person)
              .then(updateCandidates)
              .then(() => ($scope.loading = false))
          }
        }
      }

      /**
       *  Remove guardian from guardianship
       *  @param {angular.$event} $event Angular Event
       *  @param {object} guardian
       *  @return {promise}
       */
      $scope.remove = function ($event, guardian) {
        $event.preventDefault()

        // remove guardian from guardianship
        $scope.model
          .guardianship()
          .removeGuardian(guardian)
          .then(updateCandidates)
      }

      /**
       *  Update guardianship with override of partner wishes
       *  @param {angular.$event} $event Angular Event
       *  @param {boolean} value Override
       *  @return {promise}
       */
      $scope.updatePartnerOverride = function ($event, value) {
        return $scope.model.guardianship().save({
          partnerWishesOverrideMine: value,
        })
      }

      // fetch guardianship
      guardianship.fetch()

      $scope.candidates = []

      // fetch candidates
      updateCandidates()

      /** Fetch eligible guardian candidates */
      function updateCandidates() {
        var endpoint = auth.getBaseURL() + 'candidates/guardians'
        return $http.get(endpoint, auth.getConfig()).then((res) => {
          $scope.candidates = res.data
          return res
        })
      }

      function stringifyCurrentState() {
        let currentState = { to: $state.current.name }
        return JSON.stringify(currentState)
      }
    },
  ])
