import _ from 'underscore'
import angular from 'angular'

import '../../../auth'

angular.module('app.relationships.factories.addresses', ['authentication'])
  .factory('relationships.factory.address', ['$q', '$http', 'auth', function($q, $http, auth) {
    return constructor

    /**
     *  Return asset by type
     *  @param {object} obj Asset data
     *  @returns {object} Asset
     *  @constructor
     */
    function constructor(obj) {

      // unique id
      var id = this.id = (!_.isUndefined(obj)) ? obj._id : 0

      /**
       *  Save attributes to asset object
       *  @param {object} attrs Attributes object
       *  @returns {promise} $http
       */
      this.save = function(attrs) {
        if (id !== 0) {
          var endpoint = auth.getBaseURL() + 'address/' + id
          return $http.put(endpoint, attrs, auth.getConfig('signed', 'PUT', endpoint))
        }
      }

      return _.extend(obj, this)
    }
  }])
