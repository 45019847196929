import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import '../factories/gift'

angular.module('app.wishes.estate.service', ['authentication', 'app.account.service', 'app.wishes.factories.gift'])
  .service('wishes.estate.service', ['$http', 'auth', 'wishes.factories.gift',
      function($http, auth, Gift) {

        var id = auth.getUserEstate(), estate

        // verify estate is defined, throw if not
        if (_.isUndefined(id)) throw new Error('Estate is not defined, malformed user data')

        /**
         *  Fetch estate from server side
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function() {
          var endpoint = auth.getBaseURL() + 'gift/' + id
          return $http.get(endpoint, auth.getConfig(['signed', 'deepextended'], 'GET', endpoint)).then(function(res) {

            // cache estate
            estate = new Gift(res.data)

            // return response for chaining
            return res
          })
        }

        /**
         *  Get estate object
         *  @returns {object} Estate
         *  @public
         */
        this.get = function() {
          return estate
        }

      }
])
