import _ from 'underscore'
import angular from 'angular'

import './node.list'

angular.module('app.relationships.services.prototypes', ['app.relationships.services.node.list'])
  .service('relationships.service.prototypes', ['relationships.service.node.list', function(nodes) {

    function partner(active) {
      var data = {}

      if (!_.isEmpty(nodes.get(active).address)) {

        // if defined,
        // prefill same address as active
        data.address = nodes.get(active).address._id
      }

      return nodes.add(data).then(function(node) {
        return nodes.get(active).link(node, 'partner')
                  .then(function() {
                    if (!_.isEmpty(nodes.get(active).birth)) {

                      // if defined,
                      // prefill same minor status as active
                      node.birth.minor = !!nodes.get(active).birth.minor
                    }

                    return node
                  })
      })
    }
    this.partner = partner

    function child(active) {
      var data = {

        // prefill active surname
        surname: nodes.get(active).surname
      }

      return nodes.add(data).then(function(node) {
        return nodes.get(active).link(node, 'child')
            .then(function() { return node;})
      })
    }
    this.child = child

    function parent(active) {
      return nodes.add().then(function(node) {
        return nodes.get(active).link(node, 'parent')
                  .then(function() {
                    node.birth.minor = false
                    return node
                  })
      })
    }
    this.parent = parent

    function other() {
      return nodes.add().then(function(node) {
        node.birth.minor = false
        return node
      })
    }
    this.other = other
  }])
