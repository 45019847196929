import _ from 'underscore'
import angular from 'angular'

import svgTemplate from '../../../tpl/relationships/tree.svg'

import 'typeahead'
import '../../auth'
import '../../bus'
import '../../api'
import '../../directives/card'
import '../account/service'
import './services/link.table'
import './services/node.list'
import './services/prototypes'
import './directives'
import './familytree/directives'

angular.module('app.relationships', ['authentication', 'msgbus', 'api', 'app.directives.card', 'app.account.service', 'app.relationships.services.link.table', 'app.relationships.services.node.list', 'app.relationships.services.prototypes', 'app.relationships.directives', 'app.relationships.familytree.directives'])
  .controller('relationships.editor.controller', ['$state', '$stateParams', '$scope', 'auth', 'bus', 'api', 'account.service', 'relationships.service.prototypes', 'resource', function($state, $stateParams, $scope, auth, bus, api, account, prototypes, resource) {

    var engine

    let collapseControls = ['name', 'gender', 'dob', 'children', 'address', 'editName', 'editGender', 'editDoB', 'editAddress']

    $scope.collapse = _.reduce(collapseControls, (obj, control) => {
      obj[control] = true
      return obj
    }, {})

    function resetCollapse() {
      _.extend($scope.collapse, _.mapObject($scope.collapse, () => true))
    }

    /** Return to referring view */
    $scope.close = function($event) {

      // prevent default
      if (_.has($event, 'preventDefault')) $event.preventDefault()

      try {
        let redirect = JSON.parse($stateParams.redirect)

        // return to gift
        $state.go(redirect.to, redirect.params)
      } catch (e) {

        // default to relationships list view
        $state.go('auth.relationships.list')
      }
    }

    // template form model
    $scope.formModel = {
      parentConflict: false,
      partnerConflict: false,

      // default to self in focus
      focus: resource.getSelf().id
    }

    // set dataset resource in scope
    $scope.dataset = resource.get

    // set model type in scope
    $scope.type = $stateParams.type

    // set delegated state in scope
    $scope.delegated = $stateParams.delegated

    if (_.has($stateParams, 'id')) {

      // set resource presently in focus to scoped model, or create anew
      $scope.model = $stateParams.id === 'new' ? resource.mock({}) : resource.get($stateParams.id)
    } else {

      // default to focus on self
      $scope.model = resource.getSelf()
    }

    // refer from unsupported state parameters,
    // or non-existent model id (404)
    if (_.isEmpty($scope.model)) return $scope.close()

    // register typeahead engine
    engine = new window.Bloodhound({
      datumTokenizer: d => window.Bloodhound.tokenizers.whitespace(d.fullnameStatic),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      limit: 64,
      local: _.reject($scope.dataset(), entry => entry.id === $scope.model.id)
    })
    engine.initialize()

    // register typeahead comparisons
    $scope.datasetRelatives = {
      displayKey: 'fullnameStatic',
      source: engine.ttAdapter()
    }

    // editor state
    $scope.state = 'saved'

    // editor nav
    $scope.open = $scope.model.complete ? 'details' : ($scope.model.steps() || [])[0]

    /**
     *  Return steps in model
     *  @returns {array} steps
     */
    $scope.steps = function() {
      return _.uniq($scope.model.steps())
    }

    /** Return current step number, 1-based index */
    $scope.step = function() {
      return $scope.model.mocked ? 0 : (_.indexOf($scope.steps(), $scope.open) + 1)
    }

    /** Wizard next step */
    $scope.next = function() {
      $scope.navigate($scope.steps()[_.indexOf($scope.steps(), $scope.open) + 1])
    }

    /** Wizard prev step */
    $scope.prev = function() {
      $scope.navigate($scope.steps()[_.indexOf($scope.steps(), $scope.open) - 1])
    }

    /** Navigation helper */
    $scope.navigate = function(open) {
      $scope.open = open
    }

    /** Enter edit state */
    $scope.edit = function($event) {

      // prevent default
      if ($event) $event.preventDefault()
      $scope.state = 'edit'

      // publish edit event
      bus.publish('action', 'delegates', 'edit')
    }

    /** Enter saved state */
    $scope.done = function($event) {

      // prevent default
      if ($event) $event.preventDefault()
      $scope.state = 'saved'

      resetCollapse()

      // publish view event
      bus.publish('action', 'delegates', 'save')
    }

    /** Enter created state */
    $scope.create = function($event, attrs) {
      var promise

      // prevent default
      if ($event) $event.preventDefault()

      // create from mocked or existing
      if ($scope.model.mocked) {

        // add relationship by prototype,
        // applied against target in focus
        switch (attrs.type) {
          case 'partner':
            promise = prototypes.partner(attrs.target)
            break
          case 'child':
            promise = prototypes.child(attrs.target)
            break
          case 'parent':
            promise = prototypes.parent(attrs.target)
            break
          default:
            promise = prototypes.other()
        }

        promise.then(function(node) {

          // on completion
          // select completed node
          $scope.model = node

          // update image resource
          $scope.resource = createImageResource($scope.model.id)

          // unmock any mocked nodes
          resource.unmock()

          // update progress
          $scope.start()
        })

      } else {

        // return once model is created
        promise = $scope.model.create(attrs)
        promise.then($scope.close)
      }

      return promise
    }

    /** Remove model */
    $scope.remove = function($event) {

      // prevent default
      if ($event) $event.preventDefault()

      // remove mocked or persisted model
      if ($scope.model.mocked) {

        // unmock model
        resource.unmock()

        // navigate back
        $scope.close()
      } else {

        // process model removal
        resource.remove($scope.model)
            .then($scope.close)
      }
    }

    /* Copy the incoming address */
    $scope.useSameAddress = function(address) {
      delete $scope.model.address
      $scope.model.address = address
    }

    /**
     *  Check if progression is allowed during the initial relationship type selection
     */
    $scope.canProgress = function(type, target, form) {

      // make sure all the inputs are valid and not null
      if (type && target && form) {

        // retrieve target model from collection
        target = resource.get(target)

        if (!_.isEmpty(target)) {

          // check which relationship type was chosen and
          // if the person selected in the dropdown has already reach the limit
          if (type === 'parent' && target.hasParents()) {
            $scope.formModel.parentConflict = true
            $scope.formModel.partnerConflict = false
          } else if (type === 'partner' && target.hasPartner()) {
            $scope.formModel.parentConflict = false
            $scope.formModel.partnerConflict = true
          } else {
            $scope.formModel.parentConflict = false
            $scope.formModel.partnerConflict = false
          }

          return !($scope.formModel.parentConflict || $scope.formModel.partnerConflict)
        }
      }

      // simple check to allow other (e.g. friend) to be selected on its own
      // without the need to select from the dropdown
      if (type && type === 'other') {
        return true
      }

      // invalid by default
      return false
    }

    if ($scope.model.immutable) {

      // user registered email
      $scope.immutableEmail = auth.getUsername()
    }

    if ($scope.delegated) {
      $scope.confirm = function($event) {
        if ($event) $event.preventDefault()
        resetCollapse()
        account.setProgress('register', 'confirmed')
      }
    } else {
      $scope.start = function($event) {
        if ($event) $event.preventDefault()
        account.setProgress('relationships')
      }
      $scope.confirm = function($event) {
        if ($event) $event.preventDefault()
        resetCollapse()
        account.setProgress('relationships', 'confirmed')
      }
    }

    $scope.resource = createImageResource($scope.model.id)
    function createImageResource(id) {

      // create image $resource
      return api.$res('person', { id: id, subres: 'images' }, {
        save: {
          method: 'POST',
          headers: { 'X-Data-Expand': undefined }
        }
      })
    }

  }])
.controller('relationships.controller', ['$scope', '$stateParams', '$timeout', 'auth', 'relationships.service.link.table', 'relationships.service.node.list', function($scope, $stateParams, $timeout, auth, links, nodes) {

  // initiate tutorial
  $scope.tutorial = false

  $scope.setTutorial = function(state) {
    $scope.tutorial = state
  }

  $scope.svgTemplate = svgTemplate

  // nodes collection
  $scope.model = {

    // nodes in model,
    // abstraction of nodes.get
    nodes: function() {
      return _.reject(nodes.get(), function(node) {
        return node.mocked
      })
    },
    self: nodes.getSelf,

    // links in model
    links: links.get,

    // filtered node sets
    family: function() {
      return _.filter($scope.model.nodes(), function(node) {

        // a family member is defined as,
        // has relationships defined,
        // or is testator
        return !_.isEmpty(node.links()) || node.immutable
      })
    },
    friends: function() {
      return _.filter($scope.model.nodes(), function(node) {

        // a friend is defined as,
        // no relationships defined,
        // and is not testator
        return _.isEmpty(node.links()) && !node.immutable
      })
    },

    // Zoom level for family tree
    scale: 1
  }

  // Raised by the family tree directive
  $scope.$on('ftFocusNode', function(event, id) {
    $scope.active = id
  })

  /**
   *  Focus node as active
   *  @param {object} node
   */
  $scope.focus = function(node) {

    // publish focus event
    $scope.active = node.id || (nodes.getSelf() || {}).id
  }

  // focus testator
  $timeout(function() {
    $scope.focus($scope.model.self())
  })

}])
