import $ from 'jquery'
import _ from 'underscore'
import angular from 'angular'

// patch jQuery dataTransfer API
$.event.props.push('dataTransfer')

angular
  .module('app.directives.drag', [])
  .directive('appDrag', function () {
    function link(scope, element) {
      let data = scope.appDrag || ''
      if (_.isObject(data)) data = JSON.stringify(data)

      // set element draggable
      angular.element(element).attr('draggable', 'true')

      element.on('dragstart', (e) => {
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text', data)
        scope.$parent.$emit('action:drag-start')
      })

      element.on('dragend', () => {
        scope.$parent.$emit('action:drag-end')
      })
    }

    return {
      link: link,
      scope: {
        appDrag: '=',
      },
      restrict: 'A',
    }
  })
  .directive('appDrop', function () {
    function link(scope, element) {
      element.on('dragover', (e) => {
        e.preventDefault()

        // drop effect on data transfer
        e.dataTransfer.dropEffect = 'move'
        return false
      })

      element.on('dragenter', () => {
        angular.element(element).addClass('drop-active')
      })

      element.on('dragleave', () => {
        angular.element(element).removeClass('drop-active')
      })

      element.on('drop', (e) => {
        e.preventDefault()
        e.stopPropagation()

        // retrieve data
        let data = e.dataTransfer.getData('text')

        // parse object data
        try {
          data = JSON.parse(data)
        } catch (err) {
          console.error(err)
          return
        }

        // resolve method with data
        scope.appDrop({
          $event: e,
          payload: data,
        })
      })

      scope.$parent.$on('action:drag-start', function () {
        angular.element(element).addClass('drop-target')
      })

      scope.$parent.$on('action:drag-end', function () {
        angular.element(element).removeClass('drop-target')
        angular.element(element).removeClass('drop-active')
      })
    }

    return {
      link: link,
      scope: {
        appDrop: '&',
      },
      restrict: 'A',
    }
  })
