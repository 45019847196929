import _ from 'underscore'
import angular from 'angular'

angular.module('msgbus', []).service('bus', ['$rootScope', function($rootScope) {

  var pointers = {}

  // map $stateProvider events
  // $stateChangeStart
  $rootScope.$on('$stateChangeStart', ($event, target) => {

    // $stateChangeStart --> action:$state:change:start
    publish('action', '$state', 'change:start', target)
  })

  // $stateChangeSuccess
  $rootScope.$on('$stateChangeSuccess', ($event, target) => {

    // $stateChangeSuccess --> action:$state:change:success
    publish('action', '$state', 'change:success', target)
  })

  // $stateChangeError
  $rootScope.$on('$stateChangeError', ($event, target) => {

    // $stateChangeError --> action:$state:change:error
    publish('action', '$state', 'change:error', target)
  })

  // map $routeProvider events
  // $routeUpdate
  $rootScope.$on('$routeUpdate', $event => {

    // $routeUpdate --> action:$route:update
    publish('action', '$route', 'update', $event)
  })

  /**
   *  Register new subscription on message bus
   *  @param {object} $scope
   *  @param {string} type Subscription type, 'action:' or 'data:'
   *  @param {string} ns Namespace
   *  @param {string} event
   *  @param {function} fn
   */
  function subscribe($scope, type, ns, event, fn) {

    // catch missing $scope to prevent memory leaks
    if (_.isUndefined($scope.$id)) throw new Error('\'$scope\' not provided to message bus service')

    // catch malformed arguments, missing listener
    if (!_.isFunction(fn)) throw new Error('Listener function not provided to message bus service')

    // register subscription, managed in memory
    mem($scope, $rootScope.$on(formEvent(type, ns, event), fn))
  }

  // expose public method
  this.subscribe = subscribe

  /**
   *  Publish event on message bus
   *  @param {string} type Subscription type, 'action:' or 'data:'
   *  @param {string} ns Namespace
   *  @param {string} event
   *  @param [data]
   */
  function publish(type, ns, event, ...data) {

    // emit event across $rootScope
    $rootScope.$emit(formEvent(type, ns, event), ...data)
  }

  // expose public method
  this.publish = publish

  /**
   *  Message bus memory management
   *  @param {object} $scope
   *  @param {function} sub Deregistration task
   */
  function mem($scope, sub) {

    // read pointer
    var pointer = pointers[$scope.$id]

    // manage pointer
    if (_.isUndefined(pointer)) {

      // create new pointer
      pointer = pointers[$scope.$id] = {}
    } else {

      // flash existing pointer,
      // deregisters existing destroy task
      pointer.flash()
    }

    // register new subscription,
    // either append to existing array or create anew
    pointer.subs = _.union(pointer.subs || [], [sub])

    // register flash method,
    // destroy task on scope to manage memory
    pointer.flash = $scope.$on('$destroy', function() {

      // propagate destroy action
      // $scope.$emit('action:destroy'); - no current use-cases
      // flash task on scope destroy
      _.each(pointer.subs, function(deregister) {

        // deregistration
        deregister.call(null)
      })

      // delete reference of self
      delete pointers[$scope.$id]
    })
  }

  /**
   *  Form event string, colon-separated
   *  @param {string} type
   *  @param {string} ns
   *  @param {string} event
   *  @return {string} Event
   */
  function formEvent(type, ns, event) {
      let args = [type, ns, event]

      if (!_.every(args)) {

        // catch malformed event, missing type, ns, or event
        throw new Error('Malformed subscription, type:namespace:event not all provided to message bus service')
      }

      return args.join(':')
    }

}])
