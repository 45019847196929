import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import './party'

angular.module('app.wishes.factories.party.acting', ['authentication', 'app.wishes.factories.party'])
  .factory('wishes.factories.party.acting', ['$http', 'auth', 'wishes.factories.party', function($http, auth, Party) {
    return constructor

    /**
     *  Return acting party
     *  @param {object} obj Existing data
     *  @returns {object} Acting party
     *  @constructor
     */
    function constructor(obj) {
      return _.extend(new Party(obj), this)
    }
  }])
