import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'
import './factories/executorship'
import './factories/executor'

angular.module('app.wishes.executors.service', ['authentication', 'app.account.service', 'app.wishes.executors.factories.executorship', 'app.wishes.executors.factories.executor'])
  .service('wishes.executors.service', ['$http', 'auth', 'wishes.executors.factories.executorship', 'wishes.executors.factories.executor',
      function($http, auth, Executorship, Executor) {
        var collection = []
        var id = auth.getUserExecutorship(), executorship

        // verify executorship is defined, throw if not
        if (_.isUndefined(id)) throw new Error('Executorship is not defined, malformed user data')

        /**
         *  Fetch executorship from server side
         *  @returns {promise} $http
         *  @public
         */
        this.fetch = function() {
          var endpoint = auth.getBaseURL() + 'executorship/' + id
          return $http.get(endpoint, auth.getConfig('deepextended')).then(function(res) {

            // cache executorship
            executorship = new Executorship(res.data)

            // return response for chaining
            return res
          })
        }

        /**
         *  Get executorship object
         *  @returns {object} Executorship
         *  @public
         */
        this.get = function() {
          return executorship
        }

        /**
         *  Add an executor
         *  @param {object} person Person object
         *  @returns {promise} $http
         *  @public
         */
        this.add = function(person) {
          var endpoint = auth.getBaseURL() + 'executorship/' + id + '/executors'
          if (!this.exists(person)) {
            $http.post(endpoint, { person: person._id }, auth.getConfig())
                      .then(res => {
                        var ref = res.data
                        return $http.get(auth.getBaseURL() + 'executor', auth.getConfig())
                            .then(res => {
                              var executor = new Executor(_.findWhere(res.data, { _id: ref._id }))
                              collection.push(executor)
                              return this.get(executor.id)
                            })
                      })
          }
        }

        /**
         *  Remove an executor
         *  @param {object} executor Object reference to executor
         *  @public
         */
        this.remove = function(executor) {
          var endpoint = auth.getBaseURL() + 'executorship/' + id + '/executor/' + executor._id
          if (executor.substitution) this.unreference(executor.substitution, executor)
          $http.delete(endpoint, auth.getConfig()).then(function() {
            collection = _.without(collection, executor)
          })
        }
      }
  ])
