import _ from 'underscore'
import angular from 'angular'

import '../../../auth'
import '../../account/service'

angular.module('app.wishes.funeral', ['authentication', 'app.account'])
  .controller('wishes.funeral.controller', ['$scope', '$http', '$sce', 'auth', 'account.service', function($scope, $http, $sce, auth, account) {

    $scope.model = {}

    $scope.info = false
    
    import('../../../../tpl/wishes/funeral/info.html').then((infoTemplate) => {
      $scope.infoTemplate = $sce.trustAsHtml(infoTemplate)
    })

    $scope.setInfo = function(info) {
      $scope.info = info
    }

    $scope.editing = false

    $scope.setEditing = function(editing) {
      $scope.editing = editing
    }

    $scope.confirm = function($event) {
      if ($event) $event.preventDefault()
      return account.setProgress('wishes.funeral', 'confirmed')
    }

    var showEdit = false

    $scope.getShowEdit = function() {
      return showEdit
    }

    $scope.toggleShowEdit = function() {
      showEdit = !showEdit
    }
    $scope.setActiveState = function(value) {
      if (value) showEdit = false
      $scope.model.active = value
    }
    $scope.activate = function($event) {
      if ($event) $event.preventDefault()
      var endpoint = auth.getBaseURL() + 'funeral',
            data = {
              active: $scope.model.active
            },
            id = $scope.model._id

      if (id) {
        endpoint += '/' + id
        $http.put(endpoint, data, auth.getConfig(['signed', 'flat'], 'PUT', endpoint))
      } else {
        $http.post(endpoint, data, auth.getConfig(['signed', 'flat'], 'POST', endpoint)).then(function(res) {
          $scope.model._id = res.data._id
        })
      }
    }

    $scope.submit = function($event, val) {
      if ($event) $event.preventDefault()
      var endpoint = auth.getBaseURL() + 'funeral',
            data = {
              active: true,
              statement: val
            },
            id = $scope.model._id

      if (id) {
        endpoint += '/' + id
        $http.put(endpoint, data, auth.getConfig(['signed', 'flat'], 'PUT', endpoint))
      } else {
        $http.post(endpoint, data, auth.getConfig(['signed', 'flat'], 'POST', endpoint)).then(function(res) {
          $scope.model._id = res.data._id
        })
      }
    }

    var endpoint = auth.getBaseURL() + 'funeral'
    $http.get(endpoint, auth.getConfig(['signed', 'flat'], 'GET', endpoint)).then(function(res) {
      if (res.data.length) $scope.model = _.extend(_.first(res.data), $scope.model)

      $scope.$watch('statement', _.throttle(function(val) {
        if (!_.isUndefined(val)) $scope.submit(null, val)
      }, 15 * 1000))
    })

  }])
