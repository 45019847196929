import _ from 'underscore'
import angular from 'angular'

import 'typeahead'
import '../../auth'
import '../../bus'
import '../../api'
import '../account/service'
import '../assets/services/asset.list'
import '../assets/services/prototypes'

angular.module('app.assets', ['authentication', 'msgbus', 'api', 'app.account.service', 'app.assets.services.asset.list', 'app.assets.services.prototypes'])
  .controller('assets.editor.controller', ['$state', '$stateParams', '$scope', '$http', 'auth', 'bus', 'api', 'account.service', 'assets.service.prototypes', 'resource', function($state, $stateParams, $scope, $http, auth, bus, api, account, prototypes, resource) {

    var engine

    let collapseControls = ['aDescription', 'shareType', 'assetAddress', 'editAssetDesc', 'editShareType']

    $scope.collapse = _.reduce(collapseControls, (obj, control) => {
      obj[control] = true
      return obj
    }, {})

    function resetCollapse() {
      _.extend($scope.collapse, _.mapObject($scope.collapse, () => true))
    }


    /** Return to referring view */
    $scope.close = function($event) {

      // prevent default
      if (_.has($event, 'preventDefault')) $event.preventDefault()

      try {

        // return to creator
        let redirect = JSON.parse($stateParams.redirect)

        $state.go(redirect.to, redirect.params)
      } catch (e) {

        // default to assets list view
        $state.go('auth.assets.list')
      }

    }

    // template form model
    $scope.formModel = {}

    // set dataset resource in scope
    $scope.dataset = resource.get

    // set model type in scope
    $scope.type = $stateParams.type

    // set resource presently in focus to scoped model, or create anew
    $scope.model = $stateParams.id === 'new' ? resource.mock({}) : resource.get($stateParams.id)

    // refer from unsupported state parameters,
    // or non-existent model id (404)
    if (_.isEmpty($scope.model)) return $scope.close()

    // register typeahead engine
    engine = new window.Bloodhound({
      datumTokenizer: d => window.Bloodhound.tokenizers.whitespace(d.fullnameStatic),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      limit: 64,
      local: _.reject($scope.dataset(), entry => entry.id === $scope.model.id)
    })
    engine.initialize()

    // register typeahead comparisons
    $scope.datasetRelatives = {
      displayKey: 'fullnameStatic',
      source: engine.ttAdapter()
    }

    // editor state
    $scope.state = 'saved'

    // editor nav
    $scope.open = $scope.model.complete ? 'details' : ($scope.model.steps() || [])[0]

    /**
     *  Return steps in model
     *  @returns {array} steps
     */
    $scope.steps = function() {
      return _.uniq($scope.model.steps())
    }

    /** Return current step number, 1-based index */
    $scope.step = function() {
      return $scope.model.mocked ? 0 : (_.indexOf($scope.steps(), $scope.open) + 1)
    }

    /** Wizard next step */
    $scope.next = function() {
      $scope.navigate($scope.steps()[_.indexOf($scope.steps(), $scope.open) + 1])
    }

    /** Wizard prev step */
    $scope.prev = function() {
      $scope.navigate($scope.steps()[_.indexOf($scope.steps(), $scope.open) - 1])
    }

    /** Navigation helper */
    $scope.navigate = function(open) {
      $scope.open = open
    }

    /** Enter edit state */
    $scope.edit = function($event) {

      // prevent default
      if ($event) $event.preventDefault()
      $scope.state = 'edit'

      // publish edit event
      bus.publish('action', 'delegates', 'edit')
    }

    /** Enter saved state */
    $scope.done = function($event) {

      // prevent default
      if ($event) $event.preventDefault()
      resetCollapse()
      $scope.state = 'saved'

      // publish view event
      bus.publish('action', 'delegates', 'save')
    }

    /** Enter created state */
    $scope.create = function($event, attrs) {
      var promise

      // prevent default
      if ($event) $event.preventDefault()

      // create from mocked or existing
      if ($scope.model.mocked) {

        // add asset by prototype
        switch (attrs.type) {
          case 'personalProperty':
            promise = prototypes.personalProperty()
            break
          case 'realEstate':
            promise = prototypes.realEstate()
            break
        }

        // on completion
        promise.then(function(asset) {

          // select completed asset
          $scope.model = asset

          // update image resource
          $scope.resource = createImageResource($scope.model.id)

          // unmock any mocked assets
          resource.unmock()

          // update progress
          $scope.start()
        })

      } else {

        // return once model is created
        promise = $scope.model.create(attrs)
        promise.then($scope.close)
      }

      return promise
    }

    /** Remove model */
    $scope.remove = function($event) {

      // prevent default
      if ($event) $event.preventDefault()

      // remove mocked or persisted model
      if ($scope.model.mocked) {

        // unmock model
        resource.unmock()

        // navigate back
        $scope.close()
      } else {

        // process model removal
        resource.remove($scope.model)
            .then($scope.close)
      }
    }

    /* Copy the incoming address */
    $scope.useSameAddress = function(address) {
      delete $scope.model.address
      $scope.model.address = address
    }

    $scope.start = function($event) {
      if ($event) $event.preventDefault()
      account.setProgress('assets')
    }

    $scope.confirm = function($event) {
      if ($event) $event.preventDefault()
      resetCollapse()
      account.setProgress('assets', 'confirmed')
    }

    // candidates for shared asset,
    // handle ownership options to fetch candidates
    $scope.$watch('model.ownership', function(value) {
      if (value === 'shared') {

        // when shared ownership, populate candidates
        var endpoint = auth.getBaseURL() + 'candidates'
        $http.get(endpoint, auth.getConfig()).then(function(res) {

          // populate candidates
          $scope.candidates = res.data || []

          if (!_.isEmpty($scope.model.sharedWith)) {

            // replace shared with object reference,
            // with reference in candidates collection
            var sharedWith = $scope.model.sharedWith._id
            delete $scope.model.sharedWith
            $scope.model.sharedWith = _.findWhere($scope.candidates, { _id: sharedWith })
          }
        })
      }
    })

    $scope.resource = createImageResource($scope.model.id)
    function createImageResource(id) {

      // create image $resource
      return api.$res('asset', { id: id, subres: 'images' }, {
        save: {
          method: 'POST',
          headers: { 'X-Data-Expand': undefined }
        }
      })
    }

  }])
  .controller('assets.controller', ['$scope', '$stateParams', 'assets.service.asset.list', function($scope, $stateParams, assets) {

    // initiate tutorial
    $scope.tutorial = false

    $scope.setTutorial = function(state) {
      $scope.tutorial = state
    }

    // assets collection
    $scope.model = {

      // assets in model,
      // abstraction of assets.get
      assets: assets.get,

      // filtered asset sets
      realEstate: function() {
        return _.filter($scope.model.assets(), function(asset) { return (asset.type === 'realEstate');})
      },
      personalProperty: function() {
        return _.filter($scope.model.assets(), function(asset) { return (asset.type === 'personalProperty');})
      }
    }

  }])
