import _ from 'underscore'
import angular from 'angular'

import '../api'

angular.module('app.directives.notifications', ['api'])
  .directive('appNotifications', function() {
    return {
      restrict: 'E',
      scope: {},
      template: require('../../tpl/directives/notifications.html'),
      controller: 'directives.notifications.controller'
    }
  })
  .controller('directives.notifications.controller', ['$scope', '$state', 'api', function($scope, $state, api) {

    // API service will push messages to .messages
    $scope.messages = api.messages

    /**
     *  Remove message from active notifications
     *  @param {object} message
     */
    $scope.remove = message => {
      if (message.sticky) message.destroy()
    }

    $scope.$watch('messages.length', function() {
      $scope.blocker = !_.isEmpty(_.findWhere($scope.messages, { block: true }))
    })
  }])
