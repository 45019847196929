import _ from 'underscore'
import angular from 'angular'
import { loadStripe } from '@stripe/stripe-js'

const API_BASE_URL = process.env.AFFIO_API_BASE_URL
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

angular.module('app.payment.service', [])
  .service('payment.service', ['$http', '$q', function ($http, $q) {

    this.createCardElement = () => {
      const style = {
        base: {
          iconColor: "#166969",
          color: "#4D4D4D",
          fontSize: "16px",
          fontFamily: "\"Raleway\", \"Helvetica Neue\", Helvetica, Arial, sans-serif",
          fontSmoothing: "subpixel-antialiased",
          textShadow: "0 1px 0 #E6E6E6",
        },
        invalid: {
          iconColor: '#CA532F',
          color: '#CA532F'
        }
      }

      return stripePromise.then((stripe) => {
        return stripe.elements().create('card', { style })
      })
    }

    /**
     *  Process payment with server layer
     *  @param {object} card Card element
     *  @param {string} coupon Discount code to apply
     *  @returns {promise}
     */
    this.send = function (card, coupon) {
      var deferred = $q.defer()

      $http.post(`${API_BASE_URL}/payment/process`, { coupon })
        .then(res => {
          return stripePromise.then(stripe => {
            return stripe.confirmCardPayment(
              res.data?.client_secret,
              { payment_method: { card } }
            )
          })
        })
        .then(({ error, paymentIntent }) => {
          if (error) return deferred.reject(error.message)
          return deferred.resolve(paymentIntent)
        })
        .catch(res => {
          deferred.reject(
            _.has(res.data, 'error') ?
              res.data.error.message :
              'An error occurred completing payment'
          )
        })

      return deferred.promise
    }

    /**
     *  Retrieve payment status
     *  @returns {promise}
     */
    this.getStatus = function () {
      var deferred = $q.defer()

      // retrieve payment status from server-layer
      $http.get(`${API_BASE_URL}/payment/validate`)
        .then(
          () => deferred.resolve(true),
          () => deferred.resolve(false)
        )

      return deferred.promise
    }

    /**
     *  Retrieve details for voucher from code
     *  @param {string} code
     *  @returns {promise}
     */
    this.retrieveVoucherDetails = function (code) {
      var deferred = $q.defer()

      // retrieve payment status from server-layer
      $http.get(`${API_BASE_URL}/payment/verifyCoupon`, {
        params: { code: code }
      }).then(deferred.resolve, function (res) {
        return deferred.reject(res.status === 404 ? 'unknown' : 'expired')
      })

      return deferred.promise
    }

  }])
